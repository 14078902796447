import RelaxingMan  from 'theme/assets/svg/illustrations/relaxing-man.svg';

const MobileWarning = ()=>{
  return (
    <>
      <main id="content" role="main">
    
        <div className="d-lg-flex">
          <div className="container d-lg-flex align-items-lg-center vh-lg-100 space-bottom-1 space-top-3 space-bottom-lg-3 space-lg-0">
            <div className="row justify-content-lg-between align-items-lg-center w-100 mt-lg-9">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <img className="img-fluid" src={RelaxingMan} alt="SVG Illustration" />
              </div>

              <div className="col-lg-5">
                <div className="mb-4">
                  <h1>Please Switch to desktop browser.</h1>
                  <p>In order to fully utilize the functionality of our system you need to use a desktop browser.</p>
                </div>

                          
              </div>
            </div>
          </div>
        </div>
    
      </main>
    </>
  )
}

export default MobileWarning;