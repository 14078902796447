import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';


const Loader = ({size,mt,container=false})=>{  
  if(container){
    return (
      <div className="container pt-5">
        <div className={`text-center mt-${mt?mt:'2'}`}>
          <LoadingOutlined type="loading" size={size?size:24} spin />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={`text-center mt-${mt?mt:'2'}`}>
        <LoadingOutlined type="loading" size={size?size:24} spin />
      </div>
    </>
  )
}

export default Loader;