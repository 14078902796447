import styled from 'styled-components'

const StyledDiv = styled.div`
max-width: 600px;
min-width: 400px;
width:100%;
 .image-slider{
     &__item
{
    &__hint{
    
            position: absolute;
            left: 0;
            top: 0;
        
    }
&__img{
    max-width:100%;
    max-height:400px;
}
}
}

.swiper-slide{
    border: 1px solid #ccc;
    border-radius: 4px;
}


`;


export default StyledDiv;