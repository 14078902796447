import styled from 'styled-components';
const Wrapper = styled.div`
.title {
    display: block;
    border-bottom: 1px solid;
    margin-top: 16px;
    margin-bottom: 8px;
}

.submit-btn{
    margin-top: 24px;
}

.date-item {
    display: inline-block;
    box-shadow: 0 0 2px #ccc;
    padding: 8px 16px;
    border-radius: 8px;
    clear: both;
    width: auto;
    margin: 0 auto 16px 0;
}

.submit-btn {
    clear: both;
}

.item-containers {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 16px;
}



.date-item>* {
    margin-right: 20px;
}
.form-control-integrated .ant-btn.ant-dropdown-trigger{
    height :32px;
}
`;
export default Wrapper;