export const quantityGenerator = (product) => {
  if(product.has_uneven_quantity_values){
    return product.quantity_list;
  }else{
    const ret = [];
    // prevent start from zero
    const start = product.quantity_option.min_value ? product.quantity_option.min_value : product.quantity_option.step;

    for(let i = start; i <= product.quantity_option.max_value ; i+=product.quantity_option.step){
      ret.push(i);
    }
    return ret;
  }
};
