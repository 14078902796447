import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { getListingEnquiry } from 'services/api/domain';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import ProgressCard from 'component/common/ProgressCard';
const DomainEnquiry = (props)=>{
  const navigate = useNavigate();
  const {userId,adId} = useParams();
  
  const [page,setPage] = useState(0);
  const [hasMore,setHasMore]=useState(true);
  const [data,setData] = useState([]);
  const [loading,setLoading]=useState(true);
  
  useEffect(() => {
    loadData();    
  }, []);

  const loadData = ()=>{
    setLoading(true);    
    getListingEnquiry(userId,adId,page+1).then(data=>data.data).then(results=>{      
      if(results.length===0){
        setHasMore(false);
      }else{
        setData([...data,...results]);
        setPage(page+1);
      }
    }).catch(err=>{
      console.log(err);
    }).finally(()=>setLoading(false))
  }

  if(loading){
    return(
      <ProgressCard />
    )
  }

  return (
    <>
      <div className="container pt-5 form-control-integrated">
        <div className='row'>
          <div className='col-8' >
            <h3 className="font-weight-medium d-inline-block mr-3">Domain Enquiry</h3>        
          </div>
          <div className='col-4 text-right'>
            <Button type="link" onClick={()=>{navigate(-1)}}><LeftOutlined /> Back to orders</Button>
          </div>
        </div>

        <ul className="list-group mb-5">           
          {data.map((item,key)=>(
            <li key={key} className="list-group-item">
              <div className="mb-2">
                <h5>{`${item.sender.firstName} ${item.sender.lastName}`} <span className="badge badge-primary ml-1">{item.deliveryMethod}</span></h5>
              </div>

              <div className="media">               

                <div className="media-body">
                  <div className="row">
                    <div className="col-sm mb-3 mb-sm-0">
                      <span className="d-block text-dark">{item.message}</span>
                      <small className="d-block text-muted">{item.sender.email} - {item.sender.phoneNumber}</small>
                    </div>

                    <div className="col-sm-auto">
                      <span className="btn btn-xs btn-white mr-2">
                        Deliver source: {item.metaData.driverSource}
                      </span>
                      {/* <button type="button" className="btn btn-xs btn-white">
                        <i className="fas fa-trash-alt mr-1"></i> Delete
                      </button> */}
                    </div>
                  </div>                 
                </div>
              </div>
            </li>         
          ))}
        </ul>
        {hasMore &&
          <div className='text-center'>
            <Button type="link" onClick={()=>{loadData()}}>Load more ...</Button>
          </div>
        }
      </div>
    </>
  )
}

export default DomainEnquiry;