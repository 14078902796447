import axios from 'config/axios';
import { campaignAPIVersion } from 'services/apiVersions';

export const getTasks = (campaignId) => {
  return axios({
    method: 'get',
    url: `/api/${campaignAPIVersion}/campaign/task/?campaign_id=${campaignId}`
  })
};

export const getTask = () => {
  return axios({
    method: 'get',
    url: 'http://www.mocky.io/v2/5d232ea82e00008076c3ef15'
  })
};

export const newTask = (data) => {
  return axios({
    method: 'post',
    url: `/api/${campaignAPIVersion}/campaign/task/`,
    data
  })
};

export const updateTask = (id, data) => {
  return axios({
    method: 'patch',
    url: `/api/${campaignAPIVersion}/campaign/task/${id}/`,
    data
  })
};
