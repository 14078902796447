import React from 'react';
import {Outlet, Route} from 'react-router-dom';
import auth0Client from 'Auth/AuthClient';

const LandingRoute = (props) =>{  
  
  const token = auth0Client.getBearerToken()
  if(!token){
    auth0Client.signOut();
  }

  return <Outlet/>
  // const {component: Component, ...rest} = props;
  // return(
  //   <Route {...rest} render={(props) => {
  //     return <Component {...rest} {...props} />      
  //   }} />
  // );  
}
export default LandingRoute;
