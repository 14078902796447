import axios from 'config/axios';
import { socialAPIVersion } from './apiVersions'

export const getSocialAccounts = () => {
  return axios({
    method: 'get',
    url: `/api/${socialAPIVersion}/social/fb/users/`,
  });
}
export const getSocialPages = (userId) => {
  return axios({
    method: 'get',
    url: `/api/${socialAPIVersion}/social/fb/users/${userId}/pages`,
  });
}

export const addSocialAccount = (data) => {
  return axios({
    method: 'post',
    url: `/api/${socialAPIVersion}/social/fb/users/`,
    data,
  });
}

export const createSocialPost = (data) => {
  return axios({
    method: 'post',
    url: `/api/${socialAPIVersion}/social/fb/posts/`,
    data,
  });
}

export const getSocialInsight = (pageId) => {
  return axios({
    method: 'get',
    url: `/api/${socialAPIVersion}/social/fb/posts/${pageId}/insights/`,
  });
}