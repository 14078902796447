import React, {useState} from 'react';
import {Input} from 'antd';
import { LoadingOutlined ,EditOutlined} from '@ant-design/icons';

function EditableInput({editable, text, onChange, inputProps = {}, saving}) {
  const [editing, editingSetter] = useState(false);
  const [newText, newTextSetter] = useState(text);
  const finishEditing = (event) => {
    editingSetter(false);
    if(text !== event.target.value){
      newTextSetter(event.target.value);
      onChange(event.target.value);
    }
  };
  //todo add save loading
  return (
    <div className='editable-input d-flex'>
      {saving && <LoadingOutlined spin className='pt-1 pr-2' />}
      {!editing && editable && !saving && <EditOutlined className='pt-1 pr-2' onClick={() => editingSetter(true)} /> }
      {!editing && !saving && text}
      {!editing && saving && newText}
      {editing && (
        <Input
          {...inputProps}
          autoFocus={true}
          defaultValue={text}
          onBlur={finishEditing}
        />
      )}
    </div>
  );
}

export default EditableInput;
