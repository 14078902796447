import React from 'react';
import './style.scss';

import NewOrderForm from './NewOrderForm';
import { useCampaignContext } from '../../CampaignContext';

const NewOrder = () => {  
  const campaignContext = useCampaignContext();  
  return (
    <NewOrderForm propertyId={campaignContext.campaignDetail.id} props/>
  );
};
export default NewOrder;
