import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import './style.scss';

const Template = (props) => {
  return (
    <div className='main-container'>
      <Header {...props.headerProps} />
      <Outlet/>
      <Footer />
    </div>
  );
}

export default Template;