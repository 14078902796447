import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Modal, Popconfirm, Tag,Tooltip } from 'antd';
import { CopyOutlined ,LoadingOutlined, RightOutlined,CheckCircleTwoTone,ExclamationCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import ArtworkTypes from 'types/ArtworkTypes';
import { imageUrlMaker } from 'utils';
import Wrapper from './style.js';
import OrderItemDispatch from './dispatch';
import ArtworkSection from './ArtworkSection';
import { useCampaignContext } from 'component/propertyDashboard/Common/CampaignContext';
import { useGlobalState } from 'Context';
import PreviewPdf from 'component/form/PreviewPdf'
import { RouteHelperCollect, RouteHelperMaintance, RouteHelperNewSocialPost } from 'component/propertyDashboard/Common/CampaignRouteHelper';
import DuplicateOrderModal from './DuplicateOrder'
import OrderStatus from './Status/index.jsx';
import OrderActions from './orderActions';
import './style.scss';



function OrderItem(props) {
  const navigate = useNavigate();
  const orderStatus = props.data.status.code;
  const {isReadonly,campaignType} = useCampaignContext();  
  const store = useGlobalState();  
  const { agency } = store;
  
  const {
    isPackage,
    presentMode,
    deleting,
    canceling,
    removeOrder,
    cancelOrder,
    browserRoutes,
    NewArtWorkPopup,
    SelectArtWorkPopup,
    EditOrderPopup,
    PrepositionalAndInstruction,
    getPreview,
    onEditSelectedTemplate,
    doRefresh = () => {
      // will be override in inner components
    },
  } = props;
  
  
  if(!props.data || Object.keys(props.data).length===0) {
    return null;
  }
  
  const orderType = props.data.product?.type?.service.code
  const isSocialType = orderType === 'social-media';
  const isListingType = orderType === 'Listing';
  const isAuctionLive = orderType === 'Auctions Live';
  const isSocial = orderType === 'social-media' && props.data.status.code !==1;
  const hasSocialInsight = props.data.status.code !==2 && props.data.status.code !==1 && props.data.status.code!==0;
  
  const [isNewArtWorkPopupOpen, isNewArtWorkPopupOpenSetter] = useState(false);
  const [isSelectArtWorkPopupOpen, isSelectArtWorkPopupOpenSetter] = useState(
    false
  );
  const [isEditPopupOpen, isEditPopupOpenSetter] = useState(false);
  const [isDuplicatePopupOpen, setIsDuplicatePopupOpen] = useState(false);
  const [previewModal, previewModalSetter] = useState(false);
  const [selectedToDispatch, selectedToDispatchSetter] = useState(false);

  let orderNumber = [...('' + props.data.number)];
  orderNumber.splice(4, 0, '-');

  const selectToDispatch = () => {
    selectedToDispatchSetter(!selectedToDispatch);
    props.onSelectToDispatch(props.data);
  };

  const openNewArtWorkPopup = () => isNewArtWorkPopupOpenSetter(true);
  const closeNewArtWorkPopup = () => isNewArtWorkPopupOpenSetter(false);

  const openSelectArtWorkPopup = () => isSelectArtWorkPopupOpenSetter(true);
  const closeSelectArtWorkPopup = () => isSelectArtWorkPopupOpenSetter(false);

  const openEditPopup = () => {isEditPopupOpenSetter(true);}
  const closeEditPopup = () => isEditPopupOpenSetter(false);

  const openDuplicatePopup = () => {setIsDuplicatePopupOpen(true);}
  const closeDuplicatePopup = () => setIsDuplicatePopupOpen(false);
  const closeAndSuccessDuplicatePopup = () => {setIsDuplicatePopupOpen(false);doRefresh()}

  const openPreviewModal = (image) => previewModalSetter(image);
  const closePreviewModal = () => previewModalSetter(false);

  const editSelectedTemplate = () => {
    const {
      campaignId,
      orderId,
      job: { jobId },
    } = props.data;
    onEditSelectedTemplate(campaignId, orderId, jobId);
    // TODO: have to remove this code
    // navigate(browserRoutes.property.
    //   artworkEditor(props.data.campaignId,
    //      props.data['_id'],
    //      encodeURIComponent(props.data.job.jobId)));
  };

  const artworkUrl =
    props.data.artworkType === (ArtworkTypes.UploadArtwork || ArtworkTypes.SocialArtwork)
      ? imageUrlMaker(props.data.assetId, '90')
      : imageUrlMaker(props.data.job?.asset, '90');

  const artworkPreviewImage =
    props.data.artworkType === ArtworkTypes.UploadArtwork || ArtworkTypes.SocialArtwork ||
    !props.data.artworkType
      ? props.data?.assetId
      : props.data?.job?.asset;

  
  const artworkUrlBig = imageUrlMaker(artworkPreviewImage, '400');
  const date = moment(new Date(props.data.createdAt)).format('DD/MM/Y HH:mm');

  const hasActiveCollections = props.data.services.some(
    (service) => service.serviceType === 1
  );
  const hasProduct = props.data.product ? true : false;
  let actions = [];

  if (props.data.canEdit && isReadonly === false && hasProduct) {
    actions.push(
      <span key={'option'} onClick={openEditPopup}>
        Edit Qty / Options
      </span>
    );
    if(agency.can_duplicate_order){
      actions.push(
        <span key={'duplicate'} onClick={openDuplicatePopup}>
          <CopyOutlined />Duplicate
        </span>
      );
    }    
  }

  if (deleting) {
    actions.push(
      <LoadingOutlined key={'deleting'} className='pl-2' size={24} spin />
    );
  }

  if (!deleting && props.data.canRemove && isReadonly === false && hasProduct) {
    actions.push(
      <Popconfirm
        key={'remove'}
        placement='topLeft'
        title={'Are you sure?'}
        onConfirm={removeOrder}
        okText='Yes'
        cancelText='No'
      >
        <span className='text-danger'>Remove</span>
      </Popconfirm>
    );
  }

  if (canceling) {
    actions.push(
      <LoadingOutlined key={'loading'} className='pl-2' size={24} spin />
    );
  }

  if (
    !canceling &&
    props.data.canCancel &&
    isReadonly === false &&
    hasProduct
  ) {
    actions.push(
      <Popconfirm
        key={'cancel'}
        placement='topLeft'
        title={'Are you sure?'}
        onConfirm={cancelOrder}
        okText='Yes'
        cancelText='No'
      >
        <span className='text-danger'>Cancel</span>
      </Popconfirm>
    );
  }

  const actionsWithSeparator = [];
  actions = actions.forEach((item, key) => {
    actionsWithSeparator.push(item);
    if (actions[key + 1]) {
      actionsWithSeparator.push('|');
    }
  });

  const onParentClick = () => {
    props.parentSelectHandler(props.data.parentId);
    const d = document.querySelectorAll(`div[data-orderid='${props.data.parentId}']`)[0];    
    d.scrollIntoView();
  };

  const onChangeItem = (item) => {
    
    // after updating quantity this item will be updated
    props.data.quantity = item.quantity;
    props.data.cost = item.cost;
    props.data.name = item.name;
    props.data.extras = item.extras;
    props.data.product = item.product;
    props.data.productId = item.productId;
  };

  const artworkPreviewImageClick = () => {
    if (getPreview) {
      openPreviewModal(getPreview());
    } else {
      const ImageToShow = artworkPreviewImage.pdf ? (
        <PreviewPdf data={artworkPreviewImage} />
      ) : (
        <img alt='artwork' style={{ width: '100%' }} src={artworkUrlBig} />
      );
      openPreviewModal(ImageToShow);
    }
  };
  
  const orderItemDispatchCondition =
    isReadonly !== true &&
    orderStatus !== -2 && 
    (props.data.canDispatch || props.data.dispatched) &&
    (props.data.product?.artwork_type === ArtworkTypes.NoArtwork ||
      props.data.product?.artwork_type === ArtworkTypes.PredefinedArtwork ||
      artworkUrl) &&
    !props.data.generatingArtwork;

  const artworkSectionCondition =
    hasProduct &&
    props.data.hasCollection !== true &&
    props.data.product?.artwork_type !== ArtworkTypes.PredefinedArtwork &&
    !props.data.generatingArtwork;

  

  const maintenanceCondition =
    hasProduct &&
    !props.data.generatingArtwork &&
    props.data.canMaintain &&
    props.data.product?.maintenances &&
    !!props.data.product?.maintenances.length;

  const collectCondition =
    hasProduct &&
    !props.data.generatingArtwork &&
    props.data.canCollect &&
    props.data.product?.collections &&
    !!props.data.product?.collections.length;
      
  
  const collectOrder = () => {
    if (props.data.canCollect) {
      // browserRoutes.property.collection(
      navigate(RouteHelperCollect(campaignType,props.data.campaignId,props.data['_id']));      
    }
  };

  let approvalStatusIcon = '';
  if (props.data.approvalStatus) {
    switch (props.data.approvalStatus) {
    case 'Pending':
      approvalStatusIcon = 'fa-clock';
      break;
    case 'Rejected':
      approvalStatusIcon = 'fa-ban';
      break;
    case 'Approved':
      approvalStatusIcon = 'fa-check';
      break;
    default: approvalStatusIcon='fa-clock'; 
      break;
    }
   
  }

  const doPostOnSocialNetwork =()=>{
    // navigate(
    //   browserRoutes.social.createPost(props.data['_id'])
    // );
    navigate(RouteHelperNewSocialPost(campaignType,props.data.campaignId,props.data['_id']))
  }
  const goToSocialInsight =()=>{
    navigate(
      browserRoutes.social.insight(props.data['_id'])
    );
  }

  const XeroSection = ()=>{
    if(agency.xero_enabled!==true) return null;    
    if(!props.data.invoice) return null;
    if(props.data.invoice===null) return null;
    if(Object.keys(props.data.invoice).length===0) return null;

    return (      
      <Tooltip title={
        (<>
          Status: {props.data.invoice.status}
          <br></br>
          Invoice ID: {props.data.invoice.id}
        </>)
      }>
        {
          agency.xero_enabled && props.data.invoice.status==='Paid'?
            (<CheckCircleTwoTone twoToneColor="#52c41a" className='ml-2' size='large' />):
            (<ExclamationCircleTwoTone twoToneColor="#ffc40c" className='ml-2' size='large' />)
        }                    
      </Tooltip>
    )      
  }

  const getRequestDeliveryDate =(requestDate)=>{
    const splitedDate = requestDate.split('T');
    if(splitedDate.length>0){
      return splitedDate[0];
    }
  }
  
  return (
    <Wrapper className='order-item-component my-2 mx-2 mx-sm-0' data-orderid={props.data['_id']}>
      <div className={`row row1 py-3 ${selectedToDispatch || props.selectedAsParent ? 'selected' : ''}`}>
        <OrderStatus data={props.data} />
        <div className='col-12 col-md-7 col-lg-9 order-3 order-md-2'>
          <div className='title-area'>
            <h2 className='title'>
              {props.jobType === 1
                ? props.data.product?.type.name + ' ' + props.data.product?.name
                : props.data.name}
            </h2>
            <div className='ids'>
              {props.data.internalOrderId && (
                <Tag>Order ID: {props.data.internalOrderId}</Tag>
              )}
              {props.data.externalJobNo && (
                <Tag>Supplier ID: {props.data.externalJobNo}</Tag>
              )}
              {props.data.parentId && (
                <Tag className='c-pointer' onClick={onParentClick}>
                  Parent <RightOutlined />
                  {/* Parent <Icon type='right' /> */}
                </Tag>
              )}          
            </div>            
          </div>
          <div className='actions'>
            {isEditPopupOpen && (
              <EditOrderPopup
                campaignId={props.data['campaignId']}
                orderId={props.data['_id']}
                serviceId={props.data.serviceId}
                onCancel={closeEditPopup}
                onChangeItem={onChangeItem}
                isSingleProduct={isListingType || isAuctionLive}
              />
            )}
            {isDuplicatePopupOpen && (
              <DuplicateOrderModal
                campaignId={props.data['campaignId']}
                campaign_type = {campaignType}
                orderId={props.data['_id']}
                serviceId={props.data.serviceId}
                onCancel={closeDuplicatePopup}                
                onSuccess={closeAndSuccessDuplicatePopup}                
              />
            )}
            {!presentMode && actionsWithSeparator}
          </div>
          <div className='row px-0 properties mt-3'>
            <div className='col-6 col-sm-3 col-lg-2'>
              <div className='text-secondary d-inline-block d-sm-block'>
                Quantity
              </div>
              <div className='value d-inline-block pl-2 pl-sm-0'>
                <span className='fa fa-tags mr-2 text-secondary' />
                {props.data.quantity}
              </div>
            </div>
            <div className='col-6 col-sm-3 col-lg-2'>
              <div className='text-secondary d-inline-block d-sm-block'>
                Spend            
              </div>
              <div className='value d-inline-block pl-2 pl-sm-0'>
                <span className='fa fa-dollar-sign mr-2 text-secondary' />
                {/* {convertPrice(props.data.cost)} */}
                {Number(props.data.cost).toFixed(2)}
                <XeroSection />
              </div>
            </div>
            {!!props.data.extras && !!props.data.extras.length && (
              <div className='col-12 col-sm-3 col-lg-3'>
                <div className='text-secondary d-inline-block d-sm-block'>
                  Extra
                </div>
                {props.data.extras
                  .filter((item) => !!item)
                  .map((extra, key) => (
                    <div key={key} className='d-inline-block pl-2 pl-sm-0'>
                      {/* ${convertPrice(extra.cost)} {extra.name} */}
                      ${Number(extra.cost).toFixed(2)} {extra.name}
                    </div>
                  ))}
              </div>
            )}
            <div className='col-6 col-sm-3 col-lg-3'>
              <div className='text-secondary d-inline-block d-sm-block'>
                Date
              </div>
              <div className='value d-inline-block pl-2 pl-sm-0'>
                <span className='fa fa-calendar mr-2 text-secondary' />
                {date}
              </div>
            </div>
            {props.data.deliveryInfo && (
              <div className='col-6 col-sm-3 col-lg-3'>
                <div className='text-secondary d-inline-block d-sm-block'>
                Requested Delivery Date
                </div>
                <div className='value d-inline-block pl-2 pl-sm-0'>
                  <span className='fa fa-calendar mr-2 text-secondary' />
                  {
                    getRequestDeliveryDate(props.data.deliveryInfo.date)
                  }
                  {/* {moment(new Date(props.data.deliveryInfo.date)).format(
                    'DD/MM/Y'
                  )} */}
                </div>
              </div>
            )}
            {props.data.approval && agency.vendor_approval && (
              <div className='col-6 col-sm-3 col-lg-2'>
                <div className='text-secondary d-inline-block d-sm-block'>
                  Approval status
                </div>
                <div className='value d-inline-block pl-2 pl-sm-0'>
                  <span
                    className={` mr-2 text-secondary fa ${approvalStatusIcon}`}
                  />
                  {props.data.approvalStatus}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='col-6 col-md-3 col-lg-2 right-side order-2 order-md-3'>
          {orderStatus !== 0 &&
            hasProduct &&
            props.data.product.artwork_type !== ArtworkTypes.NoArtwork && (
            <div className='content'>
              {artworkUrl ? (
                <div className='image mb-3'>
                  <img
                    src={artworkUrl}
                    alt='artwork'
                    onClick={artworkPreviewImageClick}
                  />
                </div>
              ) : (
                <div className='icon mb-3'>
                  <i className='fi flaticon-pencil-can' />
                </div>
              )}
              {!presentMode && (
                <>
                  {artworkSectionCondition && (
                    <ArtworkSection
                      openPreviewModal={openPreviewModal}
                      artworkPreviewImage={artworkPreviewImage}
                      openNewArtWorkPopup={openNewArtWorkPopup}
                      artworkUrl={artworkUrl}
                      editSelectedTemplate={editSelectedTemplate}
                      artworkUrlBig={artworkUrlBig}
                      openSelectArtWorkPopup={openSelectArtWorkPopup}
                      data={props.data}
                    />
                  )}
                  {isNewArtWorkPopupOpen && hasProduct && (
                    <NewArtWorkPopup
                      onCancel={closeNewArtWorkPopup}
                      orderId={props.data['_id']}
                      campaignId={props.data['campaignId']}
                      campaignType={campaignType}
                      type={props.data.product.artwork_type}
                      onSelectTemplate={openSelectArtWorkPopup}
                      isSocial={isSocialType}
                    />
                  )}
                  {isSelectArtWorkPopupOpen && hasProduct && (
                    <SelectArtWorkPopup
                      onCancel={closeSelectArtWorkPopup}
                      agencyId={props.data['agencyId']}
                      orderId={props.data['_id']}
                      productId={props.data.product['id_obj']}
                      campaignId={props.data.campaignId}                     
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {!presentMode && (
        <div className='row flex-row-reverse row2'>
          {props.data.generatingArtwork && (
            <div>
              <span className='generating-text'>Generating Artwork...</span>
              <Button onClick={doRefresh} className='base-btn'>
                Click to check
              </Button>
            </div>
          )}
       

          {(!isPackage && orderItemDispatchCondition && !isSocialType && !isListingType && !isAuctionLive) && (
            <div className='col-12 col-md-3 col-lg-2 pr-0 pl-0 pl-md-3'>
              <OrderItemDispatch
                openPreviewModal={openPreviewModal}
                propertyId={props.propertyId}
                selectedToDispatch={selectedToDispatch}
                orderStatus={orderStatus}
                selectToDispatch={selectToDispatch}
                closePreviewModal={closePreviewModal}
                PrepositionalAndInstruction={PrepositionalAndInstruction}
                data={props.data}
              />
            </div>
          )}
          {collectCondition && (
            <div className='col-12 col-md-3 col-lg-2 pr-0 pl-0 pl-md-3'>
              <div
                className={`${isPackage?'dispatch-package':'dispatch'} ${
                  !props.data.canCollect ? 'disabled' : 'selected'
                }`}
                onClick={collectOrder}
              >
                Collect
                {hasActiveCollections && (
                  <div className='hover'>
                    {moment(
                      new Date(
                        props.data.services.find(
                          (item) => item.serviceType === 1
                        ).date
                      )
                    ).format('DD/MM/Y HH:mm')}
                  </div>
                )}
              </div>
            </div>
          )}

          {maintenanceCondition && (
            <div className='col-12 col-md-3 col-lg-2 pr-0 pl-0 pl-md-3'>
              <div
                className={`${isPackage?'dispatch-package':'dispatch'} ${
                  !props.data.canMaintain ? 'disabled' : 'selected'
                }`}
                onClick={
                  props.data.canMaintain
                    ? () =>
                      navigate(RouteHelperMaintance(campaignType,props.data.campaignId,props.data['_id']))
                    : null
                }
              >
                Maintenance
              </div>
            </div>
          )}
          <OrderActions isListingType={isListingType} isAuctionLive={isAuctionLive} propertyId={props.propertyId} campaignId={props.campaignId} orderId={props.data._id} domainListing={props.data.domain_listing}/>
          {/* {isListingType && (
          )} */}
          {isSocial && !hasSocialInsight && (
            <div>              
              <Button disabled={agency.social_post_enabled!==true} onClick={doPostOnSocialNetwork} className={agency.social_post_enabled!==true?'base-btn disabled':'base-btn '}>
              Post to Social Media
              </Button>
            </div>
          )}
          {isSocial && hasSocialInsight && (
            <div>              
              <Button disabled={agency.social_post_enabled!==true} onClick={goToSocialInsight} className='base-btn'>
                Facebook Insight
              </Button>
            </div>
          )}
        </div>
      )}
      {previewModal && (
        <Modal
          className='image-preview-modal'
          visible={true}
          footer={null}
          width="800px"
          onCancel={closePreviewModal}
        >
          {previewModal}
        </Modal>
      )}
    </Wrapper>
  );
}

OrderItem.propTypes = {
  presentMode: PropTypes.bool.isRequired,
  deleting: PropTypes.bool,
  canceling: PropTypes.bool,
  history: PropTypes.any,
  data: PropTypes.any.isRequired,
  removeOrder: PropTypes.any,
  cancelOrder: PropTypes.any,
  parentSelectHandler: PropTypes.any.isRequired,
  browserRoutes: PropTypes.any,
  NewArtWorkPopup: PropTypes.func,
  SelectArtWorkPopup: PropTypes.func,
  EditOrderPopup: PropTypes.func,
  PrepositionalAndInstruction: PropTypes.func,
  onSelectToDispatch: PropTypes.any,
};

export default OrderItem;
