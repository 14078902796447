import styled from 'styled-components';

const StyledHeader = styled.header`
${props => {
  const { bg = '#377dff', text = 'white', bgOppositeColor = 'black' } = props.colors;
  
  return `
  background:${bg};
  color:${text} !important;

  .u-header-nav-links:not(.bg-white):not(.js-header-fix-moment)
   .u-header__nav-link{
    color:${text} !important; 
    &:hover{
      color:${bgOppositeColor};  
    }
  }
  `;
}}

.logo-container {
    display: flex;
    .logo-img {
    max-height: 64px;
    margin: auto;
    max-width: 300px;
    height: auto;
    width: auto;
}
}

`;

export default StyledHeader;

