import { Button, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { FacebookFilled } from '@ant-design/icons';
import { initFacebookSdk } from './init-facebook-sdk';
import { addSocialAccount } from 'services/social';
import { useGlobalState } from 'Context';

const FacebookLoginPage = (props) => {
  const store = useGlobalState();

  const [loading, setLoading] = useState(true);
  const [doingLogin, setDoingLogin] = useState(false);
  const [resp, setResp] = useState();
  useEffect(() => {
    initFacebookSdk().then((data) => {
      if (data.isLoggedIn) {
        setResp(data.resp);
      } else {
        setResp(null)
      }
      setLoading(false);
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    });
  }, []);

  const login = () => {
    setDoingLogin(true);
    window.FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        console.log('....logged out');
        window.FB.logout(function (response) {
          addAccount();
        });
      } else {
        addAccount();
      }
    });

  }

  const addAccount = () => {
    window.FB.login(function (authResponse) {
      setDoingLogin(false);
      setResp(authResponse);
      if (authResponse.authResponse) {
        const { accessToken, expiresIn, userID } = authResponse.authResponse;
        const payload = {
          access_token: accessToken,
          token_expires_in: expiresIn,
          agency_id: store.agency.id,
          user_id: userID,
          // name: 'alaki',
        }
        addSocialAccount(payload).then((data) => {
          message.success('Account added');
          props.onDone();
        })
          .catch(function (error) {
            if (error.response) {
              message.error(`Error in adding account: ${error.response.data.detail}`)
            } else {
              console.log('Error', error.message);
              message.error('Error in adding account')
            }

          });
      }
    }, {
      scope: 'pages_read_engagement,public_profile,pages_manage_posts,publish_video,publish_to_groups,read_insights,pages_show_list,pages_read_user_content,ads_management,business_management,instagram_basic,instagram_content_publish',
      return_scopes: true,
      // auth_type: 'reauthorize'
    });
  }

  // const logout = ()=>{
  //   setDoingLogin(true);
  //   window.FB.logout(function(response) {
  //     console.log(response)
  //     setResp(false);
  //     setDoingLogin(false);
  //   });
  // }

  if (loading) {
    return (
      <div className="container pt-4">
        Loading
      </div>
    )
  }


  if (!loading) {
    return (
      <div className="container pt-4">
        <Button
          color="primary"
          onClick={() => { login() }}
          disabled={doingLogin}
          icon={<FacebookFilled />}
        >
          Add Facebook Account
        </Button>
      </div>
    )


    // if(!resp){
    //   return (
    //     <div className="container pt-4">
    //       <Button 
    //         color="primary"
    //         blocks
    //         onClick={login}
    //         disabled={doingLogin}
    //         icon={<FacebookFilled/>}            
    //         outline
    //       >
    //           Sign in with Facebook
    //       </Button>    
    //     </div>
    //   );
    // }else{
    //   return(

    //     <div className="container pt-4">

    //       <Button 
    //         color="primary"
    //         blocks
    //         onClick={logout}
    //         disabled={doingLogin}
    //         icon={<FacebookFilled/>}            
    //         outline
    //       >
    //           Logout1
    //       </Button>    
    //     </div>
    //   )
    // }
  }
}

export default FacebookLoginPage;
