import { useEffect, useState } from 'react';
import { DatePicker, Form, Input, Checkbox, message,Button ,Select, Divider, Alert} from 'antd';
import ProgressCard from 'component/common/ProgressCard';
import Loader from 'component/common/Loader';
import { v4 as uuidv4 } from 'uuid';
import DomainImages from './images';
import { getDomainUsers,retriveUserAgencies,createListing,getListing} from 'services/api/domain';
import { propertyUpdate,propertyGet } from 'services/api/property';
import {DeleteOutlined,RightCircleTwoTone,PlusOutlined} from '@ant-design/icons'
import { useCampaignContext } from '../CampaignContext';
import { useGlobalState } from 'Context';
import { browserRoutes } from 'consts/browserRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

const Option = Select.Option;
const DomainPublish = (props)=>{
  const navigate = useNavigate();
  const { campaignDetail } = useCampaignContext();
  const {user} = useGlobalState();
  const [loading,setLoading] = useState(true);
  const [saving,setSaving]= useState(false);
  const [data,setData] = useState({});
  const [pdfs,setPdfs] = useState([]);
  const [propertyImages, propertyImagesSetter] = useState([]);
  const [floorImages, floorImagesSetter] = useState([]);  
  const [form] = Form.useForm();
  const [domainUsers,setDomainUsers] = useState([]);
  const [domainUserAgencies,setDomainUserAgencies] = useState(null);
  const [loadingAgencies,setLoadingAgencies] = useState(false);
  const [selectDomainUser,setSelectedDomainUser] = useState(null);
  const [selectDomainUserAgency,setSelectedDomainUserAgency] = useState(null);
  const [features,setFeatures] = useState([]);
  const [listing,setListing] = useState(null);
  const [listingDetail,setListingDetail] = useState(null);
  
  const {campaignId,propertyId,orderId} = useParams();

  const redirectToDomainSite = ()=>{
    const redirectUri =`${process.env.REACT_APP_BASE_PATH}${browserRoutes.callbackPublishDomain}`;
    // console.log(redirectUri)
    const url = `https://auth.domain.com.au/v1/connect/authorize?client_id=${process.env.REACT_APP_DOMIAN_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=openid%20offline_access%20api_listings_read%20api_listings_write%20api_agencies_read%20api_agencies_write%20api_webhooks_write%20api_enquiries_read%20api_enquiries_write&state=${campaignId}_${propertyId}_${orderId}`;
    window.location.replace(url)
  }

  
  const defaultPropertyImages = campaignDetail.residential_real_estate_info.property.property_images_info;
  const defaultFloorImages = campaignDetail.residential_real_estate_info.property.floor_plan_id;
  
  useEffect(() => {
    getDomainUsers().then(data=>data.data).then(data=>{
      if(Array.isArray(data) && data.length)
      {        
        setDomainUsers(data);
        getPropertyAndListing();
      }else{
        
        redirectToDomainSite();
      }      
    })
    
  }, []);

  const getPropertyAndListing = ()=>{
    setLoading(true);
    const responseProperty =propertyGet(propertyId);
    const responseListing = getListing(orderId);

    axios.all([responseProperty,responseListing]).then(axios.spread((...responses) => {
      const dataProperty = responses[0].data;
      setData(dataProperty);
      
      const tmpFeatures = [];
      if(dataProperty.has_spa){
        tmpFeatures.push('SPA');
      }
      if(dataProperty.has_pool){
        tmpFeatures.push('Pool');
      }      
      if(dataProperty.heating){
        tmpFeatures.push(`Heating: ${dataProperty.heating}`);
      }
      if(dataProperty.hot_water_service){
        tmpFeatures.push(`Hot water service: ${dataProperty.hot_water_service}`);
      }
      if(dataProperty.air_conditioning){
        tmpFeatures.push('air conditioning');
      }
      if(dataProperty.secure_parking){
        tmpFeatures.push('secure parking');
      }
      if(dataProperty.remote_garage){
        tmpFeatures.push('remote garage');
      }
      if(dataProperty.vacuum_system){
        tmpFeatures.push('vacuum system');
      }
      if(dataProperty.tennis_court){
        tmpFeatures.push('tennis court');
      }
      if(dataProperty.balcony){
        tmpFeatures.push('balcony');
      }
      if(dataProperty.deck){
        tmpFeatures.push('deck');
      }
      if(dataProperty.courtyard){
        tmpFeatures.push('court yard');
      }
      if(dataProperty.shed){
        tmpFeatures.push('shed');
      }
      if(dataProperty.fully_fenced){
        tmpFeatures.push('Fully fenced');
      }
      setFeatures(tmpFeatures);

      const dataListing = responses[1].data;
      if(Array.isArray(dataListing) && dataListing.length>0){
        setListing(dataListing[0]);       
        // console.log(dataListing[0]);
        const payload = dataListing[0].create_payload;
        if(payload){
          setListingDetail(payload);
          setFeatures(payload.features.split(','));
          // get domain agencies for selected user
          setSelectedDomainUser(dataListing[0].user_id);      
          
          setPdfs([...Array(payload.propertyDetails.pdfs.length).keys()])

          retriveUserAgencies(dataListing[0].user_id).then(data=>data.data).then(data=>{      
            setDomainUserAgencies(data);
            setSelectedDomainUserAgency(dataListing[0].domain_agency_id);
            // console.log(dataListing[0].domain_agency_id);
          }).catch(err=>{
            console.log(err);
          }).finally(()=>{
            setLoading(false);
          })

        
        }else{
          setLoading(false);
        }
      }else{
        setLoading(false);
      }
      
    })).catch(() => {
      setLoading(false);
      message.error('Error in getting listing/property')
    })    
  }

  const onFinishHandler= (values)=>{
    if(!selectDomainUser || !selectDomainUserAgency){
      message.error('Please select domain user/agency.')
      return;
    }
    // console.log(values)
    const pi = propertyImages.map(x=>x._id);
    const fi = floorImages.map(x=>x._id);
    const pdfsObject = values.propertyDetails.pdfs;
    let pdfs = [];
    if(pdfsObject && Object.keys(pdfsObject).length>0){
      pdfs = Object.keys(pdfsObject).map(key=>pdfsObject[key]);
    }
    
    let model = {
      domain_user_id : selectDomainUser,
      domain_agency_id : +selectDomainUserAgency,
      campaign_id : campaignId,
      order_id :orderId,
      details: {
        imagesIds:{
          photograph: pi,
          floorPlan: fi,
        },
        ...values
      }
    }
    if(listing){
      model.provider_ad_id = listing.provider_ad_id;
    }
    model.details.propertyDetails.parkingInfo.details = [model.details.propertyDetails.parkingInfo.details];
    model.details.propertyDetails.pdfs = pdfs;
    model.details.propertyDetails.landArea.unit = 'squareFeet';
    model.details.propertyDetails.area.unit = 'squareFeet';
    model.details.features = model.details.features.join(',');
    
    setSaving(true);
    let propertyModel = {...data};
    propertyModel.under_offer = values.underOfferOrContract;
    propertyModel.propertyName = values.propertyDetails.propertyName;
    propertyModel.location = values.propertyDetails.location;
    propertyModel.bedroom = values.propertyDetails.bedRooms;
    propertyModel.bathroom = values.propertyDetails.bathRooms;
    propertyUpdate(propertyId,propertyModel).then(()=>{
      createListing(model).then(data=>data.data).then(()=>{
        
        message.success('Listing published successfully.')
        navigate(browserRoutes.campaign.dashboard.residential.order(campaignId))
      }).catch(error=>{
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          const errData = error.response.data;
          if(errData.detail && errData.detail.errors && Object.keys(errData.detail.errors).length>0){
            let errList=[];
            Object.keys(errData.detail.errors).map(errKey=>{
              errList= [...errList,...errData.detail.errors[errKey]]
            })
            message.error(errList.join('  -  '));
          }else{
            message.error('Error in publishing.')
          }
        }else{
          message.error('Error in publishing ...')
        }
      }).finally(()=>{
        setSaving(false);
      })
    }).catch(()=>{
      message.error('Error in updating property');
      setSaving(false);
    })
 
  }

  const addPdf = ()=>{
    setPdfs([...pdfs,uuidv4()])
  }

  const removePdf = (item)=>{
    setPdfs(pdfs.filter(x=>x!==item));
  }

  const onDomainUserChanged= (userId)=>{
    setSelectedDomainUserAgency(null);
    setSelectedDomainUser(userId);
    setLoadingAgencies(true);
    retriveUserAgencies(userId).then(data=>data.data).then(data=>{      
      setDomainUserAgencies(data);
    }).catch(err=>{
      console.log(err);
    }).finally(()=>{
      setLoadingAgencies(false);
    })
  }
    
  if(loading){
    return (
      <ProgressCard />
    )
  }

  
  const disabledDate = (current) => {    
    return current.startOf('day') < moment().startOf('day');
  };

  const getInitialAgency = ()=>{
    const initialAgency = domainUserAgencies.filter(x=>x.id===selectDomainUserAgency);

    if(initialAgency && initialAgency.length>0){
      return initialAgency[0].name;
    }
    return null;
  }

  const Events = ()=>{
    if(!listing || !listing.details.events) return null;
    const events = listing.details.events;
    const warnings = events.filter(x=>x.severity==='Warning');
    const errors = events.filter(x=>x.severity==='Error');
    let warningList = [];
    let errorList = [];
    if(warnings.length>0){
      warningList = warnings.map(x=>x.message);
    }
    if(errors.length>0){
      errorList = errors.map(x=>x.message);
    }    
    return (
      <>
        <div className='mb-4'>
          {errorList.length>0 && 
          errorList.map((m,i)=>{return (
            <Alert
              key={i}              
              description={m}              
              type="error"              
              closable
              className='mb-2'
            />
          );})          
          }
          {warningList.length>0 && 
          warningList.map((m,i)=>{return (
            <Alert
              key={i}              
              description={m}              
              type="warning"              
              closable
              className='mb-2'
            />
          );})          
          }
        </div>        
      </>
    )
  }

  return (
    <>
      <div className="container pt-5 form-control-integrated">
        <Events />
        <h3 className="font-weight-medium d-inline-block">
          <RightCircleTwoTone className='mr-2'/>Publish on Domain.com.au as
        </h3>

        <div className='row'>
          <div className='col-6'>
            <label className="form-label">User</label>
            <Select
              size="large" style={{ width: '100%', height:'40px' }}
              placeholder="Select user"
              onChange={e=>{onDomainUserChanged(e)}}              
              defaultValue={listing?listing.user_id:null}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>              
                    <a
                      style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                      onClick={redirectToDomainSite}
                    >
                      <PlusOutlined /> Add New User
                    </a>
                  </div>
                </div>
              )}
            >
              {domainUsers.map((item) => (
                <Option key={item.user_id}>{item.email? item.email : 'Main Account'}</Option>
              ))}
            </Select>
          </div>
          {loadingAgencies ? <Loader container={false}  mt={6}/> :
            domainUserAgencies && domainUserAgencies.length>0 ?
              <div className='col-6'>
                <label className="form-label">Agency</label>
                <Select
                  size="large" style={{ width: '100%', height:'40px' }}
                  placeholder="Select user"
                  onChange={e=>{setSelectedDomainUserAgency(e)}}   
                  defaultValue={listing?getInitialAgency():null}                         
                >
                  {domainUserAgencies.map((item) => (
                    <Option key={item.id}>{item.name? item.name : 'Main Agency'}</Option>
                  ))}
                </Select>
              </div>:
              null
          }
        </div>
        <Divider />

        <h2 className="font-weight-bold d-inline-block ml-3">
          Listing details
        </h2>
        <br></br>
       

        <h3 className="font-weight-medium d-inline-block mb-4">
          {campaignDetail.name}
        </h3>
        

        <Form form={form} name="editCampaignPopup" onFinish={onFinishHandler}>
          <div className="row">
            <div className="col-6 col-md-6">              
              <label className="form-label">Listing Action</label>
              <Form.Item  name="listingAction" initialValue={listingDetail?listingDetail.listingAction:null}>
                <Select size="large" style={{ width: '100%', height:'40px' }}>
                  <Option value="sale">Sale</Option>
                  <Option value="rent">Rent</Option>
                  <Option value="saleAndLease">Sale and lease</Option>                  
                </Select>
              </Form.Item>
            </div>
            <div className="col-6">
              <label className="form-label">Contact Preference</label>
              <Input
                size="large"    
                disabled                                                  
                defaultValue={`${user.first_name } ${user.last_name}`}
              />              
            </div>
          </div>            

          <div className="row">
            <div className="col-6 col-md-6">              
              <label className="form-label">Lifestype type</label>
              <Form.Item  name="lifeStyleType" initialValue={listingDetail?listingDetail.lifeStyleType:null}>
                <Select size="large" style={{ width: '100%', height:'40px' }}>
                  <Option value="firstHome">First Home</Option>
                  <Option value="investment">Investment</Option>
                  <Option value="retirement">Retirement</Option>
                  <Option value="seaChange">Sea Change</Option>
                  <Option value="treeChange">Tree Change</Option>
                  <Option value="youngFamilies">Young Families</Option>
                           
                </Select>
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                className='mt-5'
                valuePropName="checked"
                name="underOfferOrContract"
                initialValue={listingDetail?listingDetail.underOfferOrContract:data.under_offer}                
              >
                <Checkbox>Under offer or contract</Checkbox>
              </Form.Item>
            </div>   
          </div>

          <Divider />
          <h3 className="font-weight-medium d-inline-block">
            <RightCircleTwoTone className='mr-2'/>Property details
          </h3>

          <div className="row">
            <div className="col-12">              
              <label className="form-label">Property type</label>
              <Form.Item  name={['propertyDetails','propertyType']} initialValue={listingDetail?listingDetail.propertyDetails.propertyType:[]}>
                <Select size="large"  style={{ width: '100%',height:'100%' }}  mode="multiple">
                  <Option value="acreageSemiRural">Acreage Semi Rural</Option>                 
                  <Option value="apartmentUnitFlat">Apartment Unit Flat</Option>                 
                  <Option value="aquaculture">Aquaculture</Option>                 
                  <Option value="blockOfUnits">Block Of Units</Option>                 
                  <Option value="carSpace">Car Space</Option>                 
                  <Option value="dairyFarming">Dairy Farming</Option>                 
                  <Option value="developmentSite">Development Site</Option>                 
                  <Option value="duplex">Duplex</Option>                 
                  <Option value="farm">Farm</Option>                 
                  <Option value="fishingForestry">Fishing Forestry</Option>                 
                  <Option value="newHomeDesigns">New Home Designs</Option>                 
                  <Option value="house">House</Option>                 
                  <Option value="newHouseLand">New House Land</Option>                 
                  <Option value="irrigationServices">Irrigation Services</Option>                 
                  <Option value="newLand">New Land</Option>                 
                  <Option value="livestock">Live Stock</Option>                 
                  <Option value="newApartments">New Apartments</Option>                 
                  <Option value="penthouse">Penthouse</Option>                 
                  <Option value="retirement">Retirement</Option>                 
                  <Option value="rural">Rural</Option>                 
                  <Option value="semiDetached">Semi Detached</Option>                 
                  <Option value="viticulture">Viti-culture</Option>                 
                  <Option value="mixedFarming">Mixed Farming</Option>                 
                  <Option value="grazing">Grazing</Option>                 
                  <Option value="horticulture">Horticulture</Option>                 
                  <Option value="equine">Equine</Option>                 
                  <Option value="farmlet">Farmlet</Option>                 
                  <Option value="orchard">Orchard</Option>                 
                  <Option value="ruralLifestyle">Rural Lifestyle</Option>                 
                </Select>
              </Form.Item>
            </div>                   
          </div>

          <div className="row">
            <div className="col-6">
              <label className="form-label">Property Name</label>
              <Form.Item                
                name={['propertyDetails','propertyName']}                
                initialValue={listingDetail?listingDetail.propertyDetails.propertyName:data.propertyName}>              
                <Input
                  size="large"                                              
                />
              </Form.Item>
            </div>    
            <div className="col-6">
              <label className="form-label">Location</label>
              <Form.Item                
                name={['propertyDetails','location']}
                initialValue={listingDetail?listingDetail.propertyDetails.location:data.location}>                                            
                <Input
                  size="large"                                              
                />
              </Form.Item>
            </div>        
          </div>

          <div className='row'>
            <div className="col-4">
              <label className="form-label">Bedrooms</label>
              <Form.Item name={['propertyDetails','bedRooms']} 
                initialValue={listingDetail?listingDetail.propertyDetails.bedRooms:data.bedroom}>                                                            
                <Input type='number' size="large"/>
              </Form.Item>
            </div> 
            <div className="col-4">
              <label className="form-label">Bathrooms</label>
              <Form.Item name={['propertyDetails','bathRooms']} 
                initialValue={listingDetail?listingDetail.propertyDetails.bathRooms:data.bathroom}>                   
                <Input type='number' size="large"/>
              </Form.Item>
            </div> 
          </div>

          <div className="row">
            <div className="col-4">              
              <label className="form-label">Parking type</label>
              <Form.Item  name={['propertyDetails','parkingInfo','details','parkingType']}
                initialValue={listingDetail?listingDetail.propertyDetails.parkingInfo.details[0].parkingType:null}>                   
                <Select size="large" style={{ width: '100%', height:'40px' }}>
                  <Option value="onSite">On Site</Option>                 
                  <Option value="onStreet">On Street</Option>                 
                  <Option value="noParking">No Parking</Option>                 
                  <Option value="garage">Garage</Option>                 
                  <Option value="carport">Carport</Option>                 
                  <Option value="outdoor">Outdoor </Option>                                    
                </Select>
              </Form.Item>
            </div>
            <div className="col-4">
              <label className="form-label">Number of spaces</label>
              <Form.Item name={['propertyDetails','parkingInfo','details','numberOfSpaces']} 
                initialValue={listingDetail?listingDetail.propertyDetails.parkingInfo.details[0].numberOfSpaces:data.numberOfSpaces}>                                   
                <Input type='number' size="large"/>
              </Form.Item>
            </div> 
            <div className="col-4">
              <label className="form-label">Parking Info</label>
              <Form.Item name={['propertyDetails','parkingInfo','information']} 
                initialValue={listingDetail?listingDetail.propertyDetails.parkingInfo.information:null}>      
                <Input  size="large"/>
              </Form.Item>
            </div> 
          </div>

          <div className="row">
            <div className="col-4">
              <label className="form-label">Area</label>
              <Form.Item name={['propertyDetails','area','value']} 
                initialValue={listingDetail?listingDetail.propertyDetails.area.value:data.building_area}>                  
                <Input type='number' size="large"/>
              </Form.Item>
            </div> 
            <div className="col-4">
              <label className="form-label">Land</label>
              <Form.Item name={['propertyDetails','landArea','value']}
                initialValue={listingDetail?listingDetail.propertyDetails.landArea.value:data.land_area}>                                
                <Input type='number' size="large"/>
              </Form.Item>
            </div> 
          </div>

          <div className="row">
            <div className="col-12">
              <label className="form-label">Features</label>
              <Form.Item name={['features']} initialValue={features}>
                <Select
                  // bordered={false}
                  mode="tags"
                  size='large'
                  placeholder="Please select"                  
                  style={{ width: '100%',height:'100%' }}
                >
        
                </Select>
              </Form.Item>
            </div> 
          </div>

          <Divider/>
          <h3 className="font-weight-medium d-inline-block">
            <RightCircleTwoTone className='mr-2'/>Auction
          </h3>
          <div className="row">
            <div className="col-4">
              <label className="form-label">Date time</label>
              <Form.Item name={['auction','dateTime']} initialValue={listingDetail?moment(listingDetail.auction.dateTime):null}>    
                <DatePicker showTime={true} style={{ width: '100%' }} disabledDate={disabledDate}></DatePicker>    
              </Form.Item>
            </div> 
            <div className="col-4">
              <label className="form-label">Location</label>
              <Form.Item name={['auction','location']} initialValue={listingDetail?listingDetail.auction.location:null}>    
                <Input  size="large"/>
              </Form.Item>
            </div> 
            <div className="col-4">
              <label className="form-label">URL</label>
              <Form.Item name={['auction','url']} initialValue={listingDetail?listingDetail.auction.url:null}>    
                <Input  size="large"/>
              </Form.Item>
            </div> 
          </div> 


          <Divider/>
          <h3 className="font-weight-medium d-inline-block">
            <RightCircleTwoTone className='mr-2'/> Rental
          </h3>
          <div className="row">
            <div className="col-4">
              <label className="form-label">Bond</label>
              <Form.Item name={['bond']} initialValue={listingDetail?listingDetail.bond:null}>   
                <Input type='number' size="large"/>
              </Form.Item>
            </div> 
            <div className="col-4">
              <label className="form-label">Available From</label>
              <Form.Item name={['availableFrom']} initialValue={listingDetail?moment(listingDetail.availableFrom):null}>   
                <DatePicker showTime={true} style={{ width: '100%' }} disabledDate={disabledDate}></DatePicker>    
              </Form.Item>
            </div> 
          </div> 


          <Divider/>
          <div className='row'>
            <div className='col-8'>
              <h3 className="font-weight-medium d-inline-block">
                <RightCircleTwoTone className='mr-2'/>PDFs
              </h3>
            </div>
            <div className='col-4 text-right'>
              <Button type='primary' onClick={()=>{addPdf()}}>
                  Add PDF
              </Button>
            </div>
          </div>

          {pdfs.length>0 && pdfs.map((pdf,i)=> {
            return (
              <div className='row' key={i}>
                <div className="col-3">              
                  <label className="form-label">PDF type</label>
                  <Form.Item  name={['propertyDetails','pdfs',pdf,'type']} 
                    initialValue={listingDetail && listingDetail.propertyDetails.pdfs[pdf]? listingDetail.propertyDetails.pdfs[pdf].type:null}>
                    <Select size="large" style={{ width: '100%', height:'40px' }}>
                      <Option value="commercialPdf">Commercial Pdf</Option>                                                                 
                      <Option value="newDevBrochurePdf">New Dev Brochure Pdf</Option>                                                                 
                      <Option value="floorplanPdf">Floorplan Pdf</Option>                                                                 
                      <Option value="devProjectPdf">Dev Project Pdf</Option>                                                                 
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-3">
                  <label className="form-label">Location URL</label>
                  <Form.Item  name={['propertyDetails','pdfs',pdf,'url']}
                    initialValue={listingDetail && listingDetail.propertyDetails.pdfs[pdf]? listingDetail.propertyDetails.pdfs[pdf].url:null}>                  
                    <Input  size="large"/>
                  </Form.Item>
                </div> 
                <div className="col-4">
                  <label className="form-label">Description</label>
                  <Form.Item  name={['propertyDetails','pdfs',pdf,'description']}
                    initialValue={listingDetail && listingDetail.propertyDetails.pdfs[pdf]? listingDetail.propertyDetails.pdfs[pdf].description:null}>
                    <Input  size="large"/>
                  </Form.Item>
                </div> 
                <div className='col-2 mt-4'>
                  <label className="form-label"></label>
                  <Button type='ghost' onClick={()=>{removePdf(pdf)}}>
                    <DeleteOutlined />
                  </Button>
                </div>
              </div>
            );
          })}
          
          <Divider/>
          <DomainImages campaignId={campaignId} defalutPropertyImages={defaultPropertyImages} defaultFloorImages={defaultFloorImages} externalPropertyImagesSetter={propertyImagesSetter} externalFloorImagesSetter={floorImagesSetter}/>

          <Divider/>
          <h3 className="font-weight-medium d-inline-block">
            <RightCircleTwoTone className='mr-2'/>Prices
          </h3>
          <div className="row">
            <div className="col-4">
              <label className="form-label">From</label>
              <Form.Item name={['price','from']} initialValue={listingDetail?listingDetail.price.from:null}>   
                <Input type='number' size="large"/>
              </Form.Item>
            </div> 
            <div className="col-4">
              <label className="form-label">To</label>
              <Form.Item name={['price','to']} initialValue={listingDetail?listingDetail.price.to:null}>   
                <Input type='number' size="large"/>
              </Form.Item>
            </div> 
            <div className="col-4">
              <label className="form-label">Display Text</label>
              <Form.Item name={['price','displayText']} initialValue={listingDetail?listingDetail.price.displayText:null}>   
                <Input size="large"/>
              </Form.Item>
            </div> 
          </div>

          <div className="text-right">            
            <Button
              className="px-5 font-weight-bold"
              type="primary"
              htmlType="submit"
              size="large"                  
              loading={saving}>
              {saving? 'Saving ...': 'Publish'}
            </Button>
          </div>
          
        </Form>
      </div>
    </>
  )
}

export default DomainPublish;