import styled from 'styled-components';

const Wrapper = styled.div`
.filed {
    width: 100%;
    margin-bottom: 16px;
    &__title {
    display: block;
    margin-bottom: 4px;
    }
}

.ant-input-number {
    height: 49px;
    line-height: 49px;
    width: 100%;
}

.submit-btn {
    height: 40px;
    min-width: 150px;
}

`;

export default Wrapper;