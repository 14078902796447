import React,{useState,useEffect} from 'react';
import {Form, Modal} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TagSelect from '../../../../form/TagSelect';
import {searchAgent} from '../../../../../services/api/agent';
import UserTag from '../../../../UserTag';
import {agentsOptions, userTagAvatarMaker} from '../../../../../utils';
import './style.scss';


const AddAgent = (props) =>{
  const [form] = Form.useForm();
  // const [saving, setSaving] = useState(false);
  const [gettingAgents, setGettingAgents] = useState(false);
  const [defaultItems, setDefaultItems] = useState(null);
  
  
  const handleOk = (values) => {    
    // todo sp old agent id are sent as key/value but new ones are sent as number. temp resolve
    // let agentIds = values.agents.map(item=>item.key);
    let orderedAgents=null;
    if(defaultItems && defaultItems.length===0){
      let agentIds = values.agents;    
      // let newIds = values.agents.filter(x=>!x.key);
      orderedAgents = agentIds.map((item,key)=>{
        return {
          'agent_id': item,
          'priority': key+1
        }
      });
    }    else{
      let agentIds = values.agents;          
      orderedAgents = agentIds.map((item,key)=>{
        return {
          'agent_id': item,
          'priority': key+1
        }
      });
    }
    // props.onAdd([...agentIds,...newIds]);
    props.onAdd(orderedAgents);
    props.close();
  };

  useEffect(() => {
  // make default agent items
    props.agentListApi().then(data => {    
      const defaultItems = data.data.map(agent => {
        return {
          text: <UserTag key={agent.id} name={agent['first_name'] + agent['last_name']}
            avatar={userTagAvatarMaker(agent.profile_picture_id, '90')}/>,
          value: agent.id,       
        }
      });      
      setGettingAgents(true);
      setDefaultItems(defaultItems);        
    });
  }, [props])

  
  return (
    <Modal
      className='add-campaign-agent'
      title="Add new agent"
      visible={true}
      onCancel={props.close}
      okText="Add"
      cancelText="Cancel"
      onOk={() => {
        form
          .validateFields()
          .then(values => {              
            handleOk(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <label className="form-label agent-name">
          Agent Name
      </label>
      {gettingAgents && defaultItems ? (
        <Form form={form} name='add-agent-form' onFinish={handleOk}>
          <TagSelect
            fieldName="agents"
            form={props.form}
            initialValue={defaultItems}            
            api={searchAgent}
            options={agentsOptions}/>
        </Form>
      ) : (
        <LoadingOutlined spin />
      )}
    </Modal>
  );  
}

export default AddAgent;

