import axios from 'config/axios';
import { domainAPIVersion } from '../apiVersions'

export const getDomainUsers = () => {
  return axios({
    method: 'get',
    url: `/api/${domainAPIVersion}/domain/users/`,
    
  });
};
export const retriveUserAgencies = (userId) => {
  return axios({
    method: 'get',
    url: `/api/${domainAPIVersion}/domain/users/${userId}/agencies`,
    
  });
};
export const createDomainUser = (redirect_uri,code) => {
  const data = {
    authorization:{redirect_uri,code}
  }
  return axios({
    method: 'post',
    url: `/api/${domainAPIVersion}/domain/users/`,
    data
  });
};

export const createListing = (data) => {  
  return axios({
    method: 'put',
    url: `/api/${domainAPIVersion}/domain/listings/`,
    data
  });
};


export const getListing = (orderId) => {  
  return axios({
    method: 'get',
    url: `/api/${domainAPIVersion}/domain/listings/?order_ids=${orderId}`,
  });
};

export const getListingDetail = (userId,adId) => {  
  return axios({
    method: 'get',
    url: `/api/${domainAPIVersion}/domain/listings/${userId}/${adId}`,
  });
};
export const getListingEnquiry = (userId,adId,page=1) => {  
  return axios({
    method: 'get',
    url: `/api/${domainAPIVersion}/domain/listings/${userId}/enquiry/${adId}/?page=${page}`,
  });
};