import { useState } from 'react';
import {notification} from 'antd';
import { crmIntegration, updateCampaign } from 'services/api/campaign';
import { useCampaignContext } from '../CampaignContext';

const openNotification = (msg,type='success') => {
  notification[type]({
    message: msg,    
  });
};

const AuctionPublish = ({campaignId})=>{
  const [publishing,setPublishing] = useState(false);
  const campaignContext = useCampaignContext();
  const onPublishAuctionClicked = ()=>{
    if(publishing){
      return;
    }
    setPublishing(true);
    crmIntegration(campaignId).then(data=>data.data).then(data=>{
      updateCampaign(campaignId,{published_to_auctions_live:true}).then(()=>{
        openNotification('Order successfully published to the auction live.');
        campaignContext.reloadHeader();
      }).catch(()=>{
        openNotification('Error occured in updating campaign','error')    
      }).finally(()=>{
        // setPublishing(false);
      })
    }).catch(e=>{
      let errMsg = e.response.data.message;
      if(!errMsg){
        errMsg='Error occured in publishing ...';
      }
      openNotification(errMsg,'error');
      setPublishing(false);
    });      
  }

  if(campaignContext.campaignDetail.published_to_auctions_live){
    return  null;
  }
  
  return (
    <>
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
        <div className="mb-5">
          <h1 className="font-weight-medium">Auction Live</h1>
          <p>In order to use this feature, you need to publish your campaign to auction live. click the link below.</p>
        </div>
        <a  className="btn btn-primary btn-wide transition-3d-hover" href="#" onClick={onPublishAuctionClicked}>
          {publishing? 'Publishing ...': 'Publish'}
        </a>
      </div>

    </>
  )
}

export default AuctionPublish;