import React from 'react';
import { Badge } from 'antd';

import StyledDiv from './style';

import 'swiper/swiper.scss';
import Swiper from 'react-id-swiper';

const ImageSlider = props => {
  const { images } = props;
  const params = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };

  return (
    <StyledDiv className="image-slider">
      <Swiper {...params}>
        {images.map((item, index) => {
          return (
            <div key={index} className="image-slider__item">
              {/* Todo: Color have to be assignt from theme color*/}
              <Badge
                style={{
                  backgroundColor: 'white',
                  color: '#377dff',
                  border: '1px solid rgb(55, 125, 255)'
                }}
                count={index + 1}
                className="image-slider__item__hint"
              />
              <img
                className="image-slider__item__img"
                src={item}
                alt="Artwork"
              />
            </div>
          );
        })}
      </Swiper>
    </StyledDiv>
  );
};
export default ImageSlider;
