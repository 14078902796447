import { useCampaignContext } from '../CampaignContext';
import AuctionDetail from './auctionDetail';
import AuctionPublish from './auctionPublish';

const Auction = (props)=>{
  const campaignContext = useCampaignContext();
  
  return (
    <div id='auction' className="container pt-8">
      

      <AuctionPublish campaignId={campaignContext.campaignDetail.id} />
      <AuctionDetail campaignId={campaignContext.campaignDetail.id} />
    </div>
  )
}

export default Auction;