import React, { useState } from 'react';
import Edit from '../Edit';

export default props => {
  const [selected, selectedSetter] = useState(false);
  const [editing, editingSetter] = useState(false);
  const { urls } = props;
  const backgroundImageURL = urls ? urls['90'] : '';

  const selectedChanger = e => {
    e.preventDefault();
    selectedSetter(!selected);
    props.selectedItemToggle(props['_id']);
  };

  const editorOpen = () => {
    editingSetter(true);
  };

  const editorClose = () => {
    editingSetter(false);
  };

  return (
    <React.Fragment>
      <div className="image-container">
        <div
          className={`image ${selected ? 'selected' : ''}`}
          style={{ backgroundImage: `url(${backgroundImageURL})` }}
          onClick={editorOpen}
        >
          {!selected && props.title && (
            <div className="title">{props.title}</div>
          )}
        </div>
        <span
          className={`fa select ${selected ? 'fa-check-square' : 'fa-square'}`}
          onClick={selectedChanger}
        />
      </div>

      {editing && (
        <Edit onEdit={editorClose} onClose={editorClose} id={props['_id']} />
      )}
    </React.Fragment>
  );
};
