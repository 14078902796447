import { useCampaignContext } from '../CampaignContext';
import QRCode from 'qrcode.react'
import { useEffect, useState } from 'react';
import { Button,Input, message,Form, Alert } from 'antd';
import {UpsertQR} from 'services/api/qr';
import { updateCampaign } from 'services/api/campaign';

const QRPage = ()=>{
  const campaignContext =useCampaignContext();
  const {uss_info} = campaignContext.campaignDetail;
  const [url,setUrl] = useState(null);
  const [saving,setSaving] = useState(false);

  const changeUrl = (e)=>{
    setUrl(e.target.value)
  }
  useEffect(() => {    
    if(uss_info){
      setUrl(uss_info.url);
    }
  }, [uss_info]);

  const VisitCount =()=>{
    if(!uss_info){
      return null;
    }

    if(uss_info && Array.isArray(uss_info.VisitCount)){
      return null;
    }

    if(!url) return null;

    return (
      <div>
        <Alert message={<span><b>Visit Count: </b> {uss_info.visitCount}</span> }></Alert>
      </div>
    )
  }
  
  const onSubmit = ()=>{
    setSaving(true);
    UpsertQR({url:url}).then(data=> data.data).then(data=>{
      if(data.success){
        const redirectUrlArray = data.redirectUrl.split('/');
        const redirectUrl = redirectUrlArray.slice(-1).pop();

        updateCampaign(campaignContext.campaignDetail.id, {
          residential_real_estate: {
            short_url_code: redirectUrl
          }
        })
          .then(() => {            
            message.success('saved');
            campaignContext.reloadHeader();
            setSaving(false);
          }).catch(()=>{
            message.error('Error in updating campaign')
            setSaving(false);
          })
      }
    }).catch(e=>{
      console.log(e)
      setSaving(false);
      message.error('Error in converting URL');
    })
  }
  return (
    <>
      <div className="container">
        <div className="head d-sm-flex pt-3 pt-sm-5">
          <h3 className="title pb-1 pb-sm-4 mb-0">Tracking</h3>
        </div>
        <div className='row'>
          <div className='col-8'>
            <Form name='tracking-form'>
              <div className='mb-4'>
                <label className="form-label">Enter the URL to generate QR code.</label>
                <Form.Item name='url' initialValue={campaignContext.campaignDetail.uss_info?campaignContext.campaignDetail.uss_info.url:null}>
                  <Input 
                    addonBefore={
                      <span className="fas fa-link text-gray-600" />
                    }
                    placeholder="URL"
                    onChange={changeUrl}              
                  />
                </Form.Item>
              </div>
              <div className="text-center">
                <Button
                  className="px-5 font-weight-bold"
                  type="primary"
                  htmlType="submit"
                  size="large"                  
                  onClick={onSubmit}
                  loading={saving}>
                Update
                </Button>
              </div>
            </Form>
          </div>
          <div className='col-4'>
            <div className='text-center mb-2'>
              {url && 
            <QRCode size={228} value={url.toString()}/>
              }
            </div>

            <div className='text-center mb-4'>
              <VisitCount />
            </div>

          </div>
        </div>
        
       
       
      
        
      </div>
    </>
  )
}

export default QRPage;