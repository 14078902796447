import React, { useState } from 'react';
import { Button,message } from 'antd';
import { LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import AgentList from '../../../AgentList';
import * as api from '../../../../services/api/campaign';
import AddAgentModal from './AddAgent';
import { CampaignConsumer, useCampaignContext } from '../CampaignContext';
import { useParams } from 'react-router-dom';

const Some = props => {    
  const {campaignDetail,updateHeader} = useCampaignContext();
  const {campaignId} = useParams();
  const [addAgentModal, AddAgentModalSetter] = useState(false);
  const [updateingList, updateingListSetter] = useState(false);

  const openAddAgentModal = () => {
    AddAgentModalSetter(true);
  };

  const closeAddAgentModal = () => {
    AddAgentModalSetter(false);
  };

  const onAddAgents = agentList => {
    updateingListSetter(true);
    api
      .updateCampaign(campaignDetail.id, { agents: agentList }).then(data=>data.data)
      .then((data) => {
        message.success('agents updated successfully');        

        // update campaign context
        updateHeader(data);     
      })
      .catch(e => {
        message.error('agents updated error');
        console.log(e);
      })
      .finally(() => {
        updateingListSetter(false);
      });
  };

  const onSaveReorder = agentList => {
    updateingListSetter(true);
    api
      .updateCampaign(campaignId, { agents: agentList })
      .then(() => {
        message.success('agents updated successfully');
      })
      .catch(e => {
        message.error('agents updated error');
        console.log(e);
      })
      .finally(() => {
        updateingListSetter(false);
      });
  };

  const getAgents = () => {
    // return api.getCampaignAgents(campaignId);
    return new Promise((resolve,reject)=>{
      resolve({data:campaignDetail.agent})
    })
  };

  const removeAgent = agentid => {
    return api
      .removeCampaignAgent(campaignId, agentid)
      .then((data) => {
        message.success('Agent removed successfully');
        
        // update campaign context
        updateHeader(data);
      })
      .catch(() => {
        message.error('Remove agent error');
      });
  };

  return (
    <CampaignConsumer >
      {({isReadonly})  => (   
        <div className="container pt-5 dashboard-agents-component">
          <div>
            <h3 className="font-weight-medium d-inline-block mr-3">Agents</h3>
            {isReadonly!==true &&
          <Button
            className="align-text-bottom"
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={openAddAgentModal}
          >
            Add New Agent
          </Button>}
          </div>     
          {updateingList ? (
            <div className="text-center">
              <LoadingOutlined type="loading" size={24} spin />
            </div>
          ) : (
            <AgentList
              userRole={'agent'}
              agentListApi={getAgents}
              agentRemoveApi={removeAgent}
              isReadonly={isReadonly}
              onSaveReorder={onSaveReorder}
              enableSort={true}
              campaignId={campaignId}
            />
          )}
          {addAgentModal && (
            <AddAgentModal
              close={closeAddAgentModal}
              agentListApi={getAgents}
              onAdd={onAddAgents}
              campaignId={campaignId}
            />
          )}
        </div>
      )}
    </CampaignConsumer>
  );
};

export default React.memo(
  props => <Some {...props} />,
  (prevProps, nextProps) => {
    const prevCampaignId = prevProps.match.params.propertyId;
    const nextPropsCampaignId = nextProps.match.params.propertyId;
    return prevCampaignId === nextPropsCampaignId;
  }
);
