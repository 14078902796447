import axios from 'config/axios';
import { campaignAPIVersion } from 'services/apiVersions';

export const propertyImageAdd = (images=[], propertyId) => {
  return axios({
    method: 'post',
    url: `/api/${campaignAPIVersion}/campaign/property-image/`,
    data: {
      images,
      property_id: parseFloat(propertyId)
    }
  })
};

export const propertyUpdate = (propertyId,model) => {
  return axios({
    method: 'patch',
    url: `/api/${campaignAPIVersion}/campaign/property/${propertyId}`,
    data: model
  })
};

export const propertyGet = (propertyId) => {
  return axios({
    method: 'get',
    url: `/api/${campaignAPIVersion}/campaign/property/${propertyId}`,
  })
};
