export const configAPIVersion = 'v1'
export const portalAPIVersion = 'v1'
export const socialAPIVersion = 'v1'
export const authAPIVersion = 'v1'
export const campaignAPIVersion = 'v1'
export const productAPIVersion = 'v1'
export const agenctAPIVersion = 'v1'
export const membershipAPIVersion = 'v1'
export const addressAPIVersion = 'v1'
export const approvalAPIVersion = 'v1'

export const galleryAPIVersion = 'v2'
export const printuiAPIVersion = 'v2'
export const ussAPIVersion = 'v1'

export const pricingAPIVersion = 'v1'

export const assetAPIVersion = 'v3'

export const orderAPIVersion = 'v4'
export const xeroAPIVersion = 'v1'
export const pdfAPIVersion = 'v1'
export const domainAPIVersion = 'v1'
export const apiKeyAPIVersion = 'v1'
export const crmIntegrationAPIVersion = 'v1'
