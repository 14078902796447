import styled from 'styled-components';
const Wrapper = styled.div`

  .item{
    display:flex;
    font-size:13px;
    padding: 3px 0;
    .title{
      color:#8c8c8c;
    }
    .separator{
      flex-grow: 1;
      position: relative;
      &:after{
        content: ' ';
        width: 90%;
        height: 1px;
        background: #e1e1e1;
        top: 10px;
        right: 5%;
        position: absolute;
      }
    }
    .price{
      white-space: nowrap;
      div{
        color:#04b500;
        &.old{
          color:#ff0000;
          text-decoration: line-through;
        }
      }
    }
    &.total{
      font-weight: bold;
      .separator{
        &:after{
          content: none;
        }
      }
    }
  }

`;
export default Wrapper;