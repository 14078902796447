import React, { useEffect, useState } from 'react';
import { Button, Empty, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Item from './Item';
import './style.scss';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';
import { updateCampaign } from 'services/api/campaign';

const StyledReactSortable = styled(ReactSortable)`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
`;

const AgentList =  (props) => {
  const [agentsList, agentsListSetter] = useState([]);
  const [loading, loadingSetter] = useState(true);
  const isReadonly = props.isReadonly ? props.isReadonly : false;
  const { agentListApi } = props;
  const [sortChanged, setSortChanged] = useState(false);
  const [savingOrder, setSavingOrder] = useState(false);
  useEffect(() => {
    agentListApi()
      .then((agents) => {
        agentsListSetter(agents.data);
      })
      .finally(() => {
        loadingSetter(false);
      });
  }, [agentListApi]);

  const onRemove = (id) => {
    return props.agentRemoveApi(id);
  };

  const agentElementRemover = (itemIndex) => {
    const newAgentList = [...agentsList];
    newAgentList.splice(itemIndex, 1);
    agentsListSetter(newAgentList);
  };

  const title = {
    agent: 'Agent',
    user: 'User',
  };
  if (loading) {
    return (
      <div className="row">
        <div className="text-center w-100">
          <LoadingOutlined size={24} spin />
        </div>
      </div>
    );
  }
  if (!loading && !agentsList.length) {
    return (
      <div className="row">
        <div className="text-center w-100">
          <Empty description={`No ${title[props.userRole]} found`} />
        </div>
      </div>
    );
  }

  const onSort = () => {
    setSortChanged(true);
  };

  const saveReordering = ()=>{
    const orderedAgents = agentsList.map((item,key)=>{
      return {
        'agent_id': item['id'],
        'priority': key+1
      }
    });
    setSavingOrder(true);
    updateCampaign(props.campaignId,{agents: orderedAgents}).then(()=>{
      message.success('Agent order updated.');
      setSortChanged(false);
    }).catch(()=>{
      message.error('Error in updating agent order.')
    }).finally(()=>{
      setSavingOrder(false);
    })
  }

  return (
    <>
      {props.enableSort && (
        <div className="mb-2">
          <h6 className="text-muted">
            Please drag and drop to reorder agents.
          </h6>
        </div>
      )}
      <div className="row">
        <StyledReactSortable
          disabled={!props.enableSort}
          list={agentsList}
          setList={agentsListSetter}
          animation={150}
          onSort={onSort}
        >
          {agentsList.map((item, key) => (
            // <div className="col-12 col-sm-6 col-md-4 my-2 px-2" key={key}>
            <Item
              key={item.id}
              isReadonly={isReadonly}
              {...item}
              onRemove={onRemove}
              itemIndex={key}
              elementRemover={agentElementRemover}
              userRole={props.userRole}
              enableSort={props.enableSort}
            />
            // </div>
          ))}
        </StyledReactSortable>
      </div>
      {sortChanged && (
        <div className="mt-2 text-center">
          <Button disabled={savingOrder} onClick={saveReordering} type="primary">Save</Button>
        </div>
      )}
    </>
  );
};

export default AgentList;