
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export function initFacebookSdk() {
  return new Promise(resolve => {
    console.log('here')
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: facebookAppId,
        cookie: false,
        xfbml: false,
        // auth_type: 'reauthorize',
        version: 'v8.0',
        scope: 'pages_read_engagement,public_profile,pages_manage_posts,publish_video,publish_to_groups,read_insights,pages_show_list,pages_read_user_content'
      });

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          //   accountService.apiAuthenticate().then(resolve);
          resolve({ isLoggedIn: true, resp: authResponse });
        } else {
          resolve({ isLoggedIn: false });
        }
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  });
}