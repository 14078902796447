import React, {useState, useEffect} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {getServices} from '../../../../../../services/api/order';
import './style.scss';
import { useGlobalState } from 'Context';

const ServiceItem = (props) => {
  const active =  + props.active ? 'active' : '';
  return(
    <div className={`border rounded shadow-sm py-2 py-md-4  pl-2 pl-md-5 pr-md-3 pr-1 item ${active}` }>
      <span className={props.icon} />
      {props.title}
    </div>
  )
};

const Services =  (props) => {
  const { advanceResidential } = useGlobalState();
  const {campaignType} = props;
  const [loading, loadingSetter] = useState(true);
  const [selected, selectedSetter] = useState({});
  const [servicesList, servicesListSetter] = useState([]);

  const selectedChanger = (item) => {
    selectedSetter(item);    
    //find selected item data to get it's id
    const service = servicesList.filter(currentItem => currentItem.code === item);
    props.onChangeService(service[0]);
  };

  useEffect(() => {
    getServices(advanceResidential,campaignType).then((data) => {
      loadingSetter(false);      
      servicesListSetter([
        ...data.data.results
      ]);
    })
  }, [campaignType,advanceResidential]);

  return (
    <div className="dashboard-o-s">
      {loading && (
        <div className="text-center">
          <LoadingOutlined size={24} spin />
        </div>
      )}
      <div className="row">
        {!!servicesList.length && servicesList.map((service, key) => (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-2" key={key} onClick={() => selectedChanger(service.code)}>
            <ServiceItem icon={service.icon} title={service.name} active={selected === service.code} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;