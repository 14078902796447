import auth0Client from 'Auth/AuthClient';
import axios from 'config/axios';
import { configAPIVersion } from 'services/apiVersions';



export const getProjectConfig = (data) => {  
  axios.defaults.headers.common['Authorization'] = `Bearer ${auth0Client.getBearerToken()}`;
  return axios({
    method: 'get',
    url: `/api/${configAPIVersion}/config/info/`,
    data
  });
};


// export const getConfigForDispatch = (campaignId, productIds) => {
//   return axios({
//     method: 'get',
//     url: `api/${configAPIVersion}/config/dispatch/?productIds=${productIds.join()}&campaignId=${campaignId}`
//   });
// }

export const getConfigForDispatch = (orderIds) => {
  return axios({
    method: 'get',
    url: `api/${configAPIVersion}/config/dispatch/?orderIds=${orderIds.join(',')}`
  });
}



