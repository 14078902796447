import axios from 'config/axios';
import { productAPIVersion } from 'services/apiVersions';

export const calculatePrice = (
  productId,
  quantity,
  extraId,
  advanceResidential,
  campaign_type
) => {
  let params = {
    productId,
    quantity,
    extraId,
  };
  if (advanceResidential) {
    params = { ...params ,campaign_type};
  }
  return axios({    
    url: `/api/${productAPIVersion}/product/calculate-price/`,
    method: 'get',
    params,
  });
};
