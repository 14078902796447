import React, { useEffect, useState } from 'react';
import {Outlet, Route, useNavigate} from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { useGlobalState } from 'Context';
import auth0Client from '../Auth/AuthClient';
import { browserRoutes } from 'consts/browserRoutes';
import ProgressCard from 'component/common/ProgressCard';
// const Redirect = () => {
//   window.location = loginUrlMaker();
//   return(
//     <div className='text-center pt-5'>
//       <div className='text-black-50 pb-3'>
//         Redirecting to login page...
//       </div>      
//       <LoadingOutlined spin />
//     </div>
//   );
// };
const RedirectToLanding = ({navigate}) => { 
  navigate(browserRoutes.paymentMethodLanding);
  return(
    <div className='text-center pt-5'>
      <div className='text-black-50 pb-3'>
        Redirecting to payment method page...
      </div>      
      <LoadingOutlined spin />
    </div>
  );
};


const ProtectedRoute = () =>{
  const [loading,setLoading] = useState(true);
  const {agency} = useGlobalState();
  const navigate = useNavigate();
  // console.log('protected routes call')
  useEffect(() => {
    // console.log('+++')
    // if (!auth0Client.isAuthenticated()) {
    //   navigate('/login')
    // }
    setLoading(true);
    auth0Client.isAuthenticated().then(data=>{
      //
    }).catch(err=>{
      console.log(err); 
      navigate('/login');
    }).finally(()=>{
      setLoading(false);
    })
  }, []);

  if(loading){
    return <ProgressCard text='Loading' />
  }
  
  
  if(agency.should_add_payment_method){
    return (<RedirectToLanding navigate={navigate}/>)
  }
  return <Outlet/>
  

};

export default ProtectedRoute;

