import React, { FC } from 'react';
import { TimePicker, DatePicker } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
interface IProps {
  onChange: (input:any) => void;
  onRemove: () => void;
  defaultValue: {
    date: string;
    start: string;
  };
}

const AuctionItem: FC<IProps> = props => {
  const { onChange, defaultValue, onRemove } = props;
  const { date, start } = defaultValue;
  const momentDateObj = moment(date, 'YYYY-MM-DD');
  const momentStartObj = moment(start, 'HH:mm');

  const remove = () => {
    onRemove();
  };
  return (
    <div className="date-item">
      <DatePicker
        defaultValue={momentDateObj}
        onChange={(date, dateString) =>
          onChange({ date, dateString, type: 'Auction', kind: 'date' })
        }
      />
      <TimePicker
        format="HH:mm"
        defaultValue={momentStartObj}
        onChange={(date, dateString) =>
          onChange({ date, dateString, type: 'Auction', kind: 'start' })
        }
      />
      <DeleteOutlined type="delete" onClick={remove} />
    </div>
  );
};

export default AuctionItem;
