import React, {useEffect} from 'react';
import SocialSelector from '../Components/SocialSelector'
import { useState } from 'react';
import { Switch,Form ,Select,Button, DatePicker,Input, message} from 'antd';
import { getOrder, orderDispatch } from 'services/api/order';
import FBPreview from './FBPreview';
import { createSocialPost } from 'services/social';
import { useNavigate, useParams } from 'react-router-dom';
import { browserRoutes } from 'consts/browserRoutes';
import TitleTag from 'component/common/TitleTag';

const Option = Select.Option;

const CreateFBPost = (props)=>{  
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [photoUrl, setPhotoUrl] = useState(null);
  const [asset, setAsset] = useState(null);
  const [type,setType] = useState(null);  
  const [account,setAccount] = useState(null);  
  const [page,setPage] = useState(null);  
  const [date,setDate] = useState(null);  
  const [publishModeNow,setPublishModeNow] = useState(true);  
  const [submitting,setSubmitting] = useState(false);
  const [canSubmit,setCanSubmit] = useState(true);
  const {orderId} = useParams();
  const [order,setOrder] = useState(null);
  
  
  
  useEffect(() => {
    if(orderId){
      getOrder(orderId).then(data=>data.data.result).then(data=>{
        console.log(data);
        setOrder(data);
        if(data.fb_post && Object.keys(data.fb_post).length>0){
          message.error('This post is already posted.')
          setCanSubmit(false);
          backToCampaignPage(data.campaignId);
        }
        if(data.assetId){
          let imgArr = []
          let pageSize ='400';// data.assetId.pdf.page4? '90':'250'
          // if(!data.assetId.pdf.page3){
          //   pageSize='400';
          // }
          
          


          if(data.assetId.pdf){
            const p1 = data.assetId.pdf.page1['400'];
            imgArr.push(p1);

            if(data.assetId.pdf.page2){
              imgArr.push(data.assetId.pdf.page2[pageSize]);
            }
            if(data.assetId.pdf.page3){
              imgArr.push(data.assetId.pdf.page3[pageSize]);
            }
            if(data.assetId.pdf.page4){
              imgArr.push(data.assetId.pdf.page4[pageSize]);
            }            
          }
          else if(data.assetId.urls){
            const p1 = data.assetId.urls['400'];
            imgArr.push(p1);
          }
          
          setPhotoUrl(imgArr);
          setType('photo');
          setAsset(data.assetId);
        }      
      })
    }else{
      message.error('Order is required.')
      setCanSubmit(false);
    }
  }, []);
  
  // const onChangeImage = (value)=>{
  //   console.log(value);
  //   const urls = value.response.result.urls;
  //   console.log(urls);
  //   setPhotoUrl(urls['400']);
  // }

  const typeChanged = (value)=>{
    setType(value);
  }
  
  const backToCampaignPage = (campaignId) => {
    navigate(
      browserRoutes.campaign.dashboard.residential.order(
        campaignId?campaignId:order.campaignId
      )
    );
  }

  const onFinish = ()=>{    
    if(!page){
      message.error('Page field is required');
      return;
    }
    setSubmitting(true);
    // const data = {...values,order_id:orderId};
    let data = {
      order_id:orderId,
      user_id: account.value,
      page_id: page.value,
      content: {},
      content_type:type,
    }
    if(type==='photo'){
      data.content.url = photoUrl;
    }
    if(publishModeNow){
      data.publish_timestamp= 'now'
    }    else{
      data.publish_timestamp = date.unix();
    }
    if(type==='link'){
      data.content.link = content;
    }else{
      data.content.message = content;
    }
    createSocialPost(data).then(()=>{
      let dispatchDate = null;
      if(publishModeNow){        
        dispatchDate = new Date().toISOString();
      }else{
        dispatchDate = date.toISOString();
      }
      const paramDispatch = [{id:orderId, detail: {deliveryDate:dispatchDate}}];
      orderDispatch(paramDispatch).then(()=>{
        message.success('Post submitted successfully');
        backToCampaignPage(null);
        
      }).catch((err)=>{
        let msg = '';      
        if(err.response){
          msg = err.response.data.message;
        }
        message.error(`Error in dispatching order. ${msg}`);
        setSubmitting(false);
      })
    }).catch((err)=>{
      let msg = '';      
      if(err.response){
        msg = err.response.data.detail;
      }
      message.error(`Error occured in submiting post. ${msg}`);
      setSubmitting(false);
    })
  }

  return (
    <>
      <TitleTag title='Social Post' />
      <div className="container pt-5">
        <div className="text-center w-lg-75 mx-lg-auto">
          <h2>
            <strong>Create New Post</strong>
          </h2>            
        </div>

        <hr />
        <div className='row'>
          <div className='col-6 border-right'>
            <Form name='create-post' onFinish={onFinish} >
              <SocialSelector onAccountChange={setAccount} onPageChange={setPage} />

              <div className='row mb-4'>
                <div className='col'>                  
                  <label className="form-label">Post Type</label>                    
                  <Select
                    filterOption={false}
                    showSearch={false}
                    placeholder='Select Type'
                    size="large"          
                    // placeholder={props.placeholder}              
                    style={{ width: '100%' }}
                    onChange={typeChanged}    
                    value={type}     
                  >
                    <Option key='photo'>Photo</Option>
                    <Option key='text'>Text</Option>
                    <Option key='link'>Link</Option>
                  </Select>                                 
                </div>
              </div>
              {/* {type==='photo' && 
              <div className='row mb-4'>
                <div className='col'>
                  <div className="form-control-integrated">
                    <label className="form-label">Upload Photo</label>                    
                    <SingleFileUpload
                      addonBefore={
                        <span className="fas fa-image text-gray-600" />
                      }
                      onDone={onChangeImage}
                      additionalData={{
                        category: 'campaign',
                        subcategory: 'floorplan',
                        fileType: 'floorplan',
                        // agencyId: props.campaignId
                      }}
                    />                             
                  </div>
                </div>
              </div>
                } */}

              <div className='row'>
                <div className='col-12'>                    
                  <Input.TextArea                      
                    style={{ width: '100%' }}
                    rows={5}
                    placeholder={type==='link'?'Post Link':'Post Content'}
                    onChange={e=>{setContent(e.target.value)}}
                    value={content}
                  />                    
                </div>
                <span>
                  {/* <Picker onSelect={addEmoji} /> */}
                </span>
              </div>
           

              <div className='row mt-4'>
                <div className='col-6'>
              Publish Date <Switch onChange={(v)=>{setPublishModeNow(v)}} checkedChildren='Now' unCheckedChildren='Schedule' defaultChecked={true}></Switch>
                </div>
                <div className='col-6'>
                  {!publishModeNow &&                     
                      <DatePicker showTime={true} style={{ width: '100%' }} 
                        onChange={e=>{setDate(e)}}
                      ></DatePicker>                    
                  }
                </div>
              </div>
                



              <div className='row pt-4'>
                <div className='col text-right'>
                  <Button
                    className="px-1 px-md-3 px-lg-5 submit"
                    type="primary"
                    // htmlType={'submit'}
                    onClick={onFinish}
                    size="large"
                    loading={submitting}
                    disabled={!page || !canSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>

            </Form>
          </div>

          <div className='col-6'>
            <b>Network preview</b>

            <FBPreview userName={account?account.label:null} pageName={page?page.label:null} message={content} photos={photoUrl} isNow={publishModeNow} type={type} />
          </div>
        </div>
      </div>      
    </>
  )
}

export default CreateFBPost;