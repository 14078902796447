

export const statusTypeClasses = {
  green: 'btn-soft-success',
  processed: 'btn-soft-success',
  failed: 'btn-soft-danger',
  red: 'btn-soft-danger',
  yellow: 'btn-soft-warning',
  gray: 'btn-soft-github',
};

const OrderStatus =(props)=>{
  const {domain_listing} = props.data;
  const status = (domain_listing && Object.keys(domain_listing).length>0)? domain_listing.details.processStatus : props.data.status.title;
  const statusType = (domain_listing && Object.keys(domain_listing).length>0)? domain_listing.details.processStatus : props.data.status.type;
  return (
    <>
      <div className='col-6 col-md-2 col-lg-1 pr-0 order-1 order-md-1'>
        <div className="container d-flex h-100">
          <div className="row justify-content-center align-self-center">
            <div className='mb-3'>
              <i style={{fontSize:'27px' }} className={props.data.product?.type.service.icon} />
            </div>
            <div className={'btn btn-xs font-weight-semi-bold rounded-pill ' +statusTypeClasses[statusType]}>
              {status}              
            </div>
          </div>
        </div>

        
      </div>
    </>
  )
}

export default OrderStatus;