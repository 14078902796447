import React from 'react';
import {Checkbox} from 'antd';

export default(props) => {  
  const options = props.extra.map((item) => ({label: item.extra_info.name, value: item.extra_info.id_obj}));
  const defaults = props.extra.filter((item) => item.default).map(item => item.extra_info.id_obj);
  return(
    <div className="mt-3 separated-radio-group">
      <h6>Extra</h6>
      <Checkbox.Group options={options} onChange={props.onChange} defaultValue={defaults} />
    </div>
  );
}
