import React, { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Spin,
  InputNumber,
  Input,
  Skeleton,
  message
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm, Controller } from 'react-hook-form';
import { updateCampaign } from 'services/api/campaign';
import Wrapper from './style';
import { CampaignConsumer, useCampaignContext } from '../CampaignContext';

const Rates = () => {    
  const {campaignDetail,updateHeader} = useCampaignContext();
  const [isSubmited, setSubmited] = useState();  
  const { handleSubmit, control, reset } = useForm();

  // const {data, isLoading} = useFetchAPI(
  //   getCampaign,
  //   campaignContext.campaignDetail.id
  // );

  const {
    residential_real_estate_info: {
      property: { strata_rate, council_rate, water_rating } = {}
    } = {}
  } = campaignDetail || {};

  useEffect(() => {
    reset({
      strata_rate,
      council_rate,
      water_rating
    });
  }, [council_rate,  reset, strata_rate, water_rating]);
  const onSubmit = formData => {
    setSubmited(true);

    updateCampaign(campaignDetail.id, {
      residential_real_estate: {
        property: {
          ...formData
        }
      }
    }).then(data=>data.data).then((data) => {
      reset({...formData});
      message.success('Saved.');
      //update campaign context
      updateHeader(data);
    }).finally(() => {
      setSubmited(false);
    });
  };

  return (
    <CampaignConsumer>
      {({isReadonly})  => (
        <Wrapper className='container pt-5 form-control-integrated'>
          <h3 className='font-weight-medium d-inline-block mr-3'>Rates</h3>
          <Row>
            <Col span={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {!isSubmited ? (
                  <>
                    <label className='filed'>
                      <span className='filed__title'>Council rate:</span>
                      <Controller
                        as={Input}
                        name='council_rate'
                        control={control}
                        defaultValue={council_rate}
                        disabled={isReadonly}
                      />
                    </label>
                    <label className='filed'>
                      <span className='filed__title'>Strata rate:</span>
                      <Controller
                        as={Input}
                        disabled={isReadonly}
                        name='strata_rate'
                        control={control}
                        defaultValue={strata_rate}
                        render={({ onChange, onBlur, value }) => (
                          <Input
                            defaultValue={strata_rate}
                            size='large'
                            onBlur={onBlur}
                            onChange={value => onChange(value)}
                            value={value}
                          />
                        )}
                      />
                    </label>
                    <label className='filed'>
                      <span className='filed__title'>Water rate:</span>
                      <Controller
                        as={InputNumber}
                        disabled={isReadonly}
                        name='water_rating'
                        control={control}
                        defaultValue={water_rating}
                        render={({ onChange, onBlur, value }) => (
                          <InputNumber
                            defaultValue={water_rating}
                            size='large'
                            onBlur={onBlur}
                            onChange={value => onChange(value)}
                            value={value}
                          />
                        )}
                      />
                    </label>
                  </>
                ) : (
                  <>
                    <Skeleton active />
                    <Skeleton active />                    
                  </>
                )}
                <Button
                  type='primary'
                  htmlType='submit'
                  className='submit-btn'
                  disabled={isSubmited || isReadonly}
                >
                  
                  {!isSubmited? 'Save' : (
                    <>                      
                      Saving... <Spin disabled indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}/>
                    </>
                  )}
                </Button>
              </form>
            </Col>
          </Row>
        </Wrapper>
      )}
    </CampaignConsumer>
  );
};

export default Rates;
