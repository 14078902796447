import React from 'react';
import { Avatar } from 'antd';
import './style.scss';

export default (props) => {
  return (
    <div className='user-tag-component'>
      {props.avatar.src ? (
        <Avatar {...props.avatar} />
      ) : (
        <Avatar {...props.avatar} >{props.name[0]}</Avatar>
      )}
      {props.name}
    </div>
  );
};
