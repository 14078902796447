import React from 'react';
import PackageTypes from './PackageTypes';
import Invoice from './Invoice';
const PackageOrderer = props => {
  const {
    packages,    
    loading,
    invoiceLoading,
    priceList
  } = props;

  return (
    <div className='row'>
      <div className='col-8'>
        <div className='pt-5'>
          <div className='row'>
            <div className='col-12'>
              <PackageTypes
                packages={packages}
                onChange={props.onChange}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='col-4 mt-5 border-left'>
        {priceList && (
          <>
            <h6>Service</h6>
            <Invoice priceList={priceList} loading={invoiceLoading} />
          </>
        )}
      </div>
    </div>
  );
};

export default PackageOrderer;
