import {useState,useEffect} from 'react';
import {Button, message} from 'antd';
import {getQuotation} from 'services/api/quotation'
import { InlineInputEdit } from 'react-inline-input-edit';
import {PlusCircleOutlined,DragOutlined,DeleteOutlined} from '@ant-design/icons';
import { ReactSortable } from 'react-sortablejs';
import _ from 'lodash';
import { useCampaignContext } from '../CampaignContext';
import EasyEdit from 'react-easy-edit';
import QuotationPreview from './preview';
import './style.scss';
import ProgressCard from 'component/common/ProgressCard';
import { useGlobalState } from 'Context';

const GenerateQuotation = ()=>{
  const campaignContext = useCampaignContext();
  const [header,setHeader] = useState(null);
  const [details,setDetails] = useState(null);
  const [loading,setLoading]= useState(true);
  const [showPreview,setShowPreview]= useState(false);

  const store = useGlobalState();
  const {agency} = store;
  const hasConfig = agency.quotation_config && Object.keys(agency.quotation_config).length>0;

  const reloadData = ()=>{
    setLoading(true);
    const savedQuote = campaignContext.campaignDetail.residential_real_estate_info.quotation;
    if(savedQuote && Object.keys(savedQuote).length !== 0){
      const detailsTemp = savedQuote.items.map((key,index)=>(
        {
          id:_.uniqueId(),
          'name':key.name,
          index,
          'lines':key.lines.map(x=>(
            {
              ...x,id:_.uniqueId('lineId-')
            }
          ))
        }
      ))      
      const model = {...savedQuote}
      delete model.items;
      setHeader(model);            
      setDetails(detailsTemp);
      setLoading(false);
    }else{
      getQuotation(campaignContext.campaignDetail.id).then(data => data.data).then(result => {      
        
        const detailsTemp = result.items.map((key,index)=>(
          {
            id:_.uniqueId(),
            'name':key.name,
            index,
            'lines':key.lines.map(x=>(
              {
                ...x,id:_.uniqueId('lineId-')
              }
            ))
          }
        ))      
        const model = {...result}
        delete model.items;
        setHeader(model);            
        setDetails(detailsTemp);
      }).catch(() => {
        //
      }).finally(()=>{
        setLoading(false);
      });
    }
   
  }

  useEffect(() => {
    if(hasConfig===false){
      message.warning('No configuration found for PDF tempate. Can not proceed saving.')
    }
    reloadData()
  }, []);



  const addNewDetail = ()=>{
    setDetails([{name:'New Item', lines:[]},...details])
    //todo
  }

  const removeDetail = (index)=>{
    const tempDetails = [...details];
    tempDetails.splice(index,1);
    setDetails([...tempDetails]);
    updateTotal(tempDetails);
  }

  const changeItemHeadingName = (index,text)=>{    
    details[index].name =text;    
    setDetails([...details])
  }
  
  const addItemLine = (headerIndex)=>{
    const tempDetails = [...details];
    tempDetails[headerIndex].lines = [{name:'New Item', cost:0},...tempDetails[headerIndex].lines]
    setDetails(tempDetails)
  }

  const removeItemLine = (headerIndex,index)=>{
    const tempDetails = [...details];
    tempDetails[headerIndex].lines.splice(index,1);
    setDetails([...tempDetails]);
    updateTotal();
  }

  const updateTotal = (tempDetail=null)=>{
    let _details = details;
    if(tempDetail){
      _details = tempDetail;
    }
    let total = 0;
    _details.map(x=>(
      x.lines.map(y=>(
        total+= +y.cost
      ))
    ))

    setHeader({...header,total_cost:total});
  }

  const changeItemLine = (indexHeader,index,property,text)=>{    
    const tempDetails = [...details];
    tempDetails[indexHeader].lines[index][property] = text;
    setDetails(tempDetails)
    if(property==='cost'){
      let total = 0;
      tempDetails.map(x=>(
        x.lines.map(y=>(
          total+= +y.cost
        ))
      ))

      setHeader({...header,total_cost:total});
    }
  }

  if(loading || !header){
    return (
      <ProgressCard />
      // <div className='mt-10'>
      //   <Loader mt={20}/>
      // </div>
    );
  }

  const Header = ()=>{
    return (
      <>
        <hr></hr>
        <h4>Heading</h4>
        <div className='row mb-2'>
          <div className='col-2'>
            Campaign
          </div>
          <div className='col-8'>         
            <InlineInputEdit
              text={header.campaign_name}
              inputWidth="600px"
              inputHeight="25px"
              inputMaxLength={50}
              labelFontWeight="bold"
              inputBorderWidth={1}
              // inputFontWeight="bold"              
              onFocusOut={(text)=>{setHeader({...header,campaign_name:text})}}
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-2'>
            Agent
          </div>
          <div className='col-8'>
            <InlineInputEdit
              text={header.agent}
              inputWidth="600px"
              inputHeight="25px"
              inputMaxLength={50}
              labelFontWeight="bold"
              inputBorderWidth={1}
              // inputFontWeight="bold"              
              onFocusOut={(text)=>{setHeader({...header,agent:text})}}
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-2'>
          Vendor
          </div>
          <div className='col-8'>
            <InlineInputEdit
              text={header.vendor}
              inputWidth="600px"
              inputHeight="25px"
              inputMaxLength={50}
              labelFontWeight="bold"
              inputBorderWidth={1}
              // inputFontWeight="bold"              
              onFocusOut={(text)=>{setHeader({...header,vendor:text})}}
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-2'>
            Sale Type
          </div>
          <div className='col-8'>
            <InlineInputEdit
              text={header.sale_type}
              inputWidth="600px"
              inputHeight="25px"
              inputMaxLength={50}
              labelFontWeight="bold"
              inputBorderWidth={1}
              // inputFontWeight="bold"              
              onFocusOut={(text)=>{setHeader({...header,sale_type:text})}}
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-2'>
            Quote
          </div>
          <div className='col-8'>
            <InlineInputEdit
              text={header.quote}
              inputWidth="600px"
              inputHeight="25px"
              inputMaxLength={50}
              labelFontWeight="bold"
              inputBorderWidth={1}
              // inputFontWeight="bold"              
              onFocusOut={(text)=>{setHeader({...header,quote:text})}}
            />
          </div>
        </div>      
      </>
    )
  }

  const Items = ()=>{
    if(!details || details.length===0)
    {
      return null;
    }    
    return (
      <>
        <hr></hr>
        <div className='row'>
          <div className='col-4'><h4>Items</h4></div>
          <div className='col-8 text-right'><Button icon={<PlusCircleOutlined/>} type='primary' onClick={addNewDetail}>Add Heading</Button></div>
        </div>
        <ReactSortable
          animation={200}    
          delayOnTouchStart={true}
          list={details}
          setList={() => {
            // do nothing
          }}
          onSort={(args)=>{reorderHeaderItem(args)}}
        >       
          {details.map((x,key)=>
            (<Item key={x.id} index={key} itemData={x} />)
          )}
        </ReactSortable>
      </>
    )
  }

  const Line =(prop)=>{
    const {data,index,headerIndex} = prop;
    return (
      <div className='mb-3 dragable-small p-4'>
        <div className='row'>        
          <div className='col-8'>
            <InlineInputEdit
              text={data.name}
              inputWidth="600px"
              inputHeight="25px"
              inputMaxLength={50}
              labelFontWeight="bold"
              inputBorderWidth={1}
              onFocusOut={(text)=>{changeItemLine(headerIndex,index,'name',text)}}
            />
          </div>
          <div className='col-2'>
            $<InlineInputEdit
              text={'' +data.cost}
              inputWidth="60px"
              inputHeight="25px"
              inputMaxLength={50}
              labelFontWeight="bold"
              inputBorderWidth={1}
              type='number'
              onFocusOut={(text)=>{changeItemLine(headerIndex,index,'cost',text)}}
            />
          </div>
          <div className='col-2 text-right'>
            <Button icon={<DeleteOutlined/>} type='danger' shape='circle' size='small' onClick={()=>{removeItemLine(headerIndex,index)}}></Button>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <EasyEdit
              type="textarea"
              value={data.comment}
              hideSaveButton={true}
              hideCancelButton={true}
              saveOnBlur={true}              
              onSave={(text)=>{changeItemLine(headerIndex,index,'comment',text)}}              
              instructions="Click to edit description"
              style={{innerWidth:'100%',outerWidth:'100%'}}
            />         
          </div>
        </div>
     
      </div>
    )
  }

  
  const reorderHeaderItem = (args)=>{    
    
    const {oldIndex,newIndex} = args;
    if(oldIndex!==newIndex){
      
      const detailsTemp = [...details];      
      const currentHeaderItem = {...detailsTemp[oldIndex]};

      detailsTemp.splice(oldIndex, 1);
      detailsTemp.splice(newIndex, 0, currentHeaderItem);
      
      
      setDetails(detailsTemp);
    }   
  }
  const reorderLines = (args,headerIndex)=>{    
    
    const {oldIndex,newIndex} = args;
    if(oldIndex!==newIndex){
      
      const detailsTemp = [...details];
      const item = detailsTemp[headerIndex];
      const currentLine = {...item.lines[oldIndex]};

      detailsTemp[headerIndex].lines.splice(oldIndex, 1);
      detailsTemp[headerIndex].lines.splice(newIndex, 0, currentLine);
      
      
      setDetails(detailsTemp);
    }   
  }

  const Item = (props)=>{
    const {index,itemData} = props;    
    return (
      <>
        <div className='row dragable'>
          <div className='col bar'>
            <DragOutlined size='large' />
          </div>    
          <div className='col'>
            <div className="card mb-3">          
              <div className="card-header bg-light">
                <div className="card-header-title">
                  <div className='row'>
                    <div className='col-4'>
                      <InlineInputEdit
                        text={itemData.name}
                        inputWidth="600px"
                        inputHeight="25px"
                        inputMaxLength={50}
                        labelFontWeight="bold"
                        inputBorderWidth={1}
                        // inputFontWeight="bold"              
                        onFocusOut={(text)=>{changeItemHeadingName(index,text)}}
                      />
                    </div>
                    <div className='col-8 text-right'>
                      <Button className='mr-2' icon={<PlusCircleOutlined/>} type='ghost' onClick={()=>{addItemLine(index)}}>New Line</Button>
                      <Button icon={<DeleteOutlined/>} type='danger' onClick={()=>{removeDetail(index)}}></Button>
                    </div>
                  </div>            
                </div>
              </div>

              <div className="card-body">
                <ReactSortable
                  animation={200}              
                  list={itemData.lines}
                  setList={() => {
                    // do nothing
                  }}
                  onSort={(args)=>{reorderLines(args,index)}}
                >
                  {
                    itemData.lines.map((line,key)=>(
                      <Line key={line.id} headerIndex={index} index={key} data={line} />
                    ))
                  }
                </ReactSortable>
              </div>              
            </div>     
          </div>
        </div>        
      </>
    )
  }

  const Footer = ()=>{
    return (
      <>
        <div className='row'>
          <div className='col text-right'>
            <h4>Sub Total: ${header.total_cost.toLocaleString(undefined, {maximumFractionDigits:2})}</h4>
          </div>
        </div>
        <div className='row '>
          <div className='col text-center'>
            <Button disabled={loading} className='mr-2' size='large' type='dashed' onClick={reloadData}>Reset</Button>            
            <Button disabled={hasConfig==false} size='large' type='primary' onClick={()=>{setShowPreview(true)}}>Preview Quotation</Button>
          </div>
        </div>
      </>
    )
  }

  if(showPreview){
    return(
      <div className="container pt-5 dashboard-agents-component">
        <QuotationPreview pdfId={campaignContext.campaignDetail.residential_real_estate_info.quotation_pdf_id} header={header} details={details} campaignId={campaignContext.campaignDetail.id}  onCancel={()=>{setShowPreview(false)}}/>
      </div>
    )
  }

  const downloadPdf = ()=>{
    const link = document.createElement('a');
    link.href = campaignContext.campaignDetail.residential_real_estate_info.quotation_pdf;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div id='quote' className="container pt-5 dashboard-agents-component">
      <div className='row'>
        <div className='col'>
          <h3 className="font-weight-medium d-inline-block mr-3">Quotation</h3>
          <br></br>
          <span className='text-muted'>To start editing click on each text.</span>
        </div>
        {campaignContext.campaignDetail.residential_real_estate_info.quotation_pdf && 
        <div className='col text-right'>
          <Button  type='primary' onClick={downloadPdf}>Download Generated PDF</Button>
          {/* <a href={campaignContext.campaignDetail.residential_real_estate_info.quotation_pdf} target='_blank' download="My_File.pdf" className='btn btn-primar'> Download PDF </a> */}
        </div>
        }
      </div>    
      <Header />
      <Items />
      <Footer/>
    </div>
  )
}

export default GenerateQuotation;