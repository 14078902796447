import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {message, Button, Menu, Dropdown ,Popconfirm, Alert, Space} from 'antd';
import { DownOutlined ,DownloadOutlined} from '@ant-design/icons';
import { RouteHelperOrderTab } from 'component/propertyDashboard/Common/CampaignRouteHelper';
import ImageSlider from '../ImageSlider';
import '../../style.scss';
import StyledDiv from './style';
import { useGlobalState } from 'Context';
import {pricingChargeAlert,pricingCharge} from 'services/api/printUI'
import ProgressCard from 'component/common/ProgressCard';
import { browserRoutes } from 'consts/browserRoutes';


const ProccesDoneArtworkDetails = props => {
  const {advanceResidential,agency} = useGlobalState();  
  const [downloading,setDownloading] = useState(false);
  const [loading,setLoading] = useState(true);
  const [highResUnitAmount,setHighResUnitAmount] = useState(0);
  const {should_add_payment_method} = agency;
  let campaignType =props.campaignType?props.campaignType: 'residential-real-estate';  
  const {
    orderId,downloadable,
    propertyId,
    visibleCongrats = true,
    visibleContinueButton = true,
    asetPreviewData    
  } = props;
  // const chargeOnDownload = order.product.charge_on_download;
  const navigate = useNavigate();
  const { pdf,downloaded,proof } = asetPreviewData;
  const { file: pdfFile, pageCount } = pdf;
  const [paid,setPaid] = useState(downloaded);
  const [highRes,setHighRes] = useState(downloaded?asetPreviewData.pdf:null);
  useEffect(() => {
    if(agency.charge_high_res_artwork && agency.active_subscription){
      pricingChargeAlert().then((data)=>{
        setHighResUnitAmount(data.data.data.unit_amount/100);
        setLoading(false);
      })
    } else{
      setLoading(false);
    }
    
  }, []);

  if(!pdf){
    return null;
  }
  
  if(loading){
    return (<ProgressCard />)
  }
  const previewImages = Array(proof?proof.pageCount :pageCount)
    .fill(1)
    .map((item, index) => {
      const pageImg = proof? proof[`page${index + 1}`]: pdf[`page${index + 1}`];
      return pageImg.hiRes ? pageImg.hiRes : pageImg['400'];
    });


  const ContinueButton = visibleContinueButton ? (
    <Button
      type="primary"
      size="large"
      className="px-5"
      onClick={() =>navigate(RouteHelperOrderTab(advanceResidential,campaignType,propertyId))}
    >
      Continue
    </Button>
  ) : null;

  const TemplateSource = ()=>{
    if(visibleContinueButton) return  null;
    return (
      <strong>
      Template: {asetPreviewData.fileName}
      </strong>
    )    
  }

  const downloadHighRes = ()=>{
    setDownloading(true);
    const model ={
      type: 'high_res_template',
      orderId
    }
    pricingCharge(model).then((data)=>{
      const pdfresult = data.data.data.artwork.pdf;
      
      setHighRes(pdfresult);

      const link = document.createElement('a');
      link.href = pdfresult.file;
      link.target ='_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setPaid(true);
      setDownloading(false);
    }).catch((err)=>{
      console.log(err);
      message.error('Error in downloading');
      setDownloading(false);
    })
  }
  
  
  

  const DownloadHighRes = ()=>{
    // if product is not downloadable
    if(!downloadable) return null;
    // if(!agency.active_subscription) return null;
    
    // if agency can not download high res
    // if(agency.charge_high_res_artwork===false) 
    // {
    //   return (
    //     <Tooltip placement="topLeft" title='In order to download high-res artworks you need to have a subscription. Please contact us for further details.'>
    //       <Button
    //         type="primary"
    //         disabled
    //         ghost
    //         size="large"
    //         icon={<DownloadOutlined/>}
    //         className="mr-1"     
    //       >
    //         Download High Resolution
    //       </Button>
    //     </Tooltip>
    //   )
    // }

    // if already paid for high res download
    if(paid){
      const highResPreviewImages = Array(highRes.pageCount)
        .fill(1)
        .map((item, index) => {
          const pageImg = highRes.pdf? highRes.pdf[`page${index + 1}`] : highRes[`page${index + 1}`];
          return pageImg.hiRes ? pageImg.hiRes : pageImg['400'];
        });
      const menu = (
        <Menu>
          {
            highRes.file &&
            <Menu.Item >
              <a
                href={highRes.file}
                target="_blank"                  
                size="large"              
                className="mr-3"
              >
                <DownloadOutlined/>Download PDF
              </a>
            </Menu.Item>
          }        
          {highResPreviewImages.map((item, index) => {
            if(item){
              return (
                <Menu.Item key={index}>
                  <a
                    type="link"
                    href={item}
                    target="_blank"                  
                    size="large"
                    className="mr-3"
                    
                  >
                    <DownloadOutlined/>Image #{index + 1}
                  </a>
                </Menu.Item>
              );
            }
          })}
        </Menu>
      );
  
      return (
        <Dropdown overlay={menu} trigger="click">
          <Button
            type="primary"
            ghost
            size="large"
            icon={<DownloadOutlined/>}
            className="mr-1"
          >
            Download High Resolution <DownOutlined />
          </Button>
        </Dropdown>
      );
    }else{
      return (
        <Popconfirm
          title={`By Downloading high resolution you will be charge $${highResUnitAmount}`}
          onConfirm={downloadHighRes}
          okButtonProps={{ loading: downloading }}          
        >
          <Button
            type="primary"
            ghost
            size="large"
            icon={<DownloadOutlined/>}
            className="mr-1"            
          >
            Download High Resolution
          </Button>
        </Popconfirm>
      )
      //   if(chargeOnDownload){
      //     return (
      //       <Popconfirm
      //         title={`By Downloading high resolution you will be charge $${highResUnitAmount}`}
      //         onConfirm={downloadHighRes}
      //         okButtonProps={{ loading: downloading }}          
      //       >
      //         <Button
      //           type="primary"
      //           ghost
      //           size="large"
      //           icon={<DownloadOutlined/>}
      //           className="mr-1"            
      //         >
      //           Download High Resolution
      //         </Button>
      //       </Popconfirm>
      //     )
      //   }else{
      //     <Button
      //       type="primary"
      //       ghost
      //       size="large"
      //       icon={<DownloadOutlined/>}
      //       className="mr-1"  
      //       onClick={downloadHighRes}          
      //     >
      //     Download High Resolution
      //     </Button>
      //   }
    
    }
    
  }

  const ImagesDownloadDropdown = (() => {
    const menu = (
      <Menu>
        {
          pdfFile &&
          <Menu.Item >
            <a
              href={proof?proof.file:pdfFile}
              target="_blank"                  
              size="large"              
              className="mr-3"
            >
              <DownloadOutlined/>Download PDF
            </a>
          </Menu.Item>
        }        
        {previewImages.map((item, index) => {
          if(item){
            return (
              <Menu.Item key={index}>
                <a
                  type="link"
                  href={item}
                  target="_blank"                  
                  size="large"
                  className="mr-3"
                  
                >
                  <DownloadOutlined/>Image #{index + 1}
                </a>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger="click">
        <Button
          type="primary"
          ghost
          size="large"
          icon={<DownloadOutlined/>}
          className="mr-1"
        >
          Download Proof <DownOutlined />
        </Button>
      </Dropdown>
    );
  })();

  const ShouldAddPayment = ()=>{
    if(should_add_payment_method===false){
      return null;
    }
    return (
      <>
        <Alert
          className='mt-2'
          message="For downloading high resolution version of the file please add payment method."
          type="warning"
          action={
            <Space>
              <Button size="small" type="dashed" onClick={()=>{navigate(browserRoutes.paymentMethodLandingAuthorized)}}>
                  Add Payment Method
              </Button>
            </Space>
          }
          
        />
      </>
    )
  }

  return (
    <StyledDiv>
      <div className="container artwork-proccess-done text-center">
        {visibleCongrats ? <h2 className="pt-5 pb-2">Congrats</h2> : null}
        <div className="info pb-3">
        Please see your artwork preview below and available for download.
          <br></br>
        Be sure to check the PDF proof for Size, Spelling and LowRes Images
        </div>
        <div className="image d-inline-block" style={{ maxWidth: '100%' }}>
          <ImageSlider images={previewImages} />
        </div>
        <div className="pt-3 mb-2">
          {/* {PdfDownloadButton} */}
          {ImagesDownloadDropdown}
          <DownloadHighRes />
          <ShouldAddPayment />
          {ContinueButton}
        </div>
        <TemplateSource/>
      </div>
    </StyledDiv>
  );
};

export default ProccesDoneArtworkDetails;
