import axios from 'config/axios';

import { campaignAPIVersion, printuiAPIVersion,pdfAPIVersion } from '../apiVersions'

export const generateQuotation = (data) => {
  return axios({
    method: 'post',
    url: `/api/${printuiAPIVersion}/quotations/`,
    data,
  });
};

export const quotationPdf = (config,model,campaignId) => {
  return axios({
    method: 'post',
    // url: 'http://pdf.immersion.smart/pdf-service/api/v1/pdfservice/quotation',
    url: `/api/${pdfAPIVersion}/pdfservice/quotation/`,
    data: {config,model,campaignId},
    responseType: 'blob'
  });
};

export const getQuotation = (campaignId) => {
  return axios({
    method: 'get',
    url: `/api/${campaignAPIVersion}/campaign/generate-quote/${campaignId}/`,
  });
};
