import React, {useState} from 'react';
import {Button} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import SingleFileUpload from 'component/form/SingleFileUpload';

function EditableImage({editable, defaultImage, onChange, inputProps = {}, placeholder, saving}) {
  const [editing, editingSetter] = useState(false);
  
  const onChangeImage = (value) => {
    editingSetter(false);
    onChange(value.response.result);
  };
  return (
    <div className="editable-image">
      {saving && (
        <div className="edit-button z-index-2">
          <Button type='primary' shape="circle" loading />
        </div>
      )}
      {editable && editing && (
        <div className="mb-2">
          <SingleFileUpload {...inputProps} onDone={onChangeImage} placeholder={placeholder ? placeholder : 'Select Image to upload'} />
        </div>
      )}
      {!editing && editable && (
        <div className="edit-button">
          <Button type='primary' className='mb-8'  icon={<EditOutlined />} onClick={() => editingSetter(true)} >Edit Image</Button>
        </div>
      ) }
      {defaultImage && <img alt="default" style={{ width: '100%' }} src={defaultImage} />}      
      {!defaultImage && <span className='mb-2'>No Positional Image uploaded</span>}      
    </div>
  );
}

export default EditableImage;
