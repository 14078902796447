import { useCallback, useEffect, useState } from 'react';
export const useFetch = ({ api, params, onSuccess, onError, withApiRefrence = false }) => {
  const [data, setData] = useState({ loading: true, error: false });
  const apiCall = useCallback(() => {
    let apiCall = params ? api(params) : api();
    apiCall
      .then(resData => resData.data)
      .then(resData => {
        setData({ loading: false, error: false, data: resData, recall: apiCall });
        if (onSuccess) {
          onSuccess(resData);
        }
      })
      .catch(e => {
        console.log(e);
        setData({ loading: false, error: true });
        if (onError) {
          onError(e);
        }
      });
  }, [api, params, onSuccess, onError]);

  useEffect(() => {
    apiCall();
  }, [apiCall]);

  return withApiRefrence ? [data, apiCall] : data;
};


export const useFetchAPI = (API, defaultArgs,skipFirst=false) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skip, setSkip] = useState(skipFirst);
  const [args, setArgs] = useState(defaultArgs);
  const [refetchIndex, setRefetchIndex] = useState(0)

  const refetch = () => setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1)

  useEffect(() => {
    if(skip===true){
      setIsLoading(false);
      setSkip(false)
      return;
    }
    const fetchData = async () => {
      setIsLoading(true);
      API(args)
        .then(resData => resData.data)
        .then(resData => {          
          setError(false);          
          setData(resData);
          setIsLoading(false);      
        })
        .catch(e => {
          setError(e);
          setIsLoading(false);
          console.log(e);      
        });
    };
    fetchData();
  }, [API, args,refetchIndex]);
  return {data, isLoading,error,setArgs,refetch };
};
