import React, { useState } from 'react';
import { Button, message } from 'antd';
import Invoice from './Invoice';
import ProductTypes from './ProductTypes';
import PackageOrderer from './PackageOrderer';
import Quantity from './Quantity';
import Extra from './Extra';
import GeneralProductSelect from './ServicesProductSelect/GeneralProductSelect';
import { quantityGenerator } from './utils';
import * as orderApi from '../../../../../services/api/order';
import * as productApi from '../../../../../services/api/product';
import Services from './Services';
import { browserRoutes } from '../../../../../consts/browserRoutes';
import * as campaignApi from '../../../../../services/api/campaign';
import {
  getPackagesList,
  orderNewPackage,
  packageCalculatePrice,
} from 'services/api/packages';
import { newOrderBasedCampaign } from 'services/api/order';
import { useNavigate } from 'react-router-dom';
import { RouteHelperOrderTab } from '../../CampaignRouteHelper';
import './style.scss';
import { useCampaignContext } from '../../CampaignContext';
import { useGlobalState } from 'Context';


const NewOrderForm = (props) => {
  const { advanceResidential } = useGlobalState();
  const { campaignType } = useCampaignContext();
  const navigate = useNavigate();
  // const { type } = props;
  const orderMode = campaignType === 'order-based';
  const [productType, productTypeSetter] = useState(0);
  const [productTypes, productTypesSetter] = useState([]);
  const [selectedProduct, selectedProductSetter] = useState([]);
  const [orderType, setOrderType] = useState();
  const [, setPackages] = useState([]);
  const [extra, extraSetter] = useState([]);
  const [quantity, quantitySetter] = useState([]);
  const [orderData, orderDataSetter] = useState({});
  const [submitLoading, submitLoadingSetter] = useState(false);
  const [favoriteLoading, favoriteLoadingSetter] = useState(false);
  const [orderPrice, orderPriceSetter] = useState({});

  const [productTypeLoading, productTypeLoadingSetter] = useState(false);
  const [packageInvoiceDetails, setPackageInvoiceDetails] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const isOrderAPackage = orderType === 'Packages';
  const [invoiceLoading, setInvoiceLoading] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isSingleProduct,setIsSingleProduct] = useState(false);
  const stepsState = {
    step1: () => {
      extraSetter([]);
      quantitySetter([]);
      orderDataSetter({});
      orderPriceSetter({});
    },
    step2: () => {
      extraSetter([]);
      quantitySetter([]);
      orderDataSetter({});
      orderPriceSetter({});
    },
    step3: (product) => {
      quantitySetter([]);
      extraSetter([]);
      orderPriceSetter({});
      // timeout added to make reaction on Dom for consecutively set hook
      setTimeout(() => {
        quantitySetter(quantityGenerator(product));
        extraSetter(product.extra_options_with_defaults);
        orderDataSetter((lastData) => ({
          ...lastData,
          extra: product.extra_options_with_defaults
            .filter((item) => item.default)
            .map((item) => item.extra_info.id_obj),
        }));
      }, 0);
    },
  };

  const onChangeService = (service) => {
    
    // setIsSingleProduct(service.code === 'Listing' || service.code === 'social-media' || service.code == 'Auctions Live'); 
    setIsSingleProduct(service.code === 'Listing' || service.code == 'Auctions Live'); 
    orderPriceSetter({});
    productTypeSetter(0);
    setOrderType(service.code);
    if (service.code === 'Packages') {
      productTypeLoadingSetter(true);
      setPackageInvoiceDetails();
      getPackagesList()
        .then((res) => {
          setPackages(res.data.results);
          productTypeLoadingSetter(false);
          stepsState.step1();
          productTypesSetter(res.data.results);
        })
        .catch(() => {
          productTypeLoadingSetter(false);
        });
    } else {
      productTypeLoadingSetter(true);
      setSelectedPackage();
      orderApi
        .getProductTypes(service.id_obj,advanceResidential,campaignType)
        .then((data) => {
          stepsState.step1();
          productTypesSetter(data.data.results);
        })
        .catch(() => {
          message.error('Getting product list failed');
        })
        .finally(() => {
          productTypeLoadingSetter(false);
        });
    }
  };

  const handleProductTypeChange = (value) => {
    // far
    if (isOrderAPackage) {
      
      setSelectedPackage(value);
      setInvoiceLoading(true);
      packageCalculatePrice(value)
        .then((res) => {
          setPackageInvoiceDetails(res.data);
          setInvoiceLoading(false);
        })
        .catch(() => {
          setInvoiceLoading(false);
          setPackageInvoiceDetails(undefined);
        });
    }

    productTypeSetter(value);
    stepsState.step2();
  };

  const handleProductChange = (product) => {
    
    orderDataSetter({ product_id: product.id_obj });
    selectedProductSetter(product);
    if(!isSingleProduct){
      orderDataSetter({ product_id: product.id_obj });
    }else{
      orderDataSetter({ product_id: product.id_obj,quantity: 1  });
      orderCalculator({ product_id: product.id_obj,quantity: 1  });
    }
    stepsState.step3(product);
  };

  const handleQuantityChange = (value) => {    
    orderDataSetter({...orderData,  quantity:value});
    orderCalculator(Object.assign({}, orderData, { quantity: value }));
  };

  const handleExtraChange = (checkedValues) => {
    orderDataSetter(Object.assign({}, orderData, { extra: checkedValues }));
    orderCalculator(Object.assign({}, orderData, { extra: checkedValues }));
  };

  const orderCalculator = (orderDetail) => {
    setDisableSubmit(true);
    productApi
      .calculatePrice(
        selectedProduct.id_obj?selectedProduct.id_obj:orderDetail.product_id,
        orderDetail.quantity,
        orderDetail.extra ? orderDetail.extra.join(',') : '',
        advanceResidential,
        campaignType
      )
      .then((data) => {
        orderPriceSetter(data.data);
        setDisableSubmit(false);
      })
      .catch(() => {
        setDisableSubmit(false);
        orderPriceSetter({});
      });
  };

  const addToFavorites = () => {
    favoriteLoadingSetter(true);

    orderApi
      .addToFavorite(orderData.product_id)
      .catch(() => {
        message.error('Add to favorite error');
      })
      .finally(() => {
        favoriteLoadingSetter(false);
      });
  };

  const addToCart = () => {
    if (orderMode) {
      submitLoadingSetter(true);
      const submitData = {
        campaign_type: 'order-based',
        productId: orderData.product_id,
        quantity: orderData.quantity,
      };
      if (orderData.extra) {
        submitData['extraIds'] = orderData.extra.join(',');
      }
      newOrderBasedCampaign(submitData)
        .then(() => {
          navigate(browserRoutes.campaign.officeOrders);
        })
        .catch((err) => {
          console.log(err);
          message.error('Submit order error');
        })
        .finally(() => {
          submitLoadingSetter(false);
        });
      return;
    }
    if (isOrderAPackage) {
      submitLoadingSetter(true);
      orderNewPackage({
        campaignId: parseFloat(props.propertyId),
        productPackageId: selectedPackage,
      })
        .then(() => {
          navigate(
            RouteHelperOrderTab(
              advanceResidential,
              campaignType,
              props.propertyId
            )
          );
          // browserRoutes.campaign.dashboard.residential.order(props.propertyId)
        })
        .catch((err) => {message.error(err)})
        .finally(() => {
          submitLoadingSetter(false);
        });
    } else {
      const submitData = {
        campaign_type: campaignType,
        campaignId: parseFloat(props.propertyId),
        productId: orderData.product_id,
        quantity: orderData.quantity,
      };
      if (orderData.extra) {
        submitData['extraIds'] = orderData.extra.join(',');
      }

      submitLoadingSetter(true);
      orderApi
        .newOrder(submitData)
        .then(() => {
          campaignApi.getCampaign(parseFloat(props.propertyId)).then(() => {
            // saveCampaign(data.data);
            // todo sp reload header?
            // getCampaign(props.propertyId);
            submitLoadingSetter(false);
          });          
          navigate(
            RouteHelperOrderTab(
              advanceResidential,
              campaignType,
              props.propertyId
            )
          );
        })
        .catch((e) => {
          console.log(e);
          message.error('Submit order error');
        })
        .finally(() => {
          submitLoadingSetter(false);
        });
    }
  };
  const commandsSectionCondition =
    (orderData.product_id && orderData.quantity) ||
    (isOrderAPackage && !!selectedPackage);

  return (
    <div className="container pt-5 dashboard-order-component form-control-integrated">
      <h3 className="font-weight-medium pb-4">
        {orderMode ? 'New Office Order' : 'Order New Item'}
      </h3>
      <h6>Service</h6>
      <Services onChangeService={onChangeService} campaignType={campaignType} />

      {orderType === 'Packages' ? (
        <>
          <PackageOrderer
            packages={productTypes}
            onChange={handleProductTypeChange}
            loading={productTypeLoading}
            invoiceLoading={invoiceLoading}
            priceList={packageInvoiceDetails}
          />
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-9">
              <div className="pt-5">
                <div className="row">
                  <div className="col-12">
                    <ProductTypes
                      campaignType={campaignType}
                      productTypes={productTypes}
                      onChange={handleProductTypeChange}
                      loading={productTypeLoading}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-3 product-select">
                {productType !== 0 && (
                  <GeneralProductSelect
                    apiData={{productTypeId:productType,campaignType,advanceResidential}}
                    api={orderApi.getProducts}
                    productType={productType}
                    onSelectProduct={handleProductChange}
                    nextStep={stepsState.step2}
                    isSingleProduct={isSingleProduct}
                  />
                )}
              </div>
              {!!quantity.length && !isSingleProduct && (
                <div className="row pt-5">
                  <div className="col-6">
                    <Quantity
                      quantity={quantity}
                      onChange={handleQuantityChange}
                    />
                  </div>
                </div>
              )}
              {!!extra && !!extra.length && (
                <Extra onChange={handleExtraChange} extra={extra} />
              )}
            </div>
            {orderPrice.price_breakdown && (
              <div className="col-3 mt-5 border-left">
                <h6>Service</h6>
                <Invoice priceList={orderPrice} />
              </div>
            )}
          </div>
        </>
      )}

      {commandsSectionCondition ? (
        <React.Fragment>
          <hr className="mt-5" />
          <div className="row">
            <div className="col-12 text-right pt-5">
              <Button
                size="large"
                onClick={addToFavorites}
                loading={favoriteLoading}
              >
                <span className="fas fa-heart mr-2" />
                Add To Favorite
              </Button>
              <Button
                type="primary ml-3"
                size="large"
                onClick={addToCart}
                loading={submitLoading}
                disabled={disableSubmit}
              >
                {disableSubmit?
                  <>
                    Loading
                  </>
                  :(orderMode) ? 'Submit' : 'Add To Campaign'}                
              </Button>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};
export default NewOrderForm;
// const mapDispatchToProp = (dispatch) => {
//   return {
//     saveCampaign: (campaignDetail) => dispatch(saveCampaign(campaignDetail)),
//   };
// };

// export default connect(null, mapDispatchToProp)(NewOrderForm);
