import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

export const LoadingComponent = () => (
  <div className="text-center">
    <LoadingOutlined/>
  </div>
);

export const ErrorComponent = () => (
  <div className="text-center">
    Error
  </div>
);

export const RenderApi = ({loading, loadingComponent, error, data, render, renderError}) => {
  if(loading && loadingComponent) return loadingComponent;
  if(loading) return <LoadingComponent />;
  if(error) return renderError?renderError(error):<ErrorComponent />;
  return render(data);
};
