import React from 'react';
import { Button } from 'antd';
import ArtworkTypes from 'types/ArtworkTypes';
import PreviewPdf from 'component/form/PreviewPdf'
const ArtworkSection = (props) => {
  const {
    openPreviewModal,
    artworkPreviewImage,
    openNewArtWorkPopup,
    artworkUrl,
    editSelectedTemplate,
    artworkUrlBig,
    openSelectArtWorkPopup,
    data, // order data
  } = props;

  let artworkButtonInfo;
  if (data.dispatched && artworkPreviewImage) {    
    artworkButtonInfo = {      
      clickHandler: () =>        
        openPreviewModal(
          artworkPreviewImage?.pdf ? (
            <PreviewPdf data={artworkPreviewImage} />
          ) : (
            <img alt='artwork' style={{ width: '100%' }} src={artworkUrlBig} />
          )
        ),
      title: 'View Artwork',
    };
  }

  if (!data.dispatched) {
    switch (data.product.artwork_type) {
    case ArtworkTypes.UploadArtwork:
      artworkButtonInfo = {
        clickHandler: openNewArtWorkPopup,
        title: artworkUrl ? 'Change Artwork' : 'Upload Artwork',
      };
      break;

    case ArtworkTypes.SelectArtwork:
      artworkButtonInfo = {
        clickHandler: openSelectArtWorkPopup,
        title: artworkUrl ? 'Change Template' : 'Select Template',
        secondButton: artworkUrl
          ? {
            clickHandler: editSelectedTemplate,
            title: 'Edit Template',
          }
          : null,
      };
      break;

    case ArtworkTypes.SelectORUploadArtwork:
      artworkButtonInfo = {
        clickHandler: openNewArtWorkPopup,
        title: artworkUrl ? 'Change Artwork' : 'Select Artwork',
        secondButton: artworkUrl
          ? {
            clickHandler: editSelectedTemplate,
            title: 'Edit Template',
          }
          : null,
      };
      break;
    case ArtworkTypes.SocialArtwork:
      artworkButtonInfo = {
        clickHandler: openNewArtWorkPopup,
        title: artworkUrl ? 'Change Artwork' : 'Upload Artwork',
        secondButton: artworkUrl
          ? {
            clickHandler: editSelectedTemplate,
            title: 'Edit Template',
          }
          : null,
      };
      break;
  
    default:
      console.error('what to do if non of above occured');
      break;
    }
  }

  if(!artworkButtonInfo){
    return null;
  }

  return (
    <>
      <Button
        size='small'
        className='align-text-bottom'
        type=''
        shape='round'
        onClick={artworkButtonInfo.clickHandler}
      >
        {artworkButtonInfo.title}
      </Button>
      {artworkButtonInfo.secondButton && (
        <Button
          size='small'
          className='align-text-bottom mt-2'
          type=''
          shape='round'
          onClick={artworkButtonInfo.secondButton.clickHandler}
        >
          {artworkButtonInfo.secondButton.title}
        </Button>
      )}
    </>
  );
};

export default ArtworkSection;
