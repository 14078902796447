import axios from 'config/axios';
import { printuiAPIVersion, portalAPIVersion } from '../apiVersions'

export const getProductTemplates = (productId) => {
  return axios({
    method: 'get',
    url: `/api/${printuiAPIVersion}/templates/?productIds=${productId}&limit=100`,
  });
};

export const createJob = (data) => {
  return axios({
    method: 'post',
    url: `/api/${printuiAPIVersion}/jobs/`,
    data
  });
};


export const pricingChargeAlert = () => {
  return axios({
    method: 'get',
    url: `/api/${portalAPIVersion}/portal/pricing/alert/?type=high_res_template`,
  });
};


export const pricingCharge = (data) => {
  return axios({
    method: 'post',
    url: `/api/${portalAPIVersion}/portal/pricing/charge/`,
    data
  });
};


/**
 * 
 * @param {*} orderId 
 * Response Sample :
{
    "result": [
        "https://printui-previews.s3.ap-southeast-2.amazonaws.com/5ed8f778e5762a0089c73bff-1594116216220/1.jpg",
        "https://printui-previews.s3.ap-southeast-2.amazonaws.com/5ed8f778e5762a0089c73bff-1594116216220/2.jpg",
        "https://printui-previews.s3.ap-southeast-2.amazonaws.com/5ed8f778e5762a0089c73bff-1594116216220/3.jpg",
        "https://printui-previews.s3.ap-southeast-2.amazonaws.com/5ed8f778e5762a0089c73bff-1594116216220/4.jpg",
        "https://printui-previews.s3.ap-southeast-2.amazonaws.com/5ed8f778e5762a0089c73bff-1594116216220/5.jpg",
        "https://printui-previews.s3.ap-southeast-2.amazonaws.com/5ed8f778e5762a0089c73bff-1594116216220/6.jpg"
    ],
    "message": "Previews Generated",
    "success": true
}
 */
export const getPreview = (orderId) => {
  return axios({
    method: 'get',
    url: `/api/${printuiAPIVersion}/jobs/reqPreview/${orderId}/`
  });
};





export const checkFile = (orderId) => {
  return axios({
    method: 'get',
    url: `/api/${printuiAPIVersion}/jobs/checkFile/${orderId}/`
  });
};

export const editJob = (orderId) => {
  return axios({
    method: 'get',
    url: `/api/${printuiAPIVersion}/jobs/edit/${orderId}/`
  });
};