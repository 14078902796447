import { imageUrlMaker } from 'utils';

let token = localStorage.getItem('token')
  ? JSON.parse(localStorage.getItem('token'))
  : '';

export const initialState = {
  isLoggedIn: false,
  user: '',
  agency: '',
  token: '' || token,
  loading: false,
  errorMessage: null,
};


export const ConvertPayloadConfig =(data)=>{
  if(!data || Object.keys(data).length===0){
    return null;
  }
  const model = {
    user: data.user,
    email: data.user.email,
    agency: data.agency,
    headerImage: imageUrlMaker(data.agency.agency_logo, 250),
    heroImage: data.agency.hero_image
      ? data.agency.hero_image.urls.original
      : null,
    advanceResidential:
          data.agency.campaign_type === 'advanced-residential',
    isLoggedIn: true,
  };
  return model;
}

export const GlobalReducer = (initialState, action) => {
  switch (action.type) {
  case 'REQUEST_LOGIN':
    return {
      ...initialState,
      loading: true,
    };
  case 'CONFIG_INFO':
  {
    const payload = ConvertPayloadConfig(action.payload)
    return {
      ...initialState,
      ...payload
      // user: action.payload.user,
      // email: action.payload.user.email,
      // agency: action.payload.agency,
      // headerImage: imageUrlMaker(action.payload.agency.agency_logo, 250),
      // heroImage: action.payload.agency.hero_image
      //   ? action.payload.agency.hero_image.urls.original
      //   : null,
      // advanceResidential:
      //     action.payload.agency.campaign_type === 'advanced-residential',
      // isLoggedIn: true,
    };
  }
  case 'LOGIN_SUCCESS':
    return {
      ...initialState,
      // user: action.payload.user,
      token: action.payload.auth_token,
      loading: false,
      isLoggedIn: true,
    };
  case 'LOGOUT':
    return {
      ...initialState,
      user: '',
      agency: '',
      token: '',
      email: '',
      isLoggedIn: false,
      headerImage: '',
    };

  case 'LOGIN_ERROR':
    return {
      ...initialState,
      loading: false,
      errorMessage: action.error,
    };

  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
};
