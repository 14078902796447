export interface ReducerActionPayloadForRemove {
    index: number
}
export interface ReducerActionPayloadForAll {
    auction: string,
    inspection_times: [string]
}

export interface ReducerActionPayloadForUpdateInsert {
    index?: number;
    date: string;
    start: string;
    end?: string;
}

export interface ReducerAction {
    type: string;
    payload?: ReducerActionPayloadForUpdateInsert |
    ReducerActionPayloadForRemove |
    ReducerActionPayloadForAll | string;
}

export interface Dates {
    auction: string;
    inspection_times: [string];
}

export type createDateStrType = 'auction' | 'inspection' | '';

export enum StateReducerActions {
    AddAuction = 'AddAuction',
    AddInspection = 'AddInspection',
    UpdateAuction = 'UpdateAuction',
    UpdateInspection = 'UpdateInspection',
    RemoveInspection = 'RemoveInspection',
    RemoveAuction = 'RemoveAuction',
    All = 'All',
}