import axios from 'config/axios';
import { campaignAPIVersion,crmIntegrationAPIVersion } from 'services/apiVersions';

export const getCampaignOverview = () => {
  return axios({
    url: `/api/${campaignAPIVersion}/campaign/campaign-overview/`,
    method: 'get',
    // params: filter
  });
};

export const getListApi = (filter) => {  
  return axios({
    url: `/api/${campaignAPIVersion}/campaign/campaign/`,
    method: 'get',
    params: filter
  });
};

export const createCampaign = (data) => {
  return axios({
    method: 'post',
    url: `api/${campaignAPIVersion}/campaign/campaign/`,
    data
  });
};

export const updateCampaign = (id, data) => {
  return axios({
    method: 'patch',
    url: `/api/${campaignAPIVersion}/campaign/campaign/${id}/`,
    data
  })
};

export const updateCampaignPro = (id, data) => {
  return axios({
    method: 'patch',
    url: `/api/${campaignAPIVersion}/campaign/campaign/${id}/`,
    data
  })
};

export const crmIntegration = (campaignId) => {
  return axios({
    method: 'post',
    url: `/api/${crmIntegrationAPIVersion}/crm/files/alFtpUpload`,
    data : {campaignId:campaignId}
  })
};


export const getCampaign = (id) => {
  return axios({
    method: 'get',
    url: `/api/${campaignAPIVersion}/campaign/campaign/${id}/`
  })
};

export const getCampaignAgents = async (id) => {
  return new Promise((resolve, reject) => {
    getCampaign(id).then(data => {
      resolve({data:data.data.agent});
    }).catch(e => {
      reject(e);
    });
  });
};

export const removeCampaignAgent = (campaignId, agentId) => {
  return new Promise((resolve, reject) => {
    getCampaign(campaignId).then(data => {      
      const newAgentList = data.data.agent.filter(item => item.id !== agentId).map(item => ({'agent_id':item.id, 'priority': item.priority}));
      updateCampaign(campaignId, {agents: newAgentList}).then(data => {
        resolve(data.data);
      }).catch(e => {
        reject(e);
      });
    }).catch(e => {
      reject(e);
    });
  });
};
