import React from 'react';
import ProductCascader from '../../../../../../form/Cascader';
import SingleProductSelector from './singleProductSelector'
import './style.scss';

class GeneralProductSelect extends React.Component{
  onChange = (data) => {    
    this.props.onSelectProduct(data);
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.productType !== this.props.productType;
  }

  render(){
    if(this.props.isSingleProduct){
      return (
        <div className={'row'}>
          <div className="col-12">
            <SingleProductSelector 
              api={this.props.api}
              apiData={this.props.apiData}
              onChange={this.onChange}
            />
          </div>        
        </div>        
      )
    }else{
      return (
        <div className={'row'}>
          <div className="col-12">
            <ProductCascader
              api={this.props.api}
              apiData={this.props.apiData}
              onChange={this.onChange}
              style={{width: '100%'}}
            />
          </div>
        </div>
      );
    }
    
  }
}

export default GeneralProductSelect;
