import React,{useEffect} from 'react';
// import client1Logo from 'theme/assets/svg/clients-logo/stripe.svg';
// import backgroundImg from 'theme/assets/svg/components/abstract-shapes-20.svg';
import AuthLockService from 'Auth/AuthLockService';
import qs from 'query-string';
import { useLocation } from 'react-router';
import './style.css'
import VFXlogoImage from 'theme/assets/img/supplier/VFX_Connect_Logo.jpg';
import LogoImage from 'theme/assets/img/ezi-Logo.png'
const LoginPage =()=>{  
  const location = useLocation();
  const queryStringValues = qs.parse(location.search);
  const { supplier = '' } = queryStringValues;
  useEffect(() => {        
    let lock = new AuthLockService(); 
    lock.initLoginContainer();
    lock.lock.show({rememberLastLogin:true});
  }, []);

  const getLogoURIBaseOnSupplierNameFromQueryString = () => {
    switch (supplier.toLowerCase()) {
    case 'vfx':
      return VFXlogoImage;
    default:
      return LogoImage;
    }
  };

  return (
    <>
      <div className="login-container">
        <div className='card-sm shadow-lg' style={{width:'30rem'}}>
          
          <div className="card-body">
            <div className='text-center'>
              <img
                height="120px"
                src={getLogoURIBaseOnSupplierNameFromQueryString()}
                className="mt-2 w-auto"
                alt="Ezi Logo"
              />
            </div>
            <div className="">                  
              <div id="hiw-login-container"></div>
            </div>
          </div>
        </div>
      </div>
      <div className='template-footer'>
        <hr/>
        <div className="text-center my-2 py-2">
        © 2021 Ezi by Media Link Technologies
        </div>
      </div>
      {/* <header id="header" className="header header-bg-transparent header-abs-top">
        <div className="header-section">
          <div id="logoAndNav" className="container-fluid">
            <nav className="navbar navbar-expand header-navbar">
              <span className="d-none d-lg-flex navbar-brand header-navbar-brand" href="../landings/index.html" aria-label="Front">
                <img src={getLogoURIBaseOnSupplierNameFromQueryString()} alt="Logo" />
              </span>
              <a className="d-flex d-lg-none navbar-brand header-navbar-brand header-navbar-brand-collapsed" href="../landings/index.html" aria-label="Front">                
              </a>
              <div className="ml-auto">            
              </div>
            </nav>
          </div>
        </div>
      </header>
  
      <main id="content" role="main">
        <div className="d-flex align-items-center position-relative vh-lg-100">
          <div className="col-lg-5 col-xl-4 d-none d-lg-flex align-items-center bg-navy vh-lg-100 px-0" style={{backgroundImage: `url(${backgroundImg})` }}>
            <div className="w-100 p-5">
              <div className="w-md-80 w-lg-60 text-center mx-auto">
                <blockquote className="h3 text-white font-weight-normal mb-4">The design is really nice and offers quite a large set of options. Thank you!</blockquote>
                <span className="d-block text-white-70">Sp, Google</span>
              </div>          
              <div className="position-absolute right-0 bottom-0 left-0 text-center p-5">
                <span className="d-block text-white-70 mb-3">Front partners</span>
                <div className="d-flex justify-content-center">
                  <img className="max-w-10rem mx-auto" src={client1Logo} alt="Client 1" />
                  <img className="max-w-10rem mx-auto" src={client1Logo} alt="Client 1" />
                  <img className="max-w-10rem mx-auto" src={client1Logo} alt="Client 1" />                  
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-3 space-lg-0">
            
                <div className="mb-5 mb-md-7">                  
                  <div id="hiw-login-container"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </main>    */}
    </>
  )

}

export default LoginPage;