import axios from 'config/axios';
import { assetAPIVersion } from 'services/apiVersions';

export const getImagesList = (params) => {
  return axios({
    method: 'get',
    url: `/api/${assetAPIVersion}/assets/`,
    params
  });
};

export const getSingleImage = (id) => {
  return axios({
    method: 'get',
    url: `/api/${assetAPIVersion}/assets/${id}/`,
  });
};

export const updateSingleImage = (id, data) => {
  return axios({
    method: 'patch',
    url: `/api/${assetAPIVersion}/assets/${id}/`,
    data: data
  });
};
