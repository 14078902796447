import {useEffect} from 'react';

const useOutsideAlerter = (ref, closeSubMenus) =>{
  useEffect(() => {
    /**
       * Alert if clicked on outside of element
       */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeSubMenus();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, closeSubMenus]);
}

export default useOutsideAlerter;