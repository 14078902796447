import React, { useState } from 'react';
import { Button, Empty, Popconfirm, Tag, Pagination, Skeleton } from 'antd';
import {  
  DownloadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import OrderItem from './OrderItem';
import { browserRoutes } from '../../../../consts/browserRoutes';
import { orderList as getOrdersByCampaignId } from 'services/api/order';
import { cancelPackage, removePackage } from 'services/api/packages';
import { CampaignConsumer, useCampaignContext } from '../CampaignContext';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalState } from 'Context';
import {RouteHelperCreateOrder} from '../CampaignRouteHelper';
import { useFetchAPI } from 'services/api/useFetch';
import ArtworkTypes from 'types/ArtworkTypes';
import ProgressCard from 'component/common/ProgressCard';


const Order = (props) => {  
  const navigate = useNavigate();  
  const campaignContext = useCampaignContext();  
  const { advanceResidential} = useGlobalState();
  const [dispatchItems, dispatchItemsSetter] = useState({});
  const [selectedParent, selectedParentSetter] = useState('');
  const [selectedToRemove, setSelectedToRemove] = useState();
  const {data:orderList, isLoading,setArgs,refetch} = useFetchAPI(
    getOrdersByCampaignId,
    {
      campaignId: campaignContext.campaignDetail.id,
      page: 1,
    }
  );
  const isEmpty = !isLoading ? orderList.result.length === 0 : false;
  
  const doRefresh = () => {
    setSelectedToRemove();    
    refetch();  
  };

  const parentSelectHandler = (id) => {
    selectedParentSetter(id);
    setTimeout(selectedParentSetter, 2500, '');
  };

  const onSelectToDispatch = (order) => {
    let newItems = { ...dispatchItems };
    if (newItems[order['_id']]) {
      delete newItems[order['_id']];
    } else {
      newItems[order['_id']] = order;
    }
    dispatchItemsSetter(newItems);
  };

  const ordersToShow = !isLoading
    ? Object.keys(dispatchItems).length
      ? orderList.result.filter((item) => {
        return (
          item.canDispatch &&
            (item.product.artwork_type === 0 ||
              item.assetId ||
              (item.product.artwork_type === 2 && item.job.asset))
        );
      })
      : orderList.result
    : [];

  const orderItemCanDispatch = (item) => {
    const artworkUrl =
      item.artworkType === ArtworkTypes.UploadArtwork
        ? item.assetId
        : item.job?.asset;
    const can =
      (item.canDispatch || item.dispatched) &&
      (item.product.artwork_type === ArtworkTypes.NoArtwork ||
        item.product.artwork_type === ArtworkTypes.PredefinedArtwork ||
        artworkUrl) &&
      !item.generatingArtwork;
    return can;
  };
  const groupOrderItems = (Orders) => {
    const groupIndex = {};
    const groupedOrders = Orders.reduce((acc, curentItem) => {
      const { packageId, packageName } = curentItem;
      if (packageId) {
        const isAlreadyIndexd = groupIndex[packageId];
        if (isAlreadyIndexd > -1) {
          acc[isAlreadyIndexd].dispatched =
            acc[isAlreadyIndexd].dispatched || curentItem.dispatched;

          acc[isAlreadyIndexd].generatingArtwork =
            acc[isAlreadyIndexd].generatingArtwork ||
            curentItem.generatingArtwork;
          acc[isAlreadyIndexd].canDispatch =
            orderItemCanDispatch(curentItem) &&
            acc[isAlreadyIndexd].canDispatch;

          acc[isAlreadyIndexd].items.push(curentItem);
        } else {
          const pacakgeWithItems = {
            isGroup: true,
            id: packageId,
            packageName,
            dispatched: curentItem.dispatched,
            generatingArtwork: curentItem.generatingArtwork,
            canDispatch: orderItemCanDispatch(curentItem),
            items: [curentItem],
          };
          acc.push(pacakgeWithItems);
          groupIndex[packageId] = acc.length - 1;
        }

        return acc;
      } else {
        acc.push(curentItem);
      }
      return acc;
    }, []);

    return groupedOrders;
  };

  // const dispatchOrders = (campaignType) => {
  //   navigate({
  //     pathname: browserRoutes.order.dispatch,
  //     search: `?orderIds=${Object.keys(dispatchItems).join()}`,
  //     // state: { items: Object.values(dispatchItems),campaignType },
  //     state: { test: 'test'}
  //   });   
  // };
  // const dispatchPackage = (packgeData,campaignType) => {
  //   const { items } = packgeData;    
  //   navigate({
  //     pathname: browserRoutes.order.dispatch,
  //     search: `?orderIds=${items.map((item) => item._id).join()}`,
  //     state: { items: Object.values(items),campaignType },
  //   });    
  // };
  
  const doPagination = (pageNumber) => {    
    setArgs({
      // campaignId: props.match.params.propertyId, //todo sp check correctness
      campaignId: campaignContext.campaignDetail.id,
      page: pageNumber,
    });
  };

  if(isLoading){
    return (<ProgressCard text='Loading Orders ...' />)
  }

  return (
    // <Container propertyId={props.match.params.propertyId}>
    <CampaignConsumer>        
      {({isReadonly,campaignType})  => (
        <div className='container pt-5 dashboard-agents-component'>
          <div className='d-flex justify-content-between'>
            <div className='left'>
              <h3 className='font-weight-medium d-inline-block mr-3'>
                Orders
              </h3>
              {isReadonly!==true && (
                <Button
                  className='align-text-bottom'
                  type='primary'
                  shape='round'
                  icon={<PlusOutlined />}
                  // onClick={() => {navigate(RouteHelperCreateOrder(advanceResidential,campaignType,props.match.params.propertyId,campaignType));}} //todo sp
                  onClick={() => {navigate(RouteHelperCreateOrder(advanceResidential,campaignType,campaignContext.campaignDetail.id));}}
                >
                    New Order
                </Button>
              )}
            </div>
            {!!Object.keys(dispatchItems).length && (
              <div className='right'>
                <Link to={`${browserRoutes.order.dispatch}?orderIds=${Object.keys(dispatchItems).join()}`} state={{ items: Object.values(dispatchItems),campaignType }}>
                  <Button
                    className='align-text-bottom'
                    type='primary'
                    shape='round'
                    icon={<DownloadOutlined />}
                  // onClick={()=>{dispatchOrders(campaignType)}}
                  >
                    Dispatch Orders
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <div className='className'>            
            <>
              {isEmpty ? (
                <Empty />
              ) : (
                <>
                  {groupOrderItems(ordersToShow).map((item, key) => {
                    return item.isGroup ? (
                      item.id === selectedToRemove ? (
                        <>
                          <Skeleton active />
                          <Skeleton active />
                        </>
                      ) : (
                        <div className='package-group row ' key={item.id}>
                          <div className='col-12'>
                            <div className='package-group__header'>
                              <Tag className='package-group__name'>
                                {item.packageName} {item.id}
                              </Tag>
                              <div className='package-group__actions'>
                                <Popconfirm
                                  key={'remove'}
                                  placement='topLeft'
                                  title={'Are you sure?'}
                                  onConfirm={() => {
                                    setSelectedToRemove(item.id);
                                    removePackage(item.items[0]._id).then(
                                      () => {
                                        // removePackage(item._id).then((res) => {
                                        doRefresh();
                                      }
                                    );
                                  }}
                                  okText='Yes'
                                  cancelText='No'
                                >
                                  <span className='text-danger'>
                                        Remove
                                  </span>
                                </Popconfirm>
                                    |
                                <Popconfirm
                                  key={'cancel'}
                                  placement='topLeft'
                                  title={'Are you sure?'}
                                  onConfirm={() => {
                                    setSelectedToRemove(item.id);
                                    cancelPackage(item.items[0]._id).then(
                                      () => {
                                        doRefresh();
                                      }
                                    );
                                  }}
                                  okText='Yes'
                                  cancelText='No'
                                >
                                  <span className='text-danger'>
                                        Cancel
                                  </span>
                                </Popconfirm>
                              </div>{' '}
                            </div>

                            {item.items.map((packageGroupItem,i) => (
                              <div key={i}>
                                <OrderItem
                                  isPackage={true}
                                  key={packageGroupItem._id}
                                  campaignType={campaignType}
                                  selectedAsParent={selectedParent === item['_id']}
                                  itemKey={key}
                                  data={packageGroupItem}
                                  doRefresh={doRefresh}
                                  onSelectToDispatch={onSelectToDispatch}
                                  propertyId={campaignContext.propertyId}
                                  campaignId={campaignContext.campaignDetail.id}
                                  parentSelectHandler={parentSelectHandler}
                                />
                              </div>
                            ))}
                          </div>
                          <div
                            className='col-12 '
                            style={{ textAlign: 'right' }}
                          >
                            {item.canDispatch && (                                  
                              <Link to={`${browserRoutes.order.dispatch}?orderIds=${item.items.map((item) => item._id).join()}`} state={{items: Object.values(item.items),campaignType}}>
                                <Button
                                  className='align-text-bottom'
                                  type='primary'
                                  shape='round'
                                  icon={<DownloadOutlined />}
                                  disabled={item.dispatched}
                                // onClick={() => dispatchPackage(item,campaignType)}
                                >
                                  {item.dispatched
                                    ? 'Dispatched'
                                    : 'Dispatch Package'}
                                </Button>     
                              </Link>                             
                            )}

                            {item.generatingArtwork && (
                              <span>generatingArtwork</span>
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      <OrderItem
                        key={item._id}
                        selectedAsParent={selectedParent === item['_id']}
                        itemKey={key}
                        data={item}
                        doRefresh={doRefresh}
                        onSelectToDispatch={onSelectToDispatch}
                        propertyId={campaignContext.propertyId}
                        campaignId={campaignContext.campaignDetail.id}
                        parentSelectHandler={parentSelectHandler}
                      />
                    );
                  })}
                  <Pagination
                    defaultCurrent={orderList.page}
                    pageSize={20}
                    total={orderList.allPages * 20}
                    onChange={doPagination}
                  />
                </>
              )}
            </>
            
          </div>
        </div>
      )}
    </CampaignConsumer>
    // </Container>
  );
};

export default Order;
