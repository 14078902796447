import auth0 from 'auth0-js';

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      responseType: 'token id_token',
      scope: 'openid profile email'
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  
  getProfile(cb) {
    
    const profilePayload = this.getProfilePayload();
    if(!profilePayload){
      return null;
      // cb('Profile not found',null);
    }    
    cb(null,JSON.parse(profilePayload));


    // if(this.profile)
    // {
    //   cb(null,this.profile);      
    //   return;
    // }
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      cb('Access token must exist to fetch profile',null);
      console.log('Access token must exist to fetch profile');
      return;
      // cb({error: 'no access token'}, null);
    }    
  
  }
  getProfileWithoutCallback() {
    const profilePayload = this.getProfilePayload();
    if(!profilePayload){
      return null;
    }    
    return JSON.parse(profilePayload); 
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated = () => { 
    return new Promise((resolve, reject) => {
      const expireAt = localStorage.getItem('expires_at');
      if(!expireAt)  {
        reject(false)        
      }

      const expiresAtParsed =JSON.parse(expireAt);
      const notExpired = new Date().getTime() <= expiresAtParsed;


      if(notExpired){
        // console.log(' >>>> Token is valid. will expiried in:', new Date(expiresAtParsed));
        resolve(true);
      }else{
        // console.log(' >>>> Token Expired in (renewing):', new Date(expiresAtParsed));        
        this.auth0.checkSession({},(err, authResult) => {        
          console.log(err);
          if (err) return reject(err);          
          if (!authResult || !authResult.idToken) {
            return reject(err);
          }        
          this.idToken = authResult.idToken;
          this.profile = authResult.idTokenPayload;
          // set the time that the id token will expire at
          this.expiresAt = authResult.idTokenPayload.exp * 1000;
          this.setSession(authResult);
          resolve(true);
        });              
      }

    })


    ////
    // this.auth0.checkSession({nonce: '1234'}, function(err, authResult) {
    //   if(err){
    //     console.log(err);
    //     return new Promise((resolve,reject)=>{
    //       reject(err)
    //     })
    //   }else{
    //     this.setSession(authResult)
    //   }
    //   console.log(authResult)
    //   console.log(err)
    // });
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>');
  }

  isAuthenticated2 = () => { 
    const expireAt = localStorage.getItem('expires_at');
    if(!expireAt) return false;
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    const expiresAtParsed =JSON.parse(expireAt);
    return (new Date().getTime() < expiresAtParsed);
  }

  signIn() {    
    this.auth0.authorize();
  }

  handleAuthentication() {
    // console.log('handle auth');
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        // console.log('handle auth2');
        // console.log(err);
        if (err) return reject(err);
        // console.log(authResult);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }        
        const metadata = authResult.idTokenPayload['http://doamin.com/user_metadata'];
        // console.log(metadata)
        if(!metadata){
          return reject('Role not found');
        }
        if(metadata && !['Agent', 'AgencyAdmin', 'General'].includes(metadata.role)){
          return reject('Invalid role');
        }
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        this.setSession(authResult);
        resolve();
      });
    })
  }

  signOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('profile');
    localStorage.removeItem('tmpCV');
    // clear id token, profile, and expiration
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
    this.auth0.logout({
      returnTo: process.env.REACT_APP_BASE_PATH,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  }

  setSession = authResult => {
    //set the time the access token will expire
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // we will set profile from getconfig api (in callback component)
    // localStorage.setItem('profile', JSON.stringify(authResult.idTokenPayload));
  }

  
  getProfilePayload = ()=>{
    const profilePayload = localStorage.getItem('profile')
    return profilePayload;

  }

  getAccessToken = () => {
    const accessToken = localStorage.getItem('access_token')
    if(!accessToken){
      // throw new Error('No access token found')
    }else{
      return accessToken
    }
  }
  getBearerToken = () => {
    const accessToken = localStorage.getItem('id_token')
    // console.log(accessToken);
    return accessToken
  }
}

const auth0Client = new Auth();

export default auth0Client;
