import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, Form } from 'antd';
// import { debounce } from "../../../utils/debounce";
import debounce from 'lodash/debounce';
import './style.scss';

const Option = Select.Option;

const TagSelect = (props) => {
  const { fieldName, mode, initialValue } = props;
  const [lastFetchId, setLastFetchId] = useState(0);
  const [fetching, setFetching] = useState(false);
  // const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);

  const ids = initialValue?.map((x) => x.value);
  useEffect(() => {    
    // fetchUser = debounce(fetchUser, 800);
    
    const defaultOptions = initialValue?.map((d,i) => (
      <Option key={i} value={d.value}>
        {d.text}
      </Option>
    ));

    setOptions(defaultOptions);
    // setValue(ids);
  }, [initialValue]);

  let fetchUser = (value) => {    
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    setFetching(true);
    setOptions([]);
    // this.setState({ data: [], fetching: true });
    props
      .api(value)
      .then((body) => body.data)
      .then((body) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        const data = body.map(props.options);                
        const newOptions = data.map((d) => (
          <Option key={d.value} value={d.value}>
            {d.text}
          </Option>
        ));
        setOptions(newOptions);
        setFetching(false);
      })
      .catch(() => {
        setFetching(false);
      });
  };

  return (
    <div className="form-control-integrated tag-select">
      <Form.Item name={fieldName} initialValue={ids}>
        <Select
          size="large"
          mode={mode}
          // value={value}
          placeholder="Select users"
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={debounce(fetchUser, 500)}
          style={{ width: '100%' }}
        >
          {options}         
        </Select>
      </Form.Item>
    </div>
  );
};

TagSelect.defaultProps = {
  mode: 'multiple',
};

TagSelect.propTypes = {
  mode: PropTypes.oneOf(['multiple', 'tags']),
  api: PropTypes.func.isRequired,
  options: PropTypes.func.isRequired,
};

export default TagSelect;

/*
Sample Options
this.props.options = (user) => ({
  text: <UserTag key={user.id} name={user.name} avatar={{src:user.src}} />,
  value: user.id,
});*/
