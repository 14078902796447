import React from 'react';
import {Select} from 'antd';

const {Option} = Select;

export default (props) => {
  return(
    <React.Fragment>
      <label className="form-label">Quantity</label>
      <Select size='large' defaultValue="select" style={{width: '100%'}} onChange={props.onChange} data-testid="product-quantity" dropdownClassName="product-quantity-dropdown" >
        {props.quantity.map((item) => (
          <Option value={item} key={item}>{item}</Option>
        ))}
      </Select>
    </React.Fragment>
  );
}
