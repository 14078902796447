import Auth0Lock from 'auth0-lock';


export class AuthLockService {
  lock;
  initLoginContainer() {
    this.lock = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID, process.env.REACT_APP_AUTH0_DOMAIN, {
      allowedConnections: ['Username-Password-Authentication'],      
      container: 'hiw-login-container',
      avatar:null,
      allowShowPassword:true,
      rememberLastLogin:true,
      auth: {
        
        sso: false,
        redirectUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        responseType: 'id_token token',
        params: {
          state: 'agency',
          scope: 'openid email profile user_metadata app_metadata picture',          
        },        
      },
      theme: {
        logo: './logo.png',
        primaryColor: '#377dff',
      },
      allowSignUp: false,            
      
      languageDictionary: {
        emailInputPlaceholder: 'something@youremail.com',
        title: 'Login to your account'
      }
    });
  }

  initSignupContainer() {
    this.lock = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID, process.env.REACT_APP_AUTH0_DOMAIN, {
      container: 'hiw-signup-container',
      auth: {
        redirectUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        responseType: 'id_token token',
        params: {
          scope: 'openid email profile user_metadata app_metadata picture'
        }
      },
      theme: {
        // logo: 'theme/assets/img/ezi-Logo.png',
        primaryColor: '#eeb14f',
      },
      additionalSignUpFields: [{
        name: 'first_name',
        placeholder: 'Enter your first name'
      },{
        name: 'last_name',
        placeholder: 'Enter your last name'
      }, {
        name: 'phone',
        placeholder: 'Enter your phone number'
      }],
      allowLogin: false,
      languageDictionary: {
        emailInputPlaceholder: 'Enter your email',
        title: 'Sign up'
      }
    });
  }

  cleanLock() {    
    this.lock = null;
  }
}

export default AuthLockService;