import React from 'react';
import { authRoutes } from '../consts/auth';
import UserTag from '../component/UserTag';
import { UserOutlined } from '@ant-design/icons';


export const oppositeColor = colorHex => {
  var color = parseInt(colorHex.replace(/^#/, ''), 16);
  var complement = 0xff - color;
  return complement;
}
// export const emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const loginUrlMaker = () => {
  return authRoutes.login + '?action=login&application=PORTAL';
};

export const signupUrlMaker = () => {
  return authRoutes.signup + '?action=signup&application=PORTAL';
};

export const logoutUrlMaker = () => {
  return authRoutes.logout + '?action=logout&application=PORTAL';
};

export const auth0ErrorTranspiler = (err) => {
  if (err.error && err.error === 'request_error') {
    return ''
  }
  if (err.message) {
    return err.message
  }
  if (err.description && typeof err.description === 'string') {
    return err.description;
  }
  if (err.policy) {
    return (<div>
      {err.policy.split('\n').map((item, key) => {
        return <React.Fragment key={key}>{item}<br /></React.Fragment>
      })}
    </div>);
  }
  return '';
};

/**
 * @param {Object} pictureItem
 * @param {string} size
 * @param {('pdf'|'preview')} pdfType
 * @param {number} pageNumber
 */
export const imageUrlMaker = (pictureItem, size = '250', pdfType, pageNumber) => {
  try {
    const page = `page${pageNumber ? pageNumber : 1}`;
    if (pictureItem['pdf']) {
      return pictureItem[pdfType ? pdfType : 'pdf'][page][size];
    } else {
      // todo what to do if is not pdf?
    }
    return pictureItem['urls'][size];
  } catch (e) {
    return false;
  }
};

export const userTagAvatarMaker = (pictureItem) => {
  const avatar = { shape: 'square' };
  const theImage = imageUrlMaker(pictureItem, '32');
  if (theImage) {
    avatar.src = theImage;
  } else {
    avatar.icon = <UserOutlined />;
  }
  return avatar;
};

export const agentsOptions = (agent) => {
  return {
    text: <UserTag key={agent.id} name={`${agent.first_name} ${agent.last_name}`} avatar={userTagAvatarMaker(agent.profile_picture_id)}/>,
    value: agent.id,
  }
};

export const convertPrice = (price) => parseInt(price) === parseFloat(price) ? parseInt(price) : price;



export const capitalizeFirstLetter = (string) =>{
  return string.charAt(0).toUpperCase() + string.slice(1);
}