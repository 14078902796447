import React, {useState, useEffect} from 'react';
import {Table} from 'antd';
import moment from 'moment';
import * as taskApi from '../../../../../services/api/task';
import AddEdit from '../AddEdit';
import './style.scss';

const priorities = [
  'not valid',
  (
    <div className="text-success">
      <span className="fas fa-arrow-down mr-1" />
      Low
    </div>
  ),
  (
    <div className="text-warning">
      <span className="fas fa-arrow-up mr-1" />
      Medium
    </div>
  ),
  (
    <div className="text-danger">
      <span className="fas fa-arrow-up mr-1" />
      High
    </div>
  )
];
const statuses = [
  'not valid',
  <React.Fragment>
    <span className="fas fa-circle text-success small mr-1" />
    Completed
  </React.Fragment>,
  <React.Fragment>
    <span className="fas fa-circle text-primary small mr-1" />
    In progress
  </React.Fragment>,
  <React.Fragment>
    <span className="fas fa-circle text-warning small mr-1" />
    On hold
  </React.Fragment>,
];

export default (props) => {
  const [tasksList, tasksListSetter] = useState([]);
  const [loadingList, loadingListSetter] = useState(true);
  const [editModal, editModalSetter] = useState(0);

  const getListData = () => {
    loadingListSetter(true);
    taskApi.getTasks(props.campaignId)
      .then(data => {
        tasksListSetter(data.data.results);
      })
      .finally(() => {
        loadingListSetter(false);
      });
  }

  const editModalOpenner = (id) => {
    editModalSetter(id);
  };

  const editModalCloser = () => {
    editModalSetter(0);
  };

  const editModalUpdate = () => {
    editModalCloser();
    getListData();
  };

  const columns = [
    {
      title: 'Agency Name',
      dataIndex: 'office_name',
      key: 'office_name',
      sorter: (a, b) => ('' + a.subject).localeCompare(b.subject)
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (text) => priorities[text],
      sorter: (a, b) =>  a.priority - b.priority
    },
    {
      title: 'Due Date',
      dataIndex: 'start_date',
      key: 'date',
      render : (text) => {
        return moment(Date.parse(text)).format('D MMM');
      },
      sorter: (a, b) =>  new Date(Date.parse(a.start_date)).getTime() - new Date(Date.parse(b.start_date)).getTime()
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => statuses[text],
      sorter: (a, b) => a.status - b.status
    }
  ];

  useEffect(getListData, []);
  return(
    <div className="tasks-list-component table-integrated">
      <Table
        rowKey='id_obj'
        dataSource={tasksList}
        columns={columns}
        loading={loadingList}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              editModalOpenner(rowIndex + 1); // plus 1 to prevent making it false to show AddEdit popup
            }, // click row
          };
        }}
      />
      {!!editModal && <AddEdit editing={true} campaignId={tasksList[editModal-1].id_obj} defaultData={tasksList[editModal-1]} onUpdate={editModalUpdate} close={editModalCloser} />}
    </div>
  )
}
