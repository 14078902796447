import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { browserRoutes } from '../../../consts/browserRoutes';
import { loginUrlMaker, signupUrlMaker } from '../../../utils';
import { LogoutOutlined } from '@ant-design/icons';
import auth0Client from 'Auth/AuthClient';
import useOutsideAlerter from './useOutsideAlerter';


const Menu = () => {
  const [profile, setProfile] = useState(null);
  const [advanceResidential, setAdvanceResidentail] = useState(false);
  const wrapperRef = useRef(null);

  const profileCallback = (error, profileData) => {
    if (error) {
      // console.log('user check on ERRR path')      
    }
    // console.log(profileData);
    setProfile(profileData);  
    if(profileData){
      const isAR = profileData.agency.campaign_type === 'advanced-residential'
      setAdvanceResidentail(isAR);
    }  
  };

  
  useEffect(() => {
    auth0Client.getProfile(profileCallback);
  }, []);


  
  const menuDefaultState = {
    menu1: false,
    menu2: false,
    menu3: false,
    menuOrders: false,
    menuSocial: false,
  };

  const [menuState, setMenuState] = useState(menuDefaultState);

  const logoutHandler = () => {
    auth0Client.signOut();    
  };

  const closeSubMenus = () => {
    setMenuState({ ...menuDefaultState });
  };
  useOutsideAlerter(wrapperRef, closeSubMenus);
  
  const changeMenuState = (menu) => {
    const newState = { ...menuDefaultState, [menu]: !menuState[menu] };
    setMenuState(newState);
  };

  const MenuItems = () => (
    <ul ref={wrapperRef} className='navbar-nav u-header__navbar-nav'>
      <li className='nav-item hs-has-sub-menu u-header__nav-item'>
        <Link to={browserRoutes.home} className='nav-link u-header__nav-link'>
          Home
        </Link>
      </li>
      {advanceResidential && (
        <li className='nav-item hs-has-sub-menu u-header__nav-item'>
          <Link to={browserRoutes.dashboard} className='nav-link u-header__nav-link'>
          Dashboard
          </Link>
        </li>
      )}
      {!advanceResidential && (
        <li className='nav-item u-header__nav-item'>
          <Link
            to={browserRoutes.campaign.list}
            className='nav-link u-header__nav-link'
          >
            Campaign
          </Link>
        </li>
      )}
      {advanceResidential && (
        <li
          onClick={() => changeMenuState('menuOrders')}
          className='nav-item hs-has-sub-menu u-header__nav-item hs-sub-menu-opened'
        >
          <div            
            style={{cursor:'pointer'}}
            className='nav-link u-header__nav-link u-header__nav-link-toggle'
          >
            Orders
          </div>
          <ul
            className='hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated slideInUp'
            style={{
              minWith: '230px',
              display: menuState['menuOrders'] ? 'block' : 'none',
            }}
          >
            <li>
              <Link
                to={browserRoutes.campaign.residentialOrders}
                className='nav-link u-header__sub-menu-nav-link'
              >
                Property
              </Link>
            </li>
            <li>
              <Link
                to={browserRoutes.campaign.agentOrders}
                className='nav-link u-header__sub-menu-nav-link'
              >
                Agent
              </Link>
            </li>
            <li>
              <Link
                to={browserRoutes.campaign.officeOrders}
                className='nav-link u-header__sub-menu-nav-link'
              >
                Office
              </Link>
            </li>
          </ul>
        </li>
      )}
      {/* <li
        onClick={() => changeMenuState('menuSocial')}
        className='nav-item hs-has-sub-menu u-header__nav-item hs-sub-menu-opened'
      >
        <Link
          to='#'
          className='nav-link u-header__nav-link u-header__nav-link-toggle'
        >
            Social
        </Link>
        <ul
          className='hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated slideInUp'
          style={{
            minWith: '230px',
            display: menuState['menuSocial'] ? 'block' : 'none',
          }}
        >
          <li>
            <Link
              to={browserRoutes.campaign.residentialOrders}
              className='nav-link u-header__sub-menu-nav-link'
            >
                Accounts
            </Link>
          </li>
          <li>
            <Link
              to={browserRoutes.social.posts}
              className='nav-link u-header__sub-menu-nav-link'
            >
                Posts
            </Link>
          </li>          
        </ul>
      </li> */}
      <li
        onClick={() => changeMenuState('menu1')}
        className='nav-item hs-has-sub-menu u-header__nav-item hs-sub-menu-opened'
      >
        <div style={{cursor:'pointer'}}
          className='nav-link u-header__nav-link u-header__nav-link-toggle'
        >
          Staff
        </div>
        <ul
          className='hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated slideInUp'
          style={{
            minWith: '230px',
            display: menuState['menu1'] ? 'block' : 'none',
          }}
        >
          <li>
            <Link
              to={browserRoutes.agency.general}
              className='nav-link u-header__sub-menu-nav-link'
            >
              Agency Detail
            </Link>
          </li>
          <li>
            <Link
              to={browserRoutes.agents.root}
              className='nav-link u-header__sub-menu-nav-link'
            >
              Staff
            </Link>
          </li>
        </ul>
      </li>
      <li
        onClick={() => changeMenuState('menu2')}
        className='nav-item hs-has-sub-menu u-header__nav-item hs-sub-menu-opened'
      >
        <div style={{cursor:'pointer'}}
          className='nav-link u-header__nav-link u-header__nav-link-toggle'
        >
          <span className='fa fa-user-circle mr-1' />
          Account
        </div>
        <ul
          className='hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated slideInUp'
          style={{
            minWith: '230px',
            display: menuState['menu2'] ? 'block' : 'none',
          }}
        >
          {profile ? (
            <React.Fragment>
              {profile && (
                <li>
                  <span
                    className={'nav-link u-header__sub-menu-nav-link text-muted'} >
                    <span className='fa fa-envelope mr-1' />
                    {
                      profile.user.email.length>16 ? profile.user.email.split('@')[0] : profile.user.email
                    }                    
                  </span>                  
                </li>
              )}
              {profile && profile.agency.office_name && (
                <li>
                  <span
                    className={
                      'nav-link u-header__sub-menu-nav-link text-muted text-left'
                    }
                    title={profile.agency.office_name}
                  >
                    <span className='fa fa-user-circle mr-1' />
                    {profile.agency.office_name}
                  </span>
                </li>
              )}
              <li onClick={logoutHandler}>
                <span className='nav-link u-header__sub-menu-nav-link'>
                  <LogoutOutlined className={'mr-1'} />
                  Logout
                </span>
              </li>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <li>
                <a
                  href={loginUrlMaker()}
                  className='nav-link u-header__sub-menu-nav-link'
                >
                  <LogoutOutlined className={'mr-1'} />
                  Login
                </a>
              </li>
              <li>
                <a
                  href={signupUrlMaker()}
                  className='nav-link u-header__sub-menu-nav-link'
                >
                  Signup
                </a>
              </li>
            </React.Fragment>
          )}
        </ul>
      </li>
    </ul>
  );
  // ));
  return <MenuItems />;
};
export default Menu;
