import React, { useState, useEffect, useCallback } from 'react';
import { message, Button, Input,Cascader } from 'antd';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import './style.scss';

const ProductCascader = (props) => {
  const [loading, loadingSetter] = useState(true);
  const [options, optionsSetter] = useState([]);
  const [selectedProduct, selectedProductSetter] = useState('');
  const [isFirstLoad, isFirstLoadSetter] = useState(true);

  const { api, apiData, onChange,  ...restProps } = props;

  const flatChilds = useCallback((childs) => {
    let children = [];
    childs.forEach((child) => {
      if (child.disabled || child.children) {
        children.push(child);
      } else {
        children.push({
          value: child.value.id_obj,
          label: child.label,
          item: child.value,
        });
      }
      if (child.children) {
        child.children = flatChilds(child.children);
      }
    });
    return children;
  });

  useEffect(() => {
    loadingSetter(true);
    selectedProductSetter('');
    const {productTypeId,advanceResidential,campaignType}    = apiData;
    api(productTypeId,advanceResidential,campaignType)
      .then((data) => {
        let values = data.data;
        // falttening children to label/value obj
        values.forEach((item) => {
          if (item.children && !item.disabled) {
            item.children = flatChilds(item.children);
            // let children = [];
            // item.children.forEach(child=>{
            //   if(child.disabled){
            //     children.push(child);
            //   }else{
            //     children.push({value:child.value.id_obj,label:child.label,item:child.value})
            //   }
            // })
            // item.children =children;
          }
        });
        optionsSetter(values);
      })
      .catch((e) => {
        console.log(e);
        message.error('Get data error');
      })
      .finally(() => {
        loadingSetter(false);
        isFirstLoadSetter(false);
      });
  }, [apiData, api]);

  const onChangeItem = (items) => {
    let last = null;
    items.forEach((item) => {
      if (last) {
        if (last.children) {
          last = last.children.find((x) => x.value === item);
        }
      } else {
        last = options.find((x) => x.value === item);
      }
    });
    onChange(last.item);
    selectedProductSetter(last.item.name);

    // onChange(item);
    // selectedProductSetter(item[item.length-1].name);
  };

  return (
    <div>
      {loading && <LoadingOutlined size={24} spin />}
      {!loading && !isFirstLoad && !options.length && (
        <div className="alert alert-warning text-center">No option found</div>
      )}
      {!loading && !!options.length && (
        <div className="row">
          <div className='col-4 col-sm-5'>
            <div className="selector-button">
              <Cascader
                dropdownClassName="product-select-cascader"
                onChange={onChangeItem}
                options={options}
                disabled={false}
                placeholder={'Please select'}
                {...restProps}
              >
                <Button size="large" style={{ width: '100%',height:'49px' }}>
                  {selectedProduct ? 'Change Product' : 'Select Product'}
                  <DownOutlined />
                </Button>
              </Cascader>
            </div>
          </div>
          
          <div className='col-8 col-sm'>
            <div className="selected-product pt-3 pt-lg-0">
              {selectedProduct && (
                <Input width={'100%'} size="large" disabled value={selectedProduct} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCascader;
