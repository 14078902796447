import axios from 'config/axios';
import { productAPIVersion, orderAPIVersion } from '../apiVersions'

export const getPackagesList = () => {
  return axios({
    method: 'get',
    url: `api/${productAPIVersion}/product/package/`,
  });
};

/**
 * ordering the package
 * @param {*} data |  {campaignId, productPackageId, campaign_type}
 * @returns Promise
 */
export const orderNewPackage = (data) => {
  return axios({
    method: 'post',
    url: `api/${orderAPIVersion}/job/newPackage/`,
    data,
  });
};

export const packageCalculatePrice = (packageId) => {
  return axios({
    method: 'get',
    url: `api/${productAPIVersion}/product/calculate-package-price/?packageId=${packageId}`,
  });
};

export const removePackage = (orderId) => {
  return axios({
    method: 'delete',
    url: `api/${orderAPIVersion}/job/${orderId}/`,
  });
};

export const cancelPackage = (orderId) => {
  return axios({
    method: 'get',
    url: `api/${orderAPIVersion}/job/cancel/${orderId}/`,
  });
};
