import React from 'react';
import {Select} from 'antd';
import Loader from 'component/common/Loader';

const {Option} = Select;

export default (props) => {
  if(props.loading) {
    return (
      <>        
        <Loader />                
      </>
    );
  }


  if(props.productTypes.length){
    return(
      <React.Fragment>
        <label className="form-label">Products</label>
        <Select size='large' defaultValue="Select" style={{width: '100%'}} onChange={props.onChange} data-testid="product-select">
          {props.productTypes.map((product, key) => {
            return (<Option key={key} value={product.id_obj}>{product.name}</Option>);
          })}
        </Select>
      </React.Fragment>
    );
  }
  return null;
}
