import axios from 'config/axios';
import { assetAPIVersion } from 'services/apiVersions';

export const  assetAPIBaseURL=`${process.env.REACT_APP_API_BASE_PATH}/api/${assetAPIVersion}/assets/`;
//export const  assetUploadUrl='http://assets.immersion.smart/assetservice/api/v3/assets/';
export const assetUploadUrl = `${process.env.REACT_APP_API_BASE_PATH}/api/${assetAPIVersion}/assets/`;

export const saveFile = ({
  action,
  data,
  file,
  filename,
  headers,
  onError,
  onProgress,
  onSuccess,
  withCredentials,
}) => {
  // EXAMPLE: post form-data with 'axios'
  const formData = new FormData();  
  data.fileName = file.name?file.name:filename;  
  if (data) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }
  formData.append(filename, file);
  axios
    .post(action, formData, {
      withCredentials,
      headers,
      onUploadProgress: ({ total, loaded }) => {
        onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
      },
    })
    .then(({ data: response }) => {      
      onSuccess(response, file);
    })
    .catch(onError);

  return {
    abort() {
      console.log('upload progress is aborted.');
    },
  };
};

export const saveFileWithPromise = ({
  action,
  data,
  file,
  filename,
  headers,
  onProgress,
  withCredentials,
}) => {
  // EXAMPLE: post form-data with 'axios'
  const formData = new FormData();  
  data.fileName = file.name?file.name:filename;  
  if (data) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }
  formData.append(filename, file);
  return axios
    .post(action, formData, {
      withCredentials,
      headers,
      onUploadProgress: ({ total, loaded }) => {
        onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
      },
    });  
};

export const saveFileHeadless = ({
  action=assetUploadUrl,
  data,
  file,
  filename,
  headers,  
  withCredentials,
}) => {
  // EXAMPLE: post form-data with 'axios'
  const formData = new FormData();  
  data.fileName = file.name?file.name:filename;  
  // formData.append('name', 'image')
  
  if (data) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }
  formData.append('file', file)
  // formData.append('file', base64toBlob(file,'pdf'))  
  return axios
    .post(action, formData, {
      withCredentials,
      headers,      
    });
};