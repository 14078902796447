import React from 'react';
import Wrapper from './style';
import { Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
const OrderItemDispatch = (props) => {
  const {
    openPreviewModal,
    propertyId,
    selectedToDispatch,
    orderStatus,
    selectToDispatch,// = () => {},
    closePreviewModal,
    PrepositionalAndInstruction,
  } = props;
  const {
    _id,
    canDispatch,
    dispatched,
    positionalImg,
    deliveryInfo,
    dispatchedAt,
    dispatchedBy,
  } = props.data;

  const clickToDispatch = () => {
    if (!dispatched || orderStatus === -1) {
      selectToDispatch();
    }
  };

  const statusText = selectedToDispatch ? 'Selected' : 'Select To Dispatch';
  const DispatchTooltip = (
    <>
      <span>Dispatch By: {dispatchedBy}</span>
      <br></br>
      <span>Dispatch At: {moment(new Date(dispatchedAt)).format('DD/MM/Y HH:mm')}</span>
    </>
  );

  const allReadyDispatchedView = () => (
    <>
      <Tooltip placement='top' title={DispatchTooltip}>
        Dispatched
      </Tooltip>
      <Tooltip
        className={'ml-2'}
        placement='topRight'
        title='Preview positional image'
        onClick={() =>
          openPreviewModal(
            <PrepositionalAndInstruction
              positionalImg={positionalImg}
              instruction={deliveryInfo.instruction}
              propertyId={propertyId}
              orderId={_id}
              close={closePreviewModal}
            />
          )
        }
      >
        {/* <Icon type={'eye'} style={{ color: '#377dff' }} /> */}
        <EyeOutlined style={{ color: '#377dff' }} />
      </Tooltip>
      {/* {positionalImg && (
        
      )} */}
    </>
  );

  const allReadyDispatched = !canDispatch && dispatched;

  return (
    <Wrapper
      className={`dispatch ${
        !canDispatch ? 'disabled' : selectedToDispatch ? 'selected' : ''
      }`}
      onClick={clickToDispatch}
    >
      {allReadyDispatched ? allReadyDispatchedView() : statusText}
    </Wrapper>
  );
};

export default OrderItemDispatch;
