import React, { useState } from 'react';
import { Form, Input, Select, DatePicker, Modal, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import TagSelect from '../../../form/TagSelect';
import UserTag from '../../../UserTag';
import * as agentApi from '../../../../services/api/agent';
import * as taskApi from '../../../../services/api/task';
import { agentsOptions, userTagAvatarMaker } from '../../../../utils';
import './style.scss';

const { Option } = Select;

const TaskForm = (props) => {
  const [form] = Form.useForm();
  const { editing, defaultData } = props;
  const [saving, savingSetter] = useState(false);

  const handleOk = (values) => {
    savingSetter(true);
    values.start_date = moment(new Date(values.date)).format('Y-MM-DD');
    values.agents = values.agents.map((item) => item.key);
    if (!editing) {
      values.campaign = props.campaignId;
    }
    if (editing) {
      taskApi
        .updateTask(defaultData.id_obj, values)
        .then(() => {
          message.success('Task Updated Successfully');
          props.onUpdate();
        })
        .catch(() => message.error('Task updated error'))
        .finally(() => {
          savingSetter(false);
        });
    } else {
      // add new task
      taskApi
        .newTask(values)
        .then(() => {
          message.success('Task created successfully');
          props.onUpdate();
        })
        .catch((e) => {
          console.log(e);
          message.error('Task Creation error');
        })
        .finally(() => {
          savingSetter(false);
        });
    }
  };

  let assignee;
  if (editing) {
    assignee = props.defaultData.assign_to.map((agent, key) => {
      return {
        label: (
          <UserTag
            key={key}
            name={agent['first_name'] + ' ' + agent['last_name']}
            avatar={userTagAvatarMaker(agent['profile_picture_id'])}
          />
        ),
        value: agent.id_auth,
        key: agent.id_auth,
      };
    });
  }

  return (
    <Modal
      className='task-add-edit'
      title={editing ? 'Edit task' : 'Add new task'}
      visible={true}
      onCancel={props.close}
      okText={
        editing ? (
          <React.Fragment>
            {saving && <LoadingOutlined className='mr-2' size={24} spin />}
            Update
          </React.Fragment>
        ) : (
          <React.Fragment>
            {saving && <LoadingOutlined className='mr-2' size={24} spin />}Add
          </React.Fragment>
        )
      }
      cancelText='Cancel'
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleOk(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} name='task-upsert-form' onFinish={handleOk}>
        <div className='form-control-integrated'>
          <div className='row'>
            <div className='col-8'>
              <label className='form-label'>Task Name</label>
              <Form.Item
                name='name'
                rules={[
                  { required: true, message: 'Please enter task`s title' },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className='col-4'>
              <label className='form-label'>Status</label>
              <Form.Item name='status' rules={[{ required: true }]}>
                <Select size='large' style={{ width: '100%' }}>
                  <Option value='1'>Completed</Option>
                  <Option value='2'>In progress</Option>
                  <Option value='3'>On Hold</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-8'>
              <label className='form-label'>Start Date</label>
              <Form.Item name='date'>
                <DatePicker style={{ width: '100%' }} size='large' />
              </Form.Item>
            </div>
            <div className='col-4'>
              <label className='form-label'>Priority</label>
              <Form.Item name='priority'>
                <Select size='large' style={{ width: '100%' }}>
                  <Option value='1'>
                    <div className='text-success'>
                      <span className='fas fa-arrow-down mr-1' />
                      Low
                    </div>
                  </Option>
                  <Option value='2'>
                    <div className='text-warning'>
                      <span className='fas fa-arrow-up mr-1' />
                      Medium
                    </div>
                  </Option>
                  <Option value='3'>
                    <div className='text-danger'>
                      <span className='fas fa-arrow-up mr-1' />
                      High
                    </div>
                  </Option>
                </Select>
              </Form.Item>
              {/* )} */}
            </div>
          </div>
          <div className='pt-3'>
            <label className='form-label'>Assign to</label>
            <Form.Item name=''>
              <TagSelect
                fieldName='agents'
                form={form}
                initialValue={editing ? assignee : {}}
                api={agentApi.searchAgent}
                options={agentsOptions}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default TaskForm;
