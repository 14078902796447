import styled from 'styled-components';

const StyledDiv = styled.div`

img.embed-responsive {
    max-height: 600px;
    max-width: 100%;
    width: auto;
    margin: 0 auto;
}
.artwork-item-container{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    overflow: auto;
    max-height: 600px;
}

`;
export default StyledDiv;


