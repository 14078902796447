import { Avatar, Empty } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './FBPreview.css'

// const halfSize = {width:'50%', height:'50%'};
const FBPreview = (props)=>{
  const {userName,pageName,message,photos,isNow,type} = props;  
  if(!userName && !pageName){
    return (
      <>
        <Empty className='mt-10' description="In order to activate preview, select account" />
      </>
    );
  }
  
  return (
    <div className="container mt-2">
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-12">
          <div className="card">
            <div className="d-flex justify-content-between p-2 px-3">
              <div className="d-flex flex-row align-items-center"> 
                {/* <img src="https://i.imgur.com/UXdKE3o.jpg" width="50" className="rounded-circle" /> */}
                <Avatar size={50} icon={<UserOutlined />} />
                <div className="d-flex flex-column ml-2"> <span className="font-weight-bold">{userName}</span> <small className="text-primary">{pageName}</small> </div>
              </div>
              <div className="d-flex flex-row mt-1 ellipsis"> <small className="mr-2">{isNow? 'Now':'Scheduled'}</small> <i className="fa fa-ellipsis-h"></i> </div>
            </div> 
            {type==='photo' && 
              <>
                {photos.length===1 && <img src={photos[0]} className="img-fluid" />}
                {photos.length===2 && 
                <>
                  <div className='row mb-2 text-center'>
                    <div className='col'>
                      <img src={photos[0]} className='post-image'/>  
                    </div>                    
                  </div>
                  <div className='row mt-2'>
                    <div className='col'>
                      <img src={photos[1]} className='post-image'/>  
                    </div>                    
                  </div>
                </>
                }
                {photos.length===3 && 
                <>
                  <div className='row mb-2 text-center'>
                    <div className='col-12'>
                      <img src={photos[0]} className='post-image'/>  
                    </div>                
                  </div>    
                  <div className='row'>
                    <div className='col-6'>
                      <img src={photos[1]} className='post-image'/>  
                    </div>                
                    <div className='col-6'>
                      <img src={photos[2]} className='post-image'/>  
                    </div>                
                  </div>         
                </>
                }
                {photos.length===4 && 
                <>
                  <div className='row mb-2 text-center'>
                    <div className='col-12'>
                      <img src={photos[0]} className='post-image'/>  
                    </div>                
                  </div>    
                  <div className='row'>
                    <div className='col-4'>
                      <img src={photos[1]} className='post-image'/>  
                    </div>                
                    <div className='col-4'>
                      <img src={photos[2]} className='post-image'/>  
                    </div>                
                    <div className='col-4'>
                      <img src={photos[3]} className='post-image' />  
                    </div>                
                  </div>         
                </>
                }
              </>
            }
            <div className="p-2">
              <p className="text-justify">{message}</p>
              <hr/>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-row icons d-flex align-items-center"> <i className="fa fa-heart"></i> <i className="fa fa-smile-o ml-2"></i> </div>
                <div className="d-flex flex-row muted-color"> <span>0 comments</span> <span className="ml-2">Share</span> </div>
              </div>
              <hr/>                 
              <div className="comments">
                {/* <div className="d-flex flex-row mb-2"> 
                  <Avatar size={40} icon={<UserOutlined />} />
                  <div className="d-flex flex-column ml-2"> <span className="name">Daniel Frozer</span> <small className="comment-text">I like this alot! thanks alot</small>
                    <div className="d-flex flex-row align-items-center status"> <small>Like</small> <small>Reply</small> <small>Translate</small> <small>18 mins</small> </div>
                  </div>
                </div> */}
                {/* <div className="d-flex flex-row mb-2"> <img src="https://i.imgur.com/1YrCKa1.jpg" width="40" className="rounded-image" />
                  <div className="d-flex flex-column ml-2"> <span className="name">Elizabeth goodmen</span> <small className="comment-text">Thanks for sharing!</small>
                    <div className="d-flex flex-row align-items-center status"> <small>Like</small> <small>Reply</small> <small>Translate</small> <small>8 mins</small> </div>
                  </div>
                </div> */}
                {/* <div className="comment-input"> <input type="text" className="form-control" />
                  <div className="fonts"> <i className="fa fa-camera"></i> </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FBPreview;
