import React from 'react';
import {Input, Upload, message,Image} from 'antd';
import PropTypes from 'prop-types';
// import {saveFile, assetUploadUrl} from '../../services/api/assets';
import {saveFile, assetUploadUrl} from 'services/api/assets';
import './style.scss'
// import {imageUrlMaker} from "../../utils";
import { imageUrlMaker } from 'utils';

class SingleFileUpload extends React.Component{
  state = {
    avatars: [],
    uploadedAvatar: ''
  };
  fileData = { fileType: this.props.fileType ? this.props.fileType : 'headshot', fileName: 'default', ...(this.props.additionalData ? this.props.additionalData : {}) };
  uploadAvatarProps = {    
    action: this.props.apiUrl ? this.props.apiUrl : assetUploadUrl,
    customRequest: saveFile,
    data: this.fileData,
    listType: 'text',
    onChange: ({ file, fileList }) => {
      fileList = fileList.slice(-1);
      this.setState({avatars: fileList, uploadedAvatar: ''});

      if (file.status !== 'uploading') {
        const {onChange, defaultImage} = this.props;
        if(file.status === 'removed'){
          this.setState({uploadedAvatar: ''});
          if (onChange) {
            onChange(defaultImage ? defaultImage : '');
          }
        }else if(file.status === 'done'){
          

          if(file.response.warning){
            message.warning(file.response.warning);
          }
          if(this.props.onDone){
            this.props.onDone(fileList[fileList.length-1]);
          }
          
          this.setState({uploadedAvatar: imageUrlMaker(fileList[fileList.length-1].response.result,400)});
          if (onChange) {
            onChange(fileList[fileList.length-1].response.result._id);
          }
        }
      }
    },
    fileList: this.state.avatars,
    accept: this.props.accept ? this.props.accept : '.jpeg,.jpg,.png,.gif',
    defaultFileList: [],
  };

  render(){
    const {defaultImage} = this.props;
    
    let avatarImage = '';
    if(defaultImage){
      avatarImage = defaultImage;
    }

    // after upload completed
    if(this.state.uploadedAvatar){
      avatarImage = this.state.uploadedAvatar;
    }

    return(
      <div className="SingleFileUpload">
        <Upload className={'avatar-upload'} {...this.uploadAvatarProps} fileList={this.state.avatars}>
          <Input size={this.props.inputSize} addonBefore={this.props.addonBefore} addonAfter={'Browse'} placeholder={this.props.placeholder ? this.props.placeholder : ''} />
        </Upload>
        {/* {avatarImage && <div className='image' style={{backgroundImage: `url(${avatarImage})`}} />} */}
        {avatarImage && <Image width={47} src={avatarImage}/>}
      </div>
    );
  }
}

SingleFileUpload.defaultProps = {
  inputSize: 'large'
};

SingleFileUpload.propTypes = {
  defaultImage: PropTypes.string
};


export default SingleFileUpload;

