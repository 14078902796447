import React from 'react';
import { Form } from 'antd';

export default (props) => {
  // const properties = props.properties ? props.properties : {};
  return (
    <React.Fragment>
      {props.label && <label className="form-label">{props.label}</label>}
      <div className="input-group">
        {props.prefix && (
          <div className="input-group-prepend">{props.prefix}</div>
        )}
        <Form.Item noStyle
          name={props.name}
          initialValue={props.initialValue}
          rules={props.rules}
        >
          <select className="custom-select form-control rounded-right">
            {Object.keys(props.data).map((key) => (
              <option key={key} value={key}>
                {props.data[key]}
              </option>
            ))}
          </select>
        </Form.Item>
      </div>
    </React.Fragment>
  );
};
// export default (props) => {
//   const properties = props.properties ? props.properties : {};
//   return (
//     <React.Fragment>
//       {props.label && <label className="form-label">{props.label}</label>}
//       <div className="input-group">
//         {props.prefix && (
//           <div className="input-group-prepend">
//             {props.prefix}
//           </div>
//         )}
//         {props.getFieldDecorator(props.fileName, properties)(
//           <select className="custom-select form-control rounded-right">
//             {Object.keys(props.data).map(key => <option key={key} value={key}>{props.data[key]}</option>)}
//           </select>
//         )}
//       </div>
//     </React.Fragment>
//   );
// }
