import React from 'react';
import {Form, Input, Modal, Select, message,Checkbox} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as galleryApi from '../../../services/api/gallery';
import './style.scss';

export class EditImage extends React.Component {
  formRef = React.createRef();

  state = {
    loading: true,
    data: {},
    visible: false,
    okText: <LoadingOutlined size={24} spin />
  };

  componentDidMount() {
    galleryApi.getSingleImage(this.props['id']).then(data => {
      this.setState({
        loading: false,
        data: data.data.result,
        okText: 'Update'
      });
    }).catch(()=> {
      message.error('Get image data error');
      this.props.onClose();
    });
  }

  updateHandler = (values) => {
    this.setState({okText: 'Saving...'});
    const formData = values;
    if(formData.tags){
      formData.tags = formData.tags.map(item => item.key).join(',');
    }
    formData.id = this.props['_id'];
    galleryApi.updateSingleImage(this.props['id'], formData).then(() => {
      this.props.onEdit();
    }).catch(()=> {
      message.error('Update image error');
      this.setState({okText: 'Update'});
    })  
  };

  render() {
    let tagsList;
    if(!this.state.loading) {
      tagsList = this.state.data.tags.map(item => ({key: item}));
    }

    let uploadedAt = '';
    if(!this.state.loading){
      uploadedAt = moment(this.state.data.uploadedAt).format('MM-DD-YYYY');
    }

    return (
      <Modal
        className='gallery-explorer-editor-component'
        title="Image editor"
        visible={true}
        onCancel={this.props.onClose}
        okText={this.state.okText}
        cancelText="Cancel"
        onOk={() => {
          this.formRef.current
            .validateFields()
            .then(values => {
              this.updateHandler(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        {this.state.loading ? (
          <LoadingOutlined size={24} spin />
        ) : (
          <div className="row">
            <div className="col-md-8 col-12 order-1 order-md-0">
              <div className="image scroller">
                <img src={this.state.data.urls.original} alt="property" />
              </div>
            </div>
            <div className="col-md-4 col-12 order-0 order-md-1 form-control-integrated">
              <Form name='edit-image' ref={this.formRef}>
                <div className="form-group pt-2">
                  <label className="form-label">Title</label>
                  <Form.Item name='fileName' initialValue={this.state.data.fileName}>
                    <Input />
                  </Form.Item>
                </div>
                <span className="text-gray-600">
                  Created Date: {uploadedAt}
                </span>
                <div className="form-group pt-2 form-control-integrated tag-select">
                  <label className="form-label">Tags</label>
                  <Form.Item name='tags' initialValue={tagsList}>
                    <Select
                      mode='tags'
                      size={'large'}
                      labelInValue
                      placeholder="tags"
                      style={{ width: '100%' }}
                    >
                    </Select>
                  </Form.Item>                  
                </div>
                <div className="form-group pt-2 form-control-integrated">
                  <Form.Item valuePropName='checked' name='shared' initialValue={this.state.data.shared}>
                    <Checkbox  >Shared</Checkbox>
                  </Form.Item>                  
                </div>
              </Form>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

export default EditImage;
