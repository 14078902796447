import ProgressCard from 'component/common/ProgressCard';
import { browserRoutes } from 'consts/browserRoutes';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createDomainUser } from 'services/api/domain';
const CallbackDomain = ()=>{
  const navigate = useNavigate();
  const location = useLocation();
  const queryStringValues = qs.parse(location.search);
  const {code,state} = queryStringValues ;  
  const [error,setError] = useState(null);

  
  useEffect(() => {
    if(code){
      const redirectUri =`${process.env.REACT_APP_BASE_PATH}${browserRoutes.callbackPublishDomain}`;
      createDomainUser(redirectUri,code).then(data=>data.data).then(data=>{
        console.log(data);
        const campaignId_proppertyId = state.split('_');
        const campaignId = campaignId_proppertyId[0];
        const propertyId = campaignId_proppertyId[1];
        const orderId = campaignId_proppertyId[2];
        navigate(browserRoutes.campaign.dashboard.residential.publishDomain(campaignId,propertyId,orderId));
      }).catch(err=>{
        console.log(err);
      })
    }else{
      setError('Invalid code from domain.comn.au')
    }
  }, [state]);

  if(error){
    return (
      <>
        <div className="container mt-4">
          {error}
        </div>
      </>
    )
  }
  return (
    <>
      <ProgressCard />
    </>
  )    
}

export default CallbackDomain;