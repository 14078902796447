import auth0Client from 'Auth/AuthClient';
import React, { useReducer } from 'react';
import { initialState, GlobalReducer, ConvertPayloadConfig } from './reducer';

const GlobalStateContext = React.createContext();
const GlobalDispatchContext = React.createContext();

export function useGlobalState() {
  const context = React.useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }

  return context;
}

export function useGlobalDispatch() {
  const context = React.useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }

  return context;
}

export const GlobalProvider = ({ children }) => {
  const storedData = auth0Client.getProfileWithoutCallback();
  const storedState = ConvertPayloadConfig(storedData);  
  const [user, dispatch] = useReducer(GlobalReducer, storedState?storedState:initialState);

  return (
    <GlobalStateContext.Provider value={user}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};
