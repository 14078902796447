import { Button } from 'antd';
import { browserRoutes } from 'consts/browserRoutes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { crmIntegration } from 'services/api/campaign';
import {notification} from 'antd';

const openNotification = (msg,type='success') => {
  notification[type]({
    message: msg,    
  });
};
const OrderActions = (props)=>{
  const navigate = useNavigate();
  const {propertyId,campaignId,orderId,domainListing,isListingType,isAuctionLive} = props;
  const [publishing,setPublishing] = useState(false);

  const onPublishDomainClicked = ()=>{
    setPublishing(true);
    navigate(browserRoutes.campaign.dashboard.residential.publishDomain(campaignId,propertyId,orderId));
    setPublishing(false);
  }
  // const onPublishAuctionClicked = ()=>{
  //   setPublishing(true);
  //   crmIntegration(campaignId).then(data=>data.data).then(data=>{
  //     openNotification('Order successfully published to the auction live.')
  //   }).catch(e=>{
  //     let errMsg = e.response.data.message;
  //     if(!errMsg){
  //       errMsg='Error occured in publishing ...';
  //     }
  //     openNotification(errMsg,'error')
  //   })
  //     .finally(()=>{
  //       setPublishing(false);
  //     })
  // }

  const onShowEnquiryClicked = ()=>{
    navigate(browserRoutes.campaign.dashboard.residential.DomainEnquiry(campaignId,propertyId,domainListing.user_id,domainListing.ad_id));
  }
  
  if(domainListing && Object.keys(domainListing).length>0){
    if(domainListing.details.processStatus==='failed'){
      return (
        <>
          <Button disabled={publishing} onClick={onPublishDomainClicked} className='base-btn-warn'>
              Failed - Republish
          </Button>
        </>
      )  
    }   
    return (
      <>        
        {domainListing.details.processStatus==='processed' && 
         <Button disabled={publishing} onClick={onShowEnquiryClicked} className='base-btn'>
         Domain Enquiries
         </Button>
        }
      </>
    )
  }

  if(isListingType){
    return (
      <>
        <Button disabled={publishing} onClick={onPublishDomainClicked} className='base-btn'>
              Publish
        </Button>
      </>
    )
  }

  // if(isAuctionLive){
  //   return (
  //     <>
  //       <Button disabled={publishing} onClick={onPublishAuctionClicked} className='base-btn'>
  //             Publish Auction
  //       </Button>
  //     </>
  //   )
  // }
  
  return null;
}

export default OrderActions;