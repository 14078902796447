import React from 'react';
import './style.scss';

function Invoice(props) {
  const { priceList: {price_breakdown, final_price} } = props;
  return (
    <div className='invoice-calculator'>
      {price_breakdown.map((item,key) => (
        <div className="item" key={key}>
          <div className="title">{item.title}</div>
          <div className="separator" />
          <div className="price">
            <div className={item.discount ? 'old' : ''}>
              $ {Number(item.price).toFixed(2)}
            </div>
            {!!item.discount && (
              <div>
                $ {Number(item.price_with_discount).toFixed(2)}
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="item total">
        <div className="title">Total</div>
        <div className="separator" />
        <div className="price">
          $ {Number(final_price).toFixed(2)}
        </div>
      </div>
    </div>
  );
}

export default Invoice;
