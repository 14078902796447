import React, { useState } from 'react';
import { Modal,message,Form,Switch,Select ,Spin} from 'antd';
import debounce from 'lodash/debounce';
import { duplicateOrder } from 'services/api/order';
import { getListApi } from 'services/api/campaign';

const DuplicateOrderModal = ({orderId,campaignId,onCancel,onSuccess,campaign_type})=>{  
  const [saving,setSaving] = useState();
  const [showCampaignSearch,setShowCampaignSearch] = useState(false);  
  const [form] = Form.useForm();
  const [data,setData] = useState([]);
  const [searching,setSearching] = useState(false);
  
  const handleSubmit =  async (values) => {
    setSaving(true);    
    let model = {
      orderId,
      campaignId,
      campaign_type
    }
    if(!showCampaignSearch){
      duplicateOrder(model).then(()=>{
        message.success('Order cloned in the current campaign')
        onSuccess();
      })
    }else{
      model.campaignId = values.campaignId;
      duplicateOrder(model).then(()=>{
        message.success('Order cloned in the destination campaign')
        onCancel();
      })
    }
        
  };

  const onChangeMode = (mode)=>{
    setShowCampaignSearch(!mode);
  }

  const fetchCampaigns = (item)=>{
    
    if(item.length<3) {
      return;
    }
    const model = {
      search: item,
      page:1,
    }
    setSearching(true);
    getListApi(model).then(data=>data.data).then(data=>{          
      setData(data.results);
    }).finally(()=>{
      setSearching(false);
    })
  }
  return (
    <>
      <Modal
        title='Duplicate Order'
        okText='Add'
        okButtonProps={{loading: saving, size: 'large'}}
        onCancel={()=>{onCancel()}}
        cancelButtonProps={{size: 'large'}}
        visible={true}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              handleSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={form} name='payment-method-form' onFinish={handleSubmit}>
          <div className='row mb-4'>
            <div className="col-12">
              <label> Duplicate in current campaign</label>
              <Switch className='ml-2' defaultChecked checkedChildren='Yes' unCheckedChildren='No' onChange={onChangeMode} />
            </div>            
          </div>
          {
            showCampaignSearch&&
              <div className="row">
                <div className="col-12">
                  <label className="form-label">Destination Campaign</label>
                  <Form.Item name='campaignId' rules={[{ required: true, message: 'Destination campaign is required' }]} >
                    <Select
                      showSearch
                      size='large'
                      placeholder='Search campaigns'
                      notFoundContent={searching ? <Spin size="small" /> : null}
                      filterOption={false}
                      onSearch={debounce(fetchCampaigns, 600)}
                      //   onChange={this.onSelect}
                      style={{ width: '100%' }}                                
                    >
                      {data.map((value) => (
                        <Option key={value.id} value={value.id}>
                          <div className='row'>
                            <div className='col-12'>
                              {value.name}
                            </div>
                            {/* <div className='col-2 text-right'>
                              <span className='mr-2'>
                              cost: ${value.cost}
                              </span>
                            </div> */}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>       
                </div>
              </div> 
          }
          
        </Form>
    
      </Modal>
    </>
  )
}

export default DuplicateOrderModal;