import React, { useState, useEffect } from 'react';
import { Select, Spin, Divider} from 'antd';
import { getSocialAccounts,getSocialPages } from '../../../services/social';
import { PlusOutlined } from '@ant-design/icons';
import SocialNetworksModal from './SocialNetworks';
import Loader from 'component/common/Loader';
const Option = Select.Option;

const SocialSelector = (props) => {
  const {onAccountChange,onPageChange} = props;
  const [fetching, setFetching] = useState(true);
  const [fetchingPages, setFetchingPages] = useState(false);
  const [options, setOptions] = useState([]);  
  const [optionsPage, setOptionsPage] = useState(null);  
  const [showModal,setShowModal] = useState(false);

  const loadData = ()=>{
    getSocialAccounts().then(data=>data.data).then(data=>{      
      const items = data.map(x=> (
        <Option key={x.user_id}>
          {x.name}
        </Option>
      ));     
      setOptions(items);
      setFetching(false);
    })    
  }

  useEffect(() => {
    loadData(); 
  }, []);

  const addAccountAction = ()=>{
    setShowModal(true);
  }

  const addAccountDone = ()=>{
    loadData();
    setShowModal(false);
  }

  const accountChanged = (item)=>{
    setOptionsPage(null);
    onAccountChange(item);
    setFetchingPages(true);
    getSocialPages(item.value).then(data=>data.data).then(data=>{
      const items = data.map(x=> (
        <Option key={x.id}>
          {x.name}
        </Option>
      ));      
      setOptionsPage(items);
      setFetchingPages(false);
    })
  }

  const pageChanged = (item) =>{
    onPageChange(item);
  }

  return (    
    <div className='row mb-4'>
      <div className='col-6'>
        <div className="">
          <label className="form-label">Account</label>
          {/* <Form.Item name='user_id' rules={props.rules}> */}
          <Select
            filterOption={false}
            showSearch={false}
            placeholder='Select Account'
            size="large"                        
            // notFoundContent={fetching ? <Spin size="small" /> : null}                    
            style={{ width: '100%' }}
            onChange={accountChanged}
            loading={fetching}
            labelInValue={true}
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider style={{ margin: '2px 0' }} />
                <div style={{ padding: '4px 8px', cursor: 'pointer' }} onMouseDown={e => e.preventDefault()} onClick={addAccountAction}>
                  <span style={{color:'#0075cc'}}>
                    <PlusOutlined /> Add Account
                  </span>
                </div>
              </div>
            )}
          >
            {options}
          </Select>
          {/* </Form.Item>   */}
        </div>
      </div>
      <div className='col-6'>
        {fetchingPages && 
            <Loader mt={6}/>
        }
        <div className="">         
          {optionsPage && 
         <>
           <label className="form-label">Pages</label>
           {/* <Form.Item name='page_id'> */}
           <Select
             filterOption={false}
             showSearch={false}
             placeholder='Select Page'
             size="large"                         
             notFoundContent={fetching ? <Spin size="small" /> : null}                    
             style={{ width: '100%' }} 
             labelInValue={true}
             onChange={pageChanged}            
           >
             {optionsPage}
           </Select>
           {/* </Form.Item>  */}
         </>} 
        </div>
      </div>      
      <SocialNetworksModal visible={showModal} setVisible={setShowModal} addAccountDone={addAccountDone}/>
    </div>
  );
};

export default SocialSelector;
