import React, {useState} from 'react';
import {Divider, message} from 'antd';
import EditableInput from '../../../../form/EditableInputs/Input';
import EditableImage from '../../../../form/EditableInputs/EditableImage';
import {imageUrlMaker} from '../../../../../utils';
import {jobEdit} from '../../../../../services/api/order';
import './style.scss';

function PrepositionalAndInstruction({positionalImg, instruction, orderId, close}) {
  const [savingImage, savingImageSetter] = useState(false);
  const [image, imageSetter] = useState(positionalImg);

  const [savingInstruction, savingInstructionSetter] = useState(false);

  const onChangeInstruction = (text) => {
    savingInstructionSetter(true);
    jobEdit({id: orderId, data: {instruction: text}}).then(() => {
      message.success('Instruction updated');      
    }).catch(() => {
      message.error('Update Error');
    }).finally(() => {
      savingInstructionSetter(false);
      close();
    });
  };

  const onChangeImage = (value) => {
    savingImageSetter(true);

    const finalImage = imageUrlMaker(value, 'original');
    jobEdit({id: orderId, data: {positionalImg: value['_id']}}).then(() => {
      imageSetter(finalImage);
      message.success('Prepositional image updated');      
    }).catch(() => {
      message.error('Update Error');
    }).finally(() => {
      savingImageSetter(false);
      close();
    });
  };

  return (
    <div className='prepositional-and-instruction'>
      <EditableImage
        defaultImage={image}
        editable={false} //according to 1645 issue
        onChange={onChangeImage}
        saving={savingImage}
      />
      <Divider className='mb-4 mt-6'>Instruction</Divider>
      {!!instruction && (
        <EditableInput
          text={instruction ? instruction : 'Set Instruction'}
          editable={false} //according to 1645 issue
          onChange={onChangeInstruction}
          saving={savingInstruction}
        />
      )}
    </div>
  );
}

export default PrepositionalAndInstruction;
