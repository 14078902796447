import React from 'react';
import { Progress } from 'antd';

const ProgressCard = ({center=true,text='Loading ...'})=>{  
  if(center){
    return (
      <div className='center-container'>
        <div className='card-sm shadow-lg' style={{width:'30rem'}}>          
          <div className="card-body">            
            <div className="text-center">                  
              <Progress
                strokeColor={{
                  from: '#108ee9',
                  to: '#87d068',
                }}                
                status="active"
                percent={100}
                showInfo={false}
              />
              {text}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='card-sm shadow-lg' style={{width:'30rem'}}>          
        <div className="card-body">            
          <div className="text-center">                  
            <Progress
              strokeColor={{
                from: '#108ee9',
                to: '#87d068',
              }}                
              status="active"
              percent={100}
              showInfo={false}
            />
            {text}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProgressCard;