import React, { useState, useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as campaignApi from '../../../../services/api/campaign';
import { CampaignConsumer, useCampaignContext } from '../CampaignContext';
import { useParams } from 'react-router-dom';
const { TextArea } = Input;

const AdCopy = (props) => {  
  const {campaignDetail,updateHeader} = useCampaignContext();
  const [loading, loadingSetter] = useState(false);
  const [saving, savingSetter] = useState(false);
  const [formdata, formdataSetter] = useState(campaignDetail);
  const {campaignId} = useParams();
  
  const submitHandler = (values) => {
    savingSetter(true);
    campaignApi
      .updateCampaign(campaignId, values).then(data=>data.data)
      .then((data) => {
        message.success('Ad copy updated successfully');        
        // update campaign context                  
        updateHeader(data);
      })
      .catch(() => {
        message.error('Ad copy update error');
      })
      .finally(() => {
        savingSetter(false);
      });
  };

  const dataKeyName = loading
    ? ''
    : `${formdata.campaign_type.replace(/-/g, '_')}`;
  const campaignCustomData = formdata[`${dataKeyName}_info`];

  return (
    <CampaignConsumer>
      {({isReadonly})  => (
        <div className="container pt-5 form-control-integrated">
          <h3 className="font-weight-medium d-inline-block mr-3">Ad Copy</h3>
          {loading ? (
            <div className="text-center">
              <LoadingOutlined type="loading" size={24} spin />
            </div>
          ) : (
            <Form name="AdCopy" onFinish={submitHandler}>
              <div className="form-group pt-2">
                <label className="form-label">Headline</label>
                <Form.Item 
                  // name={`${dataKeyName}.headline`}
                  name={[dataKeyName,'headline']}                    
                  // name={`${dataKeyName}.headline`}
                  initialValue={campaignCustomData.headline}
                >
                  <Input disabled={isReadonly}
                    addonBefore={
                      <span className="fas fa-heading text-gray-600" />
                    }
                    placeholder="Headline"
                  />
                </Form.Item>           
              </div>
              <div className="form-group pt-2">
                <label className="form-label">Listing Description</label>              
                <Form.Item
                  // name={`${dataKeyName}.copy`}
                  name={[dataKeyName,'copy']}                                    
                  // name={`${dataKeyName}.copy`}
                  initialValue={campaignCustomData.copy}>
                  <TextArea    rows={5} placeholder="Listing Description" disabled={isReadonly}/>
                </Form.Item>
              </div>
            
              <div className="text-right">
                <Button
                  className="px-5 font-weight-bold"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={isReadonly}
                  // onClick={submitHandler}
                  loading={saving}>
                Update
                </Button>
              </div>
            </Form>
          )}
        </div>
      )}
    </CampaignConsumer>
  );
};

export default AdCopy;
