import styled from 'styled-components'

export const StyledImageDownloadLinkDiv = styled.div`
    color: #377dff;
    margin: 0;
    padding: 0;
    width: 100%;
    .ant-btn-link{
        color: #377dff;
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: pointer;
    
    }

    `;

const StyledDiv = styled.div`

`;


export default StyledDiv;
