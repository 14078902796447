import axios from 'config/axios';
import { authAPIVersion } from '../apiVersions'

const staffTypes = {
  agent: 'agent',
  general: 'general'
}
export const getAgentListById = (ids) => {
  return axios({
    method: 'get',
    url: `/api/${authAPIVersion}/auth/search/staff/`,
    data: {
      agents: ids
    }
  });
};

export const getAgent = (id) => {
  return axios({
    method: 'get',
    url: `/api/${authAPIVersion}/auth/staff/${id}/?type=${staffTypes.agent}`,
  });
};

export const getUser = (id) => {
  return axios({
    method: 'get',
    url: `/api/${authAPIVersion}/auth/staff/${id}/?type=${staffTypes.general}`
  });
};

export const updateAgent = (id, data) => {
  return axios({
    method: 'patch',
    url: `/api/${authAPIVersion}/auth/staff/${id}/`,
    data: {
      ...data,
      type: staffTypes.agent
    }
  });
};

export const updateUser = (id, data) => {
  return axios({
    method: 'patch',
    url: `/api/${authAPIVersion}/auth/staff/${id}/`,
    data: {
      ...data,
      type: staffTypes.general
    }
  });
};

export const addAgent = (data) => {
  return axios({
    method: 'post',
    url: `/api/${authAPIVersion}/auth/staff/`,
    data: {
      ...data,
      type: staffTypes.agent
    }
  });
};

export const addUser = (data) => {
  return axios({
    method: 'post',
    url: `/api/${authAPIVersion}/auth/staff/`,
    data: {
      ...data,
      type: staffTypes.general
    }
  });
};

export const removeAgent = (id) => {
  return axios({
    method: 'delete',
    url: `/api/${authAPIVersion}/auth/staff/${id}/`,
    data: {
      type: staffTypes.agent
    }
  });
};

export const removeUser = (id) => {
  return axios({
    method: 'delete',
    url: `/api/${authAPIVersion}/auth/staff/${id}/`,
    data: {
      type: staffTypes.general
    }
  });
};

export const searchAgent = (name) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `/api/${authAPIVersion}/auth/search/staff/?text=${name}`,
    }).then(data => {
      resolve({ data: data.data.data });
    }).catch(e => {
      reject(e);
    });
  })
};

export const getAgents = async () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `/api/${authAPIVersion}/auth/staff/?type=${staffTypes.agent}`
    }).then((data) => {
      resolve({ data: data.data.results });
    }).catch(e => {
      reject(e);
    });
  })
};

export const getUsers = async () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `/api/${authAPIVersion}/auth/staff/?type=${staffTypes.general}`,
    }).then((data) => {
      resolve({ data: data.data.results });
    }).catch(e => {
      reject(e);
    });
  })
};
