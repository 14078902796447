import {
  ReducerAction,
  Dates,
  createDateStrType,
  StateReducerActions,
  ReducerActionPayloadForUpdateInsert,
  // ReducerActionPayloadForRemove,
  ReducerActionPayloadForAll
} from './types';


export const createDateStr  = (
  type: 'auction' | 'inspection',
  data: ReducerAction['payload']
) : string=> {
  if (!data) return '';
  const { date, start, end } = data as ReducerActionPayloadForUpdateInsert;
  const endFinal = end?.replace('to', '').replace(/ /g, '');
  const s = `${date} ${start}${endFinal ? ' to ' + endFinal : ''}`;
  return s;
};


export function reducer(draft: Dates, action: ReducerAction):ReducerActionPayloadForAll{
  const { payload, type } = action;
  let mainType: createDateStrType = '';
  mainType = type.indexOf('Auction') > -1 ? 'auction' : mainType;
  mainType = type.indexOf('Inspection') > -1 ? 'inspection' : mainType;
  let dateStr = '';
  if (mainType) {
    dateStr = createDateStr(mainType, payload);
  }

  const eachItem = (strDate: string, index: number) => {
    if (index === (payload as ReducerActionPayloadForUpdateInsert).index) {
      return dateStr;
    }
    return strDate;
  };
  switch (type) {

  case StateReducerActions.All:
    draft = payload as ReducerActionPayloadForAll;
    return draft;

  case StateReducerActions.RemoveAuction:
    draft.auction = '';
    return draft;
  case StateReducerActions.AddAuction:
    draft.auction = dateStr;
    return draft;
  case StateReducerActions.AddInspection:
    draft.inspection_times.push(dateStr);
    return draft;
  case StateReducerActions.UpdateInspection:
    draft.inspection_times = draft.inspection_times.map(eachItem) as [string];
    return draft;
  case StateReducerActions.UpdateAuction:
    draft.auction = dateStr;
    return draft;
  case StateReducerActions.RemoveInspection:
    draft.inspection_times = draft.inspection_times.filter((item, index) => index !== (payload as ReducerActionPayloadForUpdateInsert).index) as [string];
    return draft;
  default:
    throw new Error(`type ${type} not defined`);
  }
}
