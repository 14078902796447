import React, {createContext, useContext} from 'react';

const CampaignContext = createContext();

export function useCampaignContext() {
  return useContext(CampaignContext);
}
export const CampaignConsumer = CampaignContext.Consumer;

export function CampaignContextProvider({values, children}) {

  if(!values){
    values = {
      isReadonly: false,          
    }
  }
  return <CampaignContext.Provider value={values}>{children}</CampaignContext.Provider>;
}