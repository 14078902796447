import axios from 'axios';
import auth0Client from 'Auth/AuthClient';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH
});

instance.defaults.headers.common['Authorization'] = `Bearer ${auth0Client.getBearerToken()}`;
// Also add/ configure interceptors

export default instance;