import React from 'react';
import { Spin } from 'antd';

import Wrapper from './style';

function Invoice(props) {
  const {
    priceList: { price_breakdown, final_price /*, original_price, saved*/ },
    loading
  } = props;
  return (
    <Wrapper className="invoice-calculator">
      {loading ? (
        <Spin />
      ) : (
        <>
          {price_breakdown.map((item,key) => (
            <div className="item" key={key}>
              <div className="title">
                {item.product.title} -x{item.product.quantity}
              </div>
              <div className="separator" />
              <div className="price">
                <div>$ {Number(item.in_package_price_with_gst).toFixed(2)}</div>
              </div>
            </div>
          ))}
          <div className="item total">
            <div className="title">Total</div>
            <div className="separator" />
            <div className="price">$ {Number(final_price).toFixed(2)}</div>
          </div>
        </>
      )}
    </Wrapper>
  );
}

export default Invoice;
