export const countriesStates = {
  'Australia': {
    'New South Wales'       : 'NSW',
    'Queensland'            : 'QLD' ,
    'South Australia'       : 'SA',
    'Tasmania'              : 'TAS',
    'Victoria'              : 'VIC',
    'Western Australia'     : 'WA'
  }
};
