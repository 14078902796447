import React from 'react';
import {Spin} from 'antd';
import Infinite from 'react-infinite';
import {getImagesList} from '../../services/api/gallery';
import Image from './Image';

export const ListItem = (props) => (
  <div className='col-6 col-lg-3 p-1'>
    <Image {...props.item} selectedItemToggle={()=>{props.selectedItemToggle(props.item)}} />
  </div>
);


export default class List extends React.Component{
  state = {
    elements: [],
    isInfiniteLoading: false
  };

  pagetoLoad = 1;
  rowKey = 0;

  rowMaker = (data) => {
    let items = [];
    let fourItems = [];
    data.forEach(item => {
      if(fourItems.length <= 4){
        fourItems.push(<ListItem key={item['_id']} item={item} selectedItemToggle={this.props.selectedItemToggle} />);
      }
      if(fourItems.length === 4){
        items.push(fourItems);
        fourItems = [];
      }
    });

    // add leftover
    if(fourItems.length){
      items.push(fourItems);
    }
    return items.map((item) => {
      this.rowKey++;
      return(<div className="row w-100 pl-5 pr-2" key={this.rowKey}>{item}</div>);
    });
  };

  handleInfiniteLoad = () => {
    this.setState({
      isInfiniteLoading: true
    });
    
    getImagesList({
      page: this.pagetoLoad,
      limit: 16,
      type:'image,floorplan,headshot',
      ...this.props.filterData}).then(data => {
      this.pagetoLoad++;
      const elements = this.rowMaker(data.data.result);
      this.setState({
        isInfiniteLoading: false,
        elements: this.state.elements.concat(elements)
      })
    }).catch(()=> {
      this.setState({
        isInfiniteLoading: false,
      })
    });
  };
  elementInfiniteLoad = () => {
    return (
      <div className='text-center loading'>
        <Spin />
      </div>
    );
  };
  shouldComponentUpdate(nextProps) {
    const {name, tag, uploaded_at} = this.props.filterData;
    const {filterData} = nextProps;

    if(name !== filterData.name || tag !== filterData.tag || uploaded_at !== filterData.uploaded_at){
      this.pagetoLoad = 1;
      this.setState({elements: []});
    }
    return true;
  }

  render(){
    return(
      <Infinite elementHeight={108}
        containerHeight={410}
        infiniteLoadBeginEdgeOffset={200}
        onInfiniteLoad={this.handleInfiniteLoad}
        loadingSpinnerDelegate={this.elementInfiniteLoad()}
        isInfiniteLoading={this.state.isInfiniteLoading}
        className='images scroller'
      >
        {this.state.elements}
      </Infinite>
    );
  }
}
