import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PreviewPdf = (props) =>{
  const {size, data} = props;
  const pages = [];
  for(let i=1; i <= data.pdf.pageCount ; i++){
    pages.push(data.pdf[`page${i}`][size]);
  }
  return (
    <div className="preview-pdf scrollTheme">
      {pages.map((image, key) => <img key={key} style={{width: '100%'}} alt={`page ${key}`} src={image} />)}
    </div>
  );
}

PreviewPdf.propTypes = {
  size: PropTypes.number,
  data: PropTypes.any,
};

PreviewPdf.defaultProps = {
  size: 400
};
export default PreviewPdf;