import React from 'react';
import { Modal, message, Button } from 'antd';
import SingleFileUpload from 'component/form/SingleFileUpload';
import { useNavigate } from 'react-router-dom';
import { orderUpdate } from '../../../../../services/api/order';
import { browserRoutes } from '../../../../../consts/browserRoutes';
import './style.scss';
import ArtworkTypes from 'types/ArtworkTypes';

const Popup = props => {
  const navigate = useNavigate();  
  const {campaignId,orderId,campaignType,isSocial} = props;
  const onUploadDone = file => {
    orderUpdate(orderId, {
      assetId: file.response.result._id,
      artworkType: 1
    })
      .then(() => {
        navigate(
          browserRoutes.artwork.artworkProccesDone(orderId,campaignType)
        );
      })
      .catch(() => {
        message.error('Save order artwork error');
      });
  };
  return (
    <Modal
      title='Create New Artwork'
      onCancel={props.onCancel}
      className='new-artwork-popup'
      visible={true}
      footer={null}
    >
      <div className='row'>
        <div className='col-6 text-center'>
          <div className='fa fa-cloud-upload-alt icon' />
          <h2>Upload your artwork</h2>
          <div className='text'>
            Select a {isSocial?'PDF':'PDF'} and upload it to get it delivered on this order
          </div>
          <SingleFileUpload
            additionalData={{
              orderId: orderId,
              category: isSocial?'social':'campaign',
              subcategory: isSocial?'facebook':'artwork',
              campaignId:campaignId
            }}
            accept={isSocial?'.pdf':'.pdf'}
            fileType= {isSocial?'pdf':'pdf'}
            placeholder={isSocial?'Select a PDF':'Select a PDF'}
            onDone={onUploadDone}
          />
          <div className='notice'>
            1- Upload or Create Artwork
            <br />
            2- We will generate a sample
            <br />
            3- See onscreen or download
          </div>
        </div>
        {(props.type === ArtworkTypes.SelectORUploadArtwork || props.type === ArtworkTypes.SocialArtwork) 
          && (
            <div className='col-6 text-center'>
              <div className='fi flaticon-computer-monitor-back-view icon' />
              <h2>Create via editor</h2>
              <div className='text'>
              Select a template and customize it easily via online editor
              </div>
              <Button
                size='large'
                type='primary'
                className='mt-3'
                onClick={props.onSelectTemplate}
              >
              Select Template
              </Button>
            </div>
          )}
      </div>
    </Modal>
  );
};

export default Popup;
