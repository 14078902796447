import React, { useState, useEffect } from 'react';
import { Upload, message, Popconfirm, Skeleton } from 'antd';
import { LoadingOutlined,DragOutlined } from '@ant-design/icons';
import GalleryExplorer from '../../../GalleryExplorer';
import EditModal from '../../../GalleryExplorer/Edit';
import * as campaignApi from 'services/api/campaign';
import { saveFile, assetAPIBaseURL } from 'services/api/assets';
import * as propertyApi from 'services/api/property';
import './style.scss';
import { imageUrlMaker } from 'utils';
import { CampaignConsumer, useCampaignContext } from '../CampaignContext';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';

const Dragger = Upload.Dragger;

const StyledReactSortable = styled(ReactSortable)`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 4em;
`;

export const Image = (props) => (
  <>
    {props.loading?          <Skeleton.Input active={true} size='large' />:
      <div className="image-wrapper">
        {props.itemIndex && <div className="tagorder">      
          <DragOutlined />
        </div>}
        <Popconfirm
          placement="bottomRight"
          title={'Are you sure?'}
          onConfirm={props.onRemovePlanImage}
          okText="Yes"
          cancelText="No"
        >
          <div className="remove">
            <div className="fa fa-times-circle" />
          </div>
        </Popconfirm>

        <div
          className={props.dragable?'image sortable-item':'image'}
          onClick={props.editModalOpenner}
          style={{ backgroundImage: `url(${props.src})` }}
        >
          {props.title && <div className="title">{props.title}</div>}
        </div>    
        {/* {props.id}     */}
      </div>
    }
  </>
);

export default (props) => {
  const {campaignDetail,updateHeader,cacheVersion} = useCampaignContext();
  const [propertyImages, propertyImagesSetter] = useState([]);
  const [floorImages, floorImagesSetter] = useState([]);
  const [imagesLoading, imagesLoadingSetter] = useState(true);
  const [imageEditModal, imageEditModalSetter] = useState(0);
  const [showImportFromGallery, showImportFromGallerySetter] = useState(false);
  const [sortChanged, setSortChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // const [reordering,setReordering] = useState(false);
  const dataKeyName = `${campaignDetail.campaign_type.replace(/-/g, '_')}`;
  const campaignCustomData = campaignDetail[`${dataKeyName}_info`];
  useEffect(() => {
    imagesLoadingSetter(true);
    const propertySorted = campaignCustomData.property.property_images_info.sort((a,b) => a.priority - b.priority);
    propertyImagesSetter(
      propertySorted.map(
        (imagesInfo) => imagesInfo.image
      )
    );
    
    floorImagesSetter(
      campaignCustomData.property.floor_plan_id
        ? campaignCustomData.property.floor_plan_id
        : []
    );
    imagesLoadingSetter(false);
  }, [cacheVersion]);
  
  const onUpdateHeader = (newItem,type='property_images_info') =>{    
    let newItemes = [...campaignDetail[`${dataKeyName}_info`].property[type], newItem]
    campaignDetail[`${dataKeyName}_info`].property[type] = newItemes;    
    updateHeader(campaignDetail);    
  }

  const onSaveReorderingPlanImage = () => {
    if (submitting) return;
    imagesLoadingSetter(true);
    setSubmitting(true);
    // const reversedPropertyImages = propertyImages.reverse();
    // propertyImagesSetter(reversedPropertyImages);
    const imagesObject = propertyImages.map((image, index) => ({
      image_id: image['_id'],
      priority: ++index,
    }));
    campaignApi
      // .updateCampaign(props.match.params.propertyId, { //todo sp
      .updateCampaign(campaignDetail.id, {
        [dataKeyName]: { property: { property_images: imagesObject } },
      })
      .then(data=>data.data).then((data) => {
        message.success('Plan photos reordered successfully.');
        setSortChanged(false);

        // update campaign context
        updateHeader(data);
      })
      .catch((e) => {message.error(e)})
      .finally(() => {
        setSubmitting(false);
        imagesLoadingSetter(false);
      });
  };

  const onPropertyImageChange = ({ file }) => {    
    if (file.status === 'done') {
      let maxPriority = propertyImages.length + 1;
      propertyApi
        .propertyImageAdd(
          [{ image_id: file.response.result._id, priority: maxPriority }],
          campaignCustomData.property.id_obj
        )
        .then(() => {
          const uploadedFile = {
            urls: {
              250: file.response.result.urls['250'],
            },
            _id: file.response.result['_id'],
          };
          propertyImagesSetter(propertyImages.concat([uploadedFile]));
          onUpdateHeader({image:uploadedFile},'property_images_info');
        })
        .catch(() => {
          message.error('Add Image to campaign error');
        });
    }
    if (file.status === 'error') {
      console.log(file);
    }
  };

  const propertyImagesProps = {
    action: assetAPIBaseURL,
    customRequest: saveFile,
    data: {
      category: 'campaign',
      subcategory: 'photo',
      fileType: 'image',
      fileName: campaignDetail.name,
      campaignId: campaignDetail.id,
    },
    onChange: onPropertyImageChange,
    defaultFileList: [],
    listType: 'picture',
    className: 'upload-list-inline',
    accept: '.jpg,.png,.gif',
    multiple: true,
  };

  const onfloorImagesPropsChange = ({ file }) => {
    if (file.status === 'done') {
      campaignApi
        // .updateCampaign(props.match.params.propertyId, { //todo sp check correctness
        .updateCampaign(campaignDetail.id, {
          [dataKeyName]: {
            property: {
              floor_plan_id: [
                ...floorImages.map((item) => item['_id']),
                file.response.result._id,
              ],
            },
          },
        })
        .then(() => {
          const uploadedFile = {
            urls: {
              250: file.response.result.urls['250'],
            },
            _id: file.response.result['_id'],
          };
          floorImagesSetter((prevData) => [...prevData, uploadedFile]); // view image list
          onUpdateHeader(uploadedFile,'floor_plan_id');
        })
        .catch(() => {
          message.error('Add Image to campaign error');
        });
    }
  };

  const floorImagesProps = {
    action: assetAPIBaseURL,
    customRequest: saveFile,
    data: {
      category: 'campaign',
      subcategory: 'floorplan',
      fileType: 'floorplan',
      fileName: '',
      // campaignId: props.match.params.propertyId, //todo sp
      campaignId: campaignDetail.id,
    },
    onChange: onfloorImagesPropsChange,
    defaultFileList: [],
    listType: 'picture',
    className: 'upload-list-inline',
    accept: '.jpg,.png,.gif',
  };

  const onImport = (list) => {    
    let lastPriority = propertyImages.length;
    const imagesList = list.map((img) => ({
      image_id: img._id,
      priority: ++lastPriority,
    }));    
    propertyApi
      .propertyImageAdd(imagesList, campaignCustomData.property.id_obj)
      .then(() => {
        // update campaign context
        const importedList = list.map((img) => ({
          image: {
            _id: img._id,
            urls: img.urls,
            fileName: img.fileName
          },
          priority: ++lastPriority,
        }));    
        let newItemes = [...campaignDetail[`${dataKeyName}_info`].property['property_images_info'], ...importedList]
        campaignDetail[`${dataKeyName}_info`].property['property_images_info'] = newItemes;   
        updateHeader(campaignDetail);    
      })
      .catch(() => {
        message.error('update images list error');
      });
  };

  const onEditModalClose = () => {
    imageEditModalSetter(0);
  };

  const onEdit = () => {
    onEditModalClose();
    imagesLoadingSetter(true);
    // campaignApi.getCampaign(props.match.params.propertyId).then((data) => { //todo sp
    campaignApi.getCampaign(campaignDetail.id).then((data) => {
      const campaignCustomData = data.data[`${dataKeyName}_info`];
      propertyImagesSetter(campaignCustomData.property.property_images_info);
      floorImagesSetter(
        campaignCustomData.property.floor_plan_id
          ? campaignCustomData.property.floor_plan_id
          : []
      );
      imagesLoadingSetter(false);
    });
  };

  const onRemovePlanImage = (item) => {
    item.loading = true;
    const images = propertyImages.filter((image) => image['_id'] !== item['_id']);
    const imagesObject = images.map((image, index) => ({
      image_id: image['_id'],
      priority: ++index,
    }));
    campaignApi
      // .updateCampaign(props.match.params.propertyId, {
      .updateCampaign(campaignDetail.id, {
        [dataKeyName]: { property: { property_images: imagesObject } },
      }).then(data=>data.data).then((data) => {
        propertyImagesSetter(images);

        // update campaign context
        updateHeader(data);
      })
      .catch((e) => {message.error(e)});
  };
  

  const onRemoveFloorImage = (id) => {
    const newFloorPlanList = {
      [dataKeyName]: {
        property: {
          floor_plan_id: floorImages
            .filter((item) => item['_id'] !== id)
            .map((item) => item['_id']),
        },
      },
    };
    // campaignApi.updateCampaign(props.match.params.propertyId, newFloorPlanList) //todo sp
    campaignApi.updateCampaign(campaignDetail.id, newFloorPlanList)
      .then(data=>data.data).then((data) => {
        floorImagesSetter((prevData) =>
          prevData.filter((item) => item['_id'] !== id)
        );
        // update campaign context
        updateHeader(data);
      })
      .catch((e) => {message.error(e)});
  };

  return (
    <CampaignConsumer>
      {({ isReadonly }) => (
        <div className="container dashboard-photo-plans">
          <div className="head d-sm-flex pt-3 pt-sm-5">
            <h3 className="title pb-1 pb-sm-4 mb-0">Photos</h3>
            <div className="flex-grow-1 text-sm-right mb-1 mb-sm-0">
              {showImportFromGallery && (
                <GalleryExplorer
                  onImport={onImport}
                  onClose={() => showImportFromGallerySetter(false)}
                />
              )}
              {sortChanged && (
                <span
                  onClick={() => onSaveReorderingPlanImage()}
                  className="mr-2 import d-inline-block btn-pill py-sm-2 py-1 px-3 btn-primary"
                >
                  {submitting ? 'Saving Reordering' : 'Save Reordering'}
                </span>
              )}
              {isReadonly !== true && (
                <span
                  onClick={() => showImportFromGallerySetter(true)}
                  className="import d-inline-block btn-pill py-sm-2 py-1 px-3 btn-secondary"
                >
                    Import from gallery
                </span>
              )}
            </div>
          </div>
          {imagesLoading ? (
            <LoadingOutlined className="text-center w-100" size={24} spin />
          ) : (
            <React.Fragment>
              {isReadonly !== true && (
                <Dragger
                  {...propertyImagesProps}
                  className="upload-images upload-photos"
                >
                  <div className="upload-icon pt-3">
                    <span className="fa fa-cloud-upload-alt" />
                  </div>
                  <strong>Browse your device and upload images</strong>
                  <small className="d-block text-muted pb-5 pt-2">
                  Minimum Recommended File Size 300ppi (Approx. 3MB)
                    <br></br>
                  Maximum file Size 10MB 
                  </small>
                </Dragger>
              )}
              <div className="row images p-2 p-md-1 p-lg-0 mt-sm-3 mt-1">
                <StyledReactSortable                  
                  list={propertyImages}
                  setList={propertyImagesSetter}
                  animation={150}
                  onSort={()=>{setSortChanged(true)}}
                >
                  {propertyImages.map((item, key) => (
                    <Image
                      id={`${item._id}`}
                      dragable={true}
                      key={key}
                      itemIndex={++key}
                      loading = {item.loading}
                      src={imageUrlMaker(item, '250')}
                      title={item?.fileName}
                      onRemovePlanImage={() => onRemovePlanImage(item)}
                      editModalOpenner={() =>
                        imageEditModalSetter(item['_id'])
                      }
                    />
                  ))}
                </StyledReactSortable>
              </div>
            </React.Fragment>
          )}
          {!!imageEditModal && (
            <EditModal
              id={imageEditModal}
              onEdit={onEdit}
              onClose={onEditModalClose}
            />
          )}

          <div className="head pt-3 pt-sm-5">
            <h3 className="title pb-1 pb-sm-4 mb-0">Floor Plans</h3>
          </div>
          {imagesLoading ? (
            <LoadingOutlined className="text-center w-100" size={24} spin />
          ) : (
            <React.Fragment>
              {isReadonly !== true && (
                <Dragger
                  {...floorImagesProps}
                  className="upload-images upload-plans"
                >
                  <div className="upload-icon pt-sm-3 pt-0">
                    <span className="fa fa-cloud-upload-alt" />
                  </div>
                  <strong>Browse your device and upload images</strong>
                  <small className="d-block text-muted pb-md-5 pb-2 pt-2">
                  Minimum Recommended File Size 300ppi (Approx. 3MB)
                    <br></br>
                  Maximum file Size 10MB 
                  </small>
                </Dragger>
              )}
              <div className="row images p-2 p-md-1 p-lg-0 mt-sm-3 mt-1">
                {floorImages &&
                    floorImages.map((item, key) => (
                      <div
                        key={key}
                        className="col-6 col-md-4 col-lg-3 p-lg-3 p-md-2 p-1"
                      >
                        <Image
                          // id={item._id}
                          dragable={false}
                          src={imageUrlMaker(item, '250')}
                          title={item?.fileName}
                          onRemovePlanImage={() =>
                            onRemoveFloorImage(item['_id'])
                          }
                          editModalOpenner={() =>
                            imageEditModalSetter(item['_id'])
                          }
                        />
                      </div>
                    ))}
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </CampaignConsumer>
  );
};
