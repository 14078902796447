import { browserRoutes } from '../../../consts/browserRoutes';

export const RouteHelperCampaignList = (isAdvanceResidential,type)=>{
  if(!isAdvanceResidential)
    return browserRoutes.campaign.list;
  switch(type){
  case 'residential-real-estate' : return browserRoutes.campaign.residentialOrders;        
  case 'project-based' : return browserRoutes.campaign.agentOrders;        
  case 'order-based' : return browserRoutes.campaign.officeOrders;        
  default: console.error(`route helper type not found ${type}`)
  }
}

export const RouteHelperCreateOrder = (isAdvanceResidential,type,propertyId)=>{
  if(!isAdvanceResidential)
    return browserRoutes.campaign.dashboard.residential.orderNew(propertyId);
  switch(type){
  case 'residential-real-estate' : return browserRoutes.campaign.dashboard.residential.orderNew(propertyId);        
  case 'project-based' : return browserRoutes.campaign.dashboard.projectBased.orderNew(propertyId);                
  case 'order-based' : return browserRoutes.campaign.create.orderBased;        
  default: console.error(`route helper type not found ${type}`)
  }
}

export const RouteHelperNewSocialPost = (type,propertyId,orderId)=>{  
  switch(type){
  case 'residential-real-estate' : return browserRoutes.campaign.dashboard.residential.socialPost(propertyId,orderId);        
  case 'project-based' : return browserRoutes.campaign.dashboard.projectBased.socialPost(propertyId,orderId);                
  // case 'order-based' : return browserRoutes.campaign.create.orderBased;        
  default: console.error(`route helper type not found ${type}`)
  }
}

export const RouteHelperCollect = (type,camapignId,orderId)=>{  
  switch(type){
  case 'residential-real-estate' : return browserRoutes.campaign.dashboard.residential.collection(camapignId,orderId);        
  case 'project-based' : return browserRoutes.campaign.dashboard.projectBased.collection(camapignId,orderId);                
  // case 'order-based' : return browserRoutes.campaign.create.orderBased;        
  default: console.error(`route helper type not found ${type}`)
  }
}

export const RouteHelperMaintance = (type,camapignId,orderId)=>{  
  switch(type){
  case 'residential-real-estate' : return browserRoutes.campaign.dashboard.residential.maintenance(camapignId,orderId);        
  case 'project-based' : return browserRoutes.campaign.dashboard.projectBased.maintenance(camapignId,orderId);                
  // case 'order-based' : return browserRoutes.campaign.create.orderBased;        
  default: console.error(`route helper type not found ${type}`)
  }
}

export const RouteHelperOrderTab= (isAdvanceResidential,type,propertyId)=>{
  if(!isAdvanceResidential)
    return browserRoutes.campaign.dashboard.residential.order(propertyId);
  switch(type){
  case 'residential-real-estate' : return browserRoutes.campaign.dashboard.residential.order(propertyId);        
  case 'project-based' : return browserRoutes.campaign.dashboard.projectBased.order(propertyId);                
  case 'order-based' : return browserRoutes.campaign.officeOrders;       
  default: console.error(`route helper type not found ${type}`)
  }
}
