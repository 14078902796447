import axios from 'config/axios';
import { productAPIVersion, orderAPIVersion } from '../apiVersions'

export const getServices = (advanceResidential, campaign_type) => {
  let params = null;
  if (advanceResidential) {
    params = { campaign_type };
  }

  return axios({
    method: 'get',
    url: `/api/${productAPIVersion}/product/service/`,
    params,
  });
};

export const getProductTypes = (
  service_id,
  advanceResidential,
  campaign_type
) => {
  let params = { service_id };
  if (advanceResidential) {
    params = { campaign_type, service_id };
  }
  return axios({
    method: 'get',
    url: `/api/${productAPIVersion}/product/product-type/`,
    params,
  });
};

export const getProducts = (
  productTypeId,
  advanceResidential,
  campaign_type,
  single_product_type=false,

) => {
  let params = { 'product-type': productTypeId };
  if (advanceResidential) {
    params = { campaign_type, ...params };
  }
  if(single_product_type){
    params = { single_product_type, ...params };
  }
  return axios({
    method: 'get',
    url: `/api/${productAPIVersion}/product/product/`,
    params,
  });
};

export const addToFavorite = (id) => {
  return axios({
    method: 'get',
    url: 'http://www.mocky.io/v2/5d21be402f0000351dc4631e',
    params: {
      id,
    },
  });
};

export const getOrder = (id) => {
  return axios({
    method: 'get',
    url: `/api/${orderAPIVersion}/job/${id}/`,
  });
};

export const newOrder = (data) => {
  return axios({
    method: 'post',
    url: `/api/${orderAPIVersion}/job/`,
    data,
  });
};
export const newOrderBasedCampaign = (data) => {
  return axios({
    method: 'post',
    url: `/api/${orderAPIVersion}/job/`,
    data,
  });
};
export const getOfficeOrdersList = (filter) => {
  filter.campaignType = 'order-based';
  return axios({
    url: `/api/${orderAPIVersion}/job/`,
    method: 'get',
    params: filter,
  });
};
export const getOrdersById = (ids, campaignId) => {
  const campaignIdQuery = campaignId ? `&campaignId=${campaignId}` : '';

  return axios({
    method: 'get',
    url: `/api/${orderAPIVersion}/job/?orderId=${ids.join(',')}${campaignIdQuery}`,
  });
};

export const orderList = async (args) => {
  let params;
  if (typeof args === 'object') {
    const { campaignId, page = 1 } = args;
    params = { campaignId, page };
  } else {
    params = { campaignId: args, page: 1 };
  }

  return axios({
    method: 'get',
    url: `/api/${orderAPIVersion}/job/`,
    params: params,
  });
};

export const orderRemoveAPI = (id) => {
  return axios({
    method: 'delete',
    url: `/api/${orderAPIVersion}/job/${id}/`,
  });
};

export const orderCancel = (id) => {
  return axios({
    method: 'get',
    url: `/api/${orderAPIVersion}/job/cancel/${id}/`,
  });
};

export const orderUpdate = (id, params) => {
  return axios({
    method: 'patch',
    url: `/api/${orderAPIVersion}/job/${id}/`,
    data: params,
  });
};

export const orderUpdatePro = (id, data) => {
  return axios({
    method: 'patch',
    url: `/api/${orderAPIVersion}/job/${id}/`,
    data: data,
  });
};

export const jobEdit = ({ id, data }) => {
  return axios({
    method: 'post',
    url: `/api/${orderAPIVersion}/job/edit`,
    data: {
      jobId: id,
      edit: data,
    },
  });
};

export const orderDispatch = (dara, params) => {
  // console.log(dara)
  // console.log(params)
  return axios({
    method: 'post',
    url: `/api/${orderAPIVersion}/job/dispatch/`,
    data: dara,
    params,
  });
};

export const collectionMaintenanceRequest = (data) => {
  return axios({
    method: 'post',
    url: `api/${orderAPIVersion}/job/services/`,
    data,
  });
};
export const duplicateOrder = (data) => {
  return axios({
    method: 'post',
    url: `api/${orderAPIVersion}/job/copyJob/`,
    data,
  });
};
