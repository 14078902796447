import { useEffect, useState } from 'react';
import { DatePicker, Form, Input, Checkbox, message,Button } from 'antd';
import Select from '../../../form/Select';
import { useCampaignContext } from '../CampaignContext';
import moment from 'moment';
import { propertyUpdate,propertyGet } from 'services/api/property';
import ProgressCard from 'component/common/ProgressCard';
import { useParams } from 'react-router-dom';
const NumberSequanceEnum = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
};

const EditProperty = (props) => {
  const [form] = Form.useForm();
  const { reloadHeader } = useCampaignContext();
  const [data, setData] = useState({});
  const [saving,setSaving]=useState(false);
  const [loading,setLoading]=useState(true);
  const {propertyId} = useParams();
  useEffect(() => {
    propertyGet(propertyId).then(data=>data.data).then(data=>{
      setData(data);
      setLoading(false);
    })
  }, []);

  const updateHandler = (values) => {
    // values[dataKeyName].start_date = moment(new Date(values[dataKeyName].start_date)).format('Y-MM-DD');
    setSaving(true);
    propertyUpdate(propertyId, values)
      .then(() => {        
        reloadHeader();
        message.success('Property Successfully updated');
      })
      .catch((e) => {
        console.log(e);
        message.error('Edit property error');
      }).finally(()=>{setSaving(false)});
  };

  if(loading){
    return (
      <ProgressCard />
    )
  }

  return (
    <>
      <div className="container pt-5 form-control-integrated">
        <h3 className="font-weight-medium d-inline-block mr-3">
          Property Edit
        </h3>
        <Form form={form} name="editCampaignPopup" onFinish={updateHandler}>
          <div className="row">
            <div className="col-12 col-md-6">
              <Select
                name="bedroom"
                initialValue={data.bedroom}
                size="large"
                prefix={
                  <span id="calendarFromLabel" className="input-group-text">
                    <span className="fas fa-bed" />
                  </span>
                }
                label="Bedroom"
                data={NumberSequanceEnum}
              />
            </div>
            <div className="col-12 col-md-6 pt-2 pt-lg-0">
              <Select
                name="bathroom"
                initialValue={data.bathroom}
                size="large"
                prefix={
                  <span id="calendarFromLabel" className="input-group-text">
                    <span className="fas fa-shower" />
                  </span>
                }
                label="Bathroom"
                data={NumberSequanceEnum}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pt-2 pt-md-0 mt-2">
              <Select
                name="car_park"
                initialValue={data.car_park}
                size="large"
                prefix={
                  <span id="calendarFromLabel" className="input-group-text">
                    <span className="fas fa-parking" />
                  </span>
                }
                label="Car Park"
                data={NumberSequanceEnum}
              />
            </div>
            <div className="col-12 col-md-6 pt-2 pt-lg-0 mt-2">
              <label className="form-label">Square Meters</label>
              <Form.Item name="square_feet" initialValue={data.square_feet}>
                <Input
                  size="large"
                  addonBefore={<span className="fa fa-tape" />}
                  placeholder="e.g. 2400"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Start Date</label>
              <Form.Item
                name="start_date"
                initialValue={data.startDate?moment(data.startDate):null}
              >
                <DatePicker style={{ width: '100%' }} size="large" />
              </Form.Item>
            </div>
            <div className="col-3 pt-2 pt-lg-0 mt-2">
              <label className="form-label">Final Status</label>
              <Form.Item name="final_status" initialValue={data.final_status}>
                <Input size="large" placeholder="e.g. Sold" />
              </Form.Item>
            </div>
            <div className="col-3 pt-2 pt-lg-0 mt-2">
              <label className="form-label">Municipality</label>
              <Form.Item name="municipality" initialValue={data.municipality}>
                <Input size="large" />
              </Form.Item>
            </div>
            <div className="col-3 pt-2 pt-lg-0 mt-2">
              <label className="form-label">Category</label>
              <Form.Item name="category" initialValue={data.category}>
                <Input size="large" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-3 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="under_offer"
                initialValue={data.under_offer}
              >
                <Checkbox>Under Offer</Checkbox>
              </Form.Item>
            </div>
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="new_construction"
                initialValue={data.new_construction}
              >
                <Checkbox>New Construction</Checkbox>
              </Form.Item>
            </div>
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="is_sold"
                initialValue={data.is_sold}
              >
                <Checkbox>Is Sold</Checkbox>
              </Form.Item>
            </div>
            <div className="col-2 pt-2 pt-lg-0 mt-2">              
              <Form.Item
                valuePropName="checked"
                name="is_rental"
                initialValue={data.is_rental}
              >
                <Checkbox>Is Rental</Checkbox>
              </Form.Item>
            </div>
            <div className="col-3 pt-2 pt-lg-0 mt-2">              
              <Form.Item
                valuePropName="checked"
                name="newly_built"
                initialValue={data.newly_built}
              >
                <Checkbox>Newly built</Checkbox>
              </Form.Item>
            </div>
          </div>

          <div className="row">          
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Price</label>
              <Form.Item name="price" initialValue={data.price}>
                <Input type='number' size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Price View</label>
              <Form.Item name="price_view" initialValue={data.price_view}>
                <Input type='number' size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Rent</label>
              <Form.Item name="rent" initialValue={data.rent}>
                <Input type='number' size="rent"  />
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Sold Price</label>
              <Form.Item name="sold_price" initialValue={data.sold_price}>
                <Input type='number' size="rent"  />
              </Form.Item>
            </div>
          </div>

          <div className="row">          
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Ensuite</label>
              <Form.Item name="ensuite" initialValue={data.ensuite}>
                <Input type='number' size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Carports</label>
              <Form.Item name="carports" initialValue={data.carports}>
                <Input type='number' size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Air conditioning</label>
              <Form.Item name="air_conditioning" initialValue={data.air_conditioning}>
                <Input type='number' size="large"/>
              </Form.Item>
            </div>
          </div>


          <div className="row">
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="remote_garage"
                initialValue={data.remote_garage}
              >
                <Checkbox>Remote garage</Checkbox>
              </Form.Item>
            </div>         
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="secure_parking"
                initialValue={data.secure_parking}
              >
                <Checkbox>Secure parking</Checkbox>
              </Form.Item>
            </div>         
            {/* <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="air_conditioning"
                initialValue={data.air_conditioning}
              >
                <Checkbox>Air conditioning</Checkbox>
              </Form.Item>
            </div>          */}
               
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="vacuum_system"
                initialValue={data.vacuum_system}
              >
                <Checkbox>Vacuum system</Checkbox>
              </Form.Item>
            </div>         
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="intercom"
                initialValue={data.intercom}
              >
                <Checkbox>Intercom</Checkbox>
              </Form.Item>
            </div>                                      
          </div>


          <div className='row'>
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="has_pool"
                className='mt-5'
                initialValue={data.has_pool}
              >
                <Checkbox>Has Pool</Checkbox>
              </Form.Item>
            </div>    
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Pool type</label>
              <Form.Item name="pool_type" initialValue={data.pool_type}>
                <Input  size="large"/>
              </Form.Item>
            </div>     
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                className='mt-5'
                valuePropName="checked"
                name="has_spa"
                initialValue={data.has_spa}
              >
                <Checkbox>Has spa</Checkbox>
              </Form.Item>
            </div>    
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Spa type</label>
              <Form.Item name="spa_type" initialValue={data.spa_type}>
                <Input  size="large"/>
              </Form.Item>
            </div>          
          </div>


          <div className='row'>
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="tennis_court"
                initialValue={data.tennis_court}
              >
                <Checkbox>Tennis court</Checkbox>
              </Form.Item>
            </div>    
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="balcony"
                initialValue={data.balcony}
              >
                <Checkbox>Balcony</Checkbox>
              </Form.Item>
            </div>    
            <div className="col-1 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="deck"
                initialValue={data.deck}
              >
                <Checkbox>Deck</Checkbox>
              </Form.Item>
            </div>    
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="courtyard"
                initialValue={data.courtyard}
              >
                <Checkbox>Court yard</Checkbox>
              </Form.Item>
            </div>    
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="outdoor_ent"
                initialValue={data.outdoor_ent}
              >
                <Checkbox>Outdoor Ent</Checkbox>
              </Form.Item>
            </div>    
            <div className="col-1 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="shed"
                initialValue={data.shed}
              >
                <Checkbox>Shed</Checkbox>
              </Form.Item>
            </div>    
            <div className="col-2 pt-2 pt-lg-0 mt-2">
              <Form.Item
                valuePropName="checked"
                name="fully_fenced"
                initialValue={data.fully_fenced}
              >
                <Checkbox>Fully Fenced</Checkbox>
              </Form.Item>
            </div>    
          </div>

          <div className="row">          
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Open fire place</label>
              <Form.Item name="open_fire_place" initialValue={data.open_fire_place}>
                <Input  size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Heating</label>
              <Form.Item name="heating" initialValue={data.heating}>
                <Input  size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Hot water service</label>
              <Form.Item name="hot_water_service" initialValue={data.hot_water_service}>
                <Input  size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Other features</label>
              <Form.Item name="other_features" initialValue={data.other_features}>
                <Input  size="large"/>
              </Form.Item>
            </div>
          </div>


          <div className="row">          
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Land area</label>
              <Form.Item name="land_area" initialValue={data.land_area}>
                <Input type='number' size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Land unit</label>
              <Form.Item name="land_unit" initialValue={data.land_unit}>
                <Input  size="large"/>
              </Form.Item>
            </div>

            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Frontage area</label>
              <Form.Item name="frontage_area" initialValue={data.frontage_area}>
                <Input type='number' size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Frontage unit</label>
              <Form.Item name="frontage_unit" initialValue={data.frontage_unit}>
                <Input  size="large"/>
              </Form.Item>
            </div>

          </div>
          <div className="row">          
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Depth area</label>
              <Form.Item name="depth_area" initialValue={data.depth_area}>
                <Input type='depth_area' size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Depth unit</label>
              <Form.Item name="depth_unit" initialValue={data.depth_unit}>
                <Input  size="large"/>
              </Form.Item>
            </div>

            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Depth side</label>
              <Form.Item name="depth_side" initialValue={data.depth_side}>
                <Input  size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Cross over</label>
              <Form.Item name="cross_over" initialValue={data.cross_over}>
                <Input  size="large"/>
              </Form.Item>
            </div>
          </div>

          <div className="row">          
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Building area</label>
              <Form.Item name="building_area" initialValue={data.building_area}>
                <Input type='number' size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Building unit</label>
              <Form.Item name="building_unit" initialValue={data.building_unit}>
                <Input  size="large"/>
              </Form.Item>
            </div>
            <div className="col-3  pt-2 pt-lg-0 mt-2">
              <label className="form-label">Energy rating</label>
              <Form.Item name="energy_rating" initialValue={data.energy_rating}>
                <Input type='number' size="large" min={0} max={10}/>
              </Form.Item>
            </div>
            <div className="col-3 pt-2 pt-lg-0 mt-2">
              <label className="form-label">Alarm system</label>
              <Form.Item                
                name="alarm_system"
                initialValue={data.alarm_system}
              >
                <Input type='number' size="large"/>
              </Form.Item>
            </div>   
          </div>

        
          <div className="text-right">
            
            <Button
              className="px-5 font-weight-bold"
              type="primary"
              htmlType="submit"
              size="large"                  
              disabled={saving}>
              {saving?'Saving ...': 'Update'}
            </Button>
          </div>
          
        </Form>
      </div>
    </>
  );
};
export default EditProperty;
