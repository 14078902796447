import React from 'react';
import WithFetchData from './WithFetchData';
import WithStaticData from './WithStaticData';
const ProccesDoneArtworkDetails = props => {
  const { 
    asetPreviewData /* this prop determine the static data and make component to decide use static or fetch */
  } = props;
  // console.log(asetPreviewData)
  return asetPreviewData ? (
    <WithStaticData {...props} />
  ) : (
    <WithFetchData {...props} />
  );
};

export default ProccesDoneArtworkDetails;
