import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import { browserRoutes as userBrowserRoutes } from './consts/browserRoutes';
import LoginPage from './component/Auth/Login';
import CallbackPage from './component/Auth/Callback';
import CallbackDomain from './component/CallbackDomain';

//components
import Template from './component/Template';
import LandingRoute from './container/LandingRoute';
import LogoutCallback from './component/User/Logout';
import ProgressCard from 'component/common/ProgressCard';
import ProtectedRoute from 'container/ProtectedRoute';
import { useGlobalState } from 'Context';

const Home = lazy(() => import('./component/Home'));
const CampaignDashboardMain = lazy(() =>  import('./component/propertyDashboard'));
const CampaignDashboardResidentialBased = lazy(() =>  import('./component/propertyDashboard/Residential'));
const CampaignDashboardProjectBased = lazy(() =>  import('./component/propertyDashboard/ProjectBased'));
const CampaignDashboardOrderBased = lazy(() =>  import('./component/propertyDashboard/Common/Order'));
// --- Residential section ---
import  CampaignDashboardResidentialBasedOrders from './component/propertyDashboard/Common/Order'
import CampaignDashboardResidentialBasedNewOrders from './component/propertyDashboard/Common/Order/NewOrder'
import CampaignDashboardResidentialBasedPhotoAndPlans from './component/propertyDashboard/Common/PhotoAndPlans'
import CampaignDashboardResidentialBasedAgents from './component/propertyDashboard/Common/Agents'
import CampaignDashboardResidentialBasedAdCopy from './component/propertyDashboard/Common/AdCopy'
import CampaignDashboardResidentialBasedInspection from './component/propertyDashboard/Common/InspectionAuction'
import CampaignDashboardResidentialBasedRates from './component/propertyDashboard/Common/Rates';
import CampaignDashboardResidentialBasedCollectionMaintance from './component/propertyDashboard/Common/Order/CollectionMaintenance';
import CampaignDashboardResidentialBasedTasks from './component/propertyDashboard/Common/Tasks';
import CampaignDashboardResidentialBasedQR from './component/propertyDashboard/Common/QR';
import CampaignDashboardResidentialBasedQutation from './component/propertyDashboard/Common/Quotation';
import CampaignDashboardResidentialBasedEditProperty from './component/propertyDashboard/Common/EditProperty';
import CampaignDashboardResidentialBasedDomainPublish from './component/propertyDashboard/Common/DomainPublish';
import CampaignDashboardResidentialBasedDomainEnquiry from './component/propertyDashboard/Common/DomainEnquiry';
import CampaignDashboardResidentialBasedAuction from './component/propertyDashboard/Common/Auction';
import CampaignDashboardResidentialBasedCreateFBPost from './component/Social/Posts';



const Dashboard = lazy(() => import('./component/CampaignOverview'));

const AgentsList = lazy(() => import('./component/Agents/List'));
const AgentsAdd = lazy(() => import('./component/Agents/Add'));
const AgentsEdit = lazy(() => import('./component/Agents/Edit'));


const AgencyMain = lazy(() => import('./component/AgencyDetail/index'));
const AgencyGeneral = lazy(() => import('./component/AgencyDetail/Edit'));
const AgencyInvoices = lazy(() => import('./component/AgencyDetail/Invoices'));
const AgencyPaymentMethods = lazy(() => import('./component/AgencyDetail/PaymentMethods'));
const AgencyIntegrations = lazy(() => import('./component/AgencyDetail/Integrations'));
const AgencySocial = lazy(() => import('./component/Social/FacebookLogin'));


const CampaignCreate = lazy(() => import('./component/CampaignCreate'));
const CampaignCreateResidential = lazy(() => import('./component/CampaignCreate/Residential'));
const CampaignCreateOrderBased = lazy(() => import('./component/CampaignCreate/OrderBased'));
const CampaignCreateProjectBased = lazy(() => import('./component/CampaignCreate/ProjectBased'));

const CampaignList = lazy(() => import('./component/CampaignList'));
const ResidentialOrders = lazy(() =>
  import('./component/CampaignList/ResidentialOrders')
);
const OfficeOrders = lazy(() =>
  import('./component/CampaignList/OfficeOrders')
);
const AgentOrders = lazy(() => import('./component/CampaignList/AgentOrders'));
const NewArtworkComplete = lazy(() =>
  import('./component/propertyDashboard/Common/Order/NewArtWork/Complete')
);
const NewArtworkProccesDone = lazy(() =>
  import('./component/propertyDashboard/Common/Order/NewArtWork/ProccesDone')
);
const CollectionMaintenance = lazy(() =>
  import('./component/propertyDashboard/Common/Order/CollectionMaintenance')
);
const OrderDispatch = lazy(() =>
  import('./component/propertyDashboard/Common/Order/Dispatch/index')
);

const PaymentMethodAuthorized = lazy(() =>
  import('./component/PaymentMethodAuthorized')
);
const FBInsightLazy = lazy(() =>
  import('./component/Social/Insight')
);
const ArtworkEditor = lazy(() =>
  import('./component/propertyDashboard/Common/Order/ArtworkEditor')
);
const ArtworkEditorDone = lazy(() =>
  import('./component/propertyDashboard/Common/Order/ArtworkEditor/Done')
);
const PaymentMethodLanding = lazy(() =>
  import('./component/PaymentMethodLanding')
);
const VendorApproval = lazy(() =>
  import('./component/public/VendorApproval/index')
);
const PageNotFound = lazy(() => import('./component/PageNotFound'));

const MainRoutes = () => {
  const store = useGlobalState();
  return (
    <>   
      <BrowserRouter >
        <Suspense fallback={<ProgressCard />}>
          <Routes>          
            <Route path={userBrowserRoutes.login} index element={<LoginPage/>}  />
            <Route path={userBrowserRoutes.auth0Callback} element={<CallbackPage/>}  />
            <Route
              path={userBrowserRoutes.authLogoutCallback}
              element={<LogoutCallback/>}
            />            
            <Route element={LandingRoute} >
              <Route path={userBrowserRoutes.paymentMethodLanding}  element={<PaymentMethodLanding/>} />              
            </Route>
            <Route
              path={userBrowserRoutes.public.vendorApproval}
              element={<VendorApproval/>}
            />

            {/* --- Editor Routes (Without template for being fullscreen sake) ---  */}
            <Route element={<ProtectedRoute/>}>
              <Route path={userBrowserRoutes.campaign.dashboard.residential.artworkEditor(
                ':propertyId',
                ':orderId',
                ':campaignType',
                ':jobId',
                ':url'
              )}
              element={<ArtworkEditor/>}/>
              <Route path={userBrowserRoutes.campaign.dashboard.residential.artworkEditorDone(
                ':propertyId',
                ':orderId',
                ':campaignType',
                ':jobId'
              )} element={<ArtworkEditorDone/>}/>
            </Route>
          
         
            {/* -- Routes with Layout and protection ---  */}
            <Route   element={<Template  />}>
              <Route   element={<ProtectedRoute  />}>
                <Route  path={userBrowserRoutes.home}  element={<Home/>} />
                <Route  path={userBrowserRoutes.dashboard}  element={<Dashboard/>} />              
            
                <Route path={userBrowserRoutes.campaign.create.root} element={<CampaignCreate/>}>
                  <Route index  path={userBrowserRoutes.campaign.create.residential}   element={<CampaignCreateResidential/>} />
                  <Route path={userBrowserRoutes.campaign.create.orderBased}    element={<CampaignCreateOrderBased/>} />
                  <Route path={userBrowserRoutes.campaign.create.projectBased}  element={<CampaignCreateProjectBased/>} />
                </Route>
                <Route  path={userBrowserRoutes.agency.dashboard} element={<AgencyMain/>} >
                  <Route  path={userBrowserRoutes.agency.general}  element={<AgencyGeneral/>} />            
                  <Route path={userBrowserRoutes.agency.paymentMethods} element={<AgencyPaymentMethods/>}  />
                  <Route path={userBrowserRoutes.agency.socialAccounts} element={<AgencySocial/>}  />            
                  {
                    store.agency.xero_enabled && 
                  <Route path={userBrowserRoutes.agency.integrations} element={<AgencyIntegrations/>} />
                  }            
                  <Route path={userBrowserRoutes.agency.invoices} element={<AgencyInvoices/>}  />
                </Route>

                <Route path={userBrowserRoutes.campaign.dashboard.root} element={<CampaignDashboardMain/>}>
                  <Route
                    path={userBrowserRoutes.campaign.dashboard.residential.artworkEditor(
                      ':propertyId',
                      ':orderId',
                      ':campaignType',
                      ':jobId'
                    )}
                    element={<CampaignDashboardOrderBased/>}                  
                  />
               
                </Route>
                {/* ---- Residentail campaign dashboard routes ----  */}
                <Route path={userBrowserRoutes.campaign.dashboard.residential.root(':campaignId')} element={<CampaignDashboardResidentialBased/>}>
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.order(':campaignId')} element={<CampaignDashboardResidentialBasedOrders/>}/>
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.collection(':campaignId', ':orderId')} element={<CampaignDashboardResidentialBasedCollectionMaintance/>}  />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.maintenance(':campaignId', ':orderId')} element={<CampaignDashboardResidentialBasedCollectionMaintance/>}  />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.socialPost(':campaignId', ':orderId')} element={<CampaignDashboardResidentialBasedCreateFBPost/>}  />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.orderNew(':campaignId')} element={<CampaignDashboardResidentialBasedNewOrders/>} />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.photosAndPlans(':campaignId')} element={<CampaignDashboardResidentialBasedPhotoAndPlans/>} />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.agents(':campaignId')} element={<CampaignDashboardResidentialBasedAgents/>} />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.adCopy(':campaignId')} element={<CampaignDashboardResidentialBasedAdCopy/>} />
       
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.InspectionAuction(':campaignId')} element={<CampaignDashboardResidentialBasedInspection/>} />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.rates(':campaignId')} element={ <CampaignDashboardResidentialBasedRates/> } />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.tasks(':campaignId')} element={<CampaignDashboardResidentialBasedTasks/>} />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.QR(':campaignId')} element={<CampaignDashboardResidentialBasedQR/>} />
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.quotation(':campaignId')} element={<CampaignDashboardResidentialBasedQutation/>} />        
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.auction(':campaignId')} element={<CampaignDashboardResidentialBasedAuction/>} />        
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.editProperty(':campaignId',':propertyId')} element={<CampaignDashboardResidentialBasedEditProperty/>} />        
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.publishDomain(':campaignId',':propertyId',':orderId')} element={<CampaignDashboardResidentialBasedDomainPublish/>} />        
                  <Route path={userBrowserRoutes.campaign.dashboard.residential.DomainEnquiry(':campaignId',':propertyId',':userId',':adId')} element={<CampaignDashboardResidentialBasedDomainEnquiry/>} />        
                </Route>

                <Route path={userBrowserRoutes.property.collection(':campaignId',':orderId')} element={<CollectionMaintenance/>}/>
                <Route path={userBrowserRoutes.property.maintenance(':campaignId',':orderId')} element={<CollectionMaintenance/>}/>
            

                {/* ---- Project based campaign dashboard routes ----  */}
                <Route path={userBrowserRoutes.campaign.dashboard.projectBased.root(':campaignId')} element={<CampaignDashboardProjectBased/>}>
                  <Route path={userBrowserRoutes.campaign.dashboard.projectBased.order(':campaignId')} element={<CampaignDashboardResidentialBasedOrders/>} />
                  <Route path={userBrowserRoutes.campaign.dashboard.projectBased.agents(':campaignId')} element={<CampaignDashboardResidentialBasedAgents/>} />
                  <Route path={userBrowserRoutes.campaign.dashboard.projectBased.socialPost(':campaignId', ':orderId')} element={<CampaignDashboardResidentialBasedCreateFBPost/>} exact />
                  <Route path={userBrowserRoutes.campaign.dashboard.projectBased.orderNew(':campaignId')} element={<CampaignDashboardResidentialBasedNewOrders/>}/>
                  <Route path={userBrowserRoutes.campaign.dashboard.projectBased.collection(':campaignId', ':orderId')} element={<CampaignDashboardResidentialBasedCollectionMaintance/>}  />
                  <Route path={userBrowserRoutes.campaign.dashboard.projectBased.maintenance(':campaignId', ':orderId')} element={<CampaignDashboardResidentialBasedCollectionMaintance/>}  />
                </Route>

                {/* <Route path={userBrowserRoutes.agents.root} element={<AgentsRouter/>}>
                </Route> */}
                {/* <Route exact path={userBrowserRoutes.agents.add(':role')} element={<AgentAdd/>} />
                  <Route exact path={userBrowserRoutes.agents.edit(':role',':agentId')}  element={<AgentsEdit/>} /> */}
                <Route exact path={userBrowserRoutes.agents.root} element={<AgentsList/>} />
                <Route exact path={userBrowserRoutes.agents.edit(':role',':agentId')} element={<AgentsEdit/>} />
                <Route exact path={userBrowserRoutes.agents.add(':role')} element={<AgentsAdd/>} />



                <Route  path={userBrowserRoutes.paymentMethodLandingAuthorized}  element={<PaymentMethodAuthorized/>} />
                <Route path={userBrowserRoutes.profile}
                  element={<Home/>}
                />
                <Route path={userBrowserRoutes.campaign.list}
                  element={<CampaignList/>}
                />
                <Route path={userBrowserRoutes.campaign.residentialOrders}
                  element={<ResidentialOrders/>}
                />
                <Route path={userBrowserRoutes.campaign.officeOrders}
                  element={<OfficeOrders/>}
                />
                <Route path={userBrowserRoutes.campaign.agentOrders}
                  element={<AgentOrders/>}
                />
            
                <Route 
                  path={userBrowserRoutes.order.dispatch}
                  element={<OrderDispatch/>}
                />
              
                
                <Route 
                  path={userBrowserRoutes.artwork.artworkCompleteDetail(':orderId')}
                  element={<NewArtworkComplete/>}
                />
                <Route 
                  path={userBrowserRoutes.artwork.artworkProccesDone(':orderId',':campaignType')}
                  element={<NewArtworkProccesDone/>}
                />                
                
                <Route  path={userBrowserRoutes.social.insight(':orderId')}  element={<FBInsightLazy/>} />
                <Route  path={userBrowserRoutes.callbackPublishDomain}  element={<CallbackDomain/>} />               
              </Route>
            </Route>
            
          
 
            <Route path="*" element={<PageNotFound/>} />
          </Routes>
        </Suspense>
      </BrowserRouter> 
    </>
  );
};

export default MainRoutes;
