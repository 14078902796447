import {Alert, message} from 'antd';
import Loader from 'component/common/Loader';
import { useEffect, useState } from 'react';

const SingleProductSelector =({api,apiData,onChange})=>{
  const [option,setOption] = useState(null);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    const {productTypeId,advanceResidential,campaignType}    = apiData;
    api(productTypeId,advanceResidential,campaignType,true)
      .then((data) => {
        let values = data.data;      
        
        setOption(values);
        onChange(values)
      })
      .catch((e) => {
        console.log(e);
        message.error('Get data error');
      })
      .finally(() => {
        setLoading(false);        
      });
  }, []);

  if(loading){
    return (
      <>        
        <Loader />                
      </>
    )
  }
  if(!option){
    return null;
  }
  return (
    <>
      <Alert message={`Product: ${option.name}`} type="success" />
    </>
  )
}

export default SingleProductSelector;