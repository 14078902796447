import { Button, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { saveFileHeadless } from 'services/api/assets';
import { getCampaign, updateCampaign } from 'services/api/campaign';
import { quotationPdf} from 'services/api/quotation';
import ProgressCard from 'component/common/ProgressCard';
import { useGlobalState } from 'Context';

const QuotationPreview = (props)=>{
  const store = useGlobalState();
  const {agency} = store;

  const {header,details,campaignId,pdfId} = props;
  const [pages,setPages] = useState([]);
  const [pdfUrl,setPdfUrl] = useState(null);
  const [saving,setSaving] = useState(0); //1: generaatee image 2: save data 3:generate pdf
  const savingRef = useRef(saving);
  savingRef.current = saving;


  const totalLine = 24; 
  // const paperSize= 30;
  // const rowSize= 0.8;
  // const paget = {
  //   type: 'h' //ht h hi items  - cat i id
  // }
  useEffect(() => {
    let tp = [];
    tp.push([{
      type: 'h',
    }])
    let pageIndex = 0;
    let currentPageRowUsed = 8;
    details.forEach(h => {     
      // add category
      if(currentPageRowUsed===totalLine){
        pageIndex+=1;
        tp[pageIndex] = [];
        currentPageRowUsed=0;
      }
      tp[pageIndex].push({type: 'cat', title: h.name});
      currentPageRowUsed+=1;

      h.lines.forEach(l => {
        // add line name and cost row
        if(currentPageRowUsed===totalLine){
          pageIndex+=1;
          tp[pageIndex] = [];
          currentPageRowUsed=0;
        }
        tp[pageIndex].push({type: 'item', title: l.name, extra: l.cost, hasComment: l.comment && l.comment.length>0});
        currentPageRowUsed+=1;

        //add line description row
        if(l.comment){
          const estemiatedCommentLines = Math.ceil(l.comment.length / 100);
          if(currentPageRowUsed===totalLine || currentPageRowUsed+estemiatedCommentLines>totalLine){
            pageIndex+=1;
            tp[pageIndex] = [];
            currentPageRowUsed=0;
          }

          tp[pageIndex].push({type: 'comment', title: l.comment});

          currentPageRowUsed+=estemiatedCommentLines;
        }
      });
    });
    tp[pageIndex].push({type: 'footer'});
    setPages(tp);
    const interval = setInterval(() => getCampaignJob(),7500)
    return () => {
      clearInterval(interval);
    }
  }, []);


  const onOk =async ()=>{


    const postModel = {...header};
    let detailsTemp = {};

    details.forEach(item=>{
      detailsTemp[item.name] = item.lines;
    })
    
    postModel.items = details;

    setSaving(1);
    quotationPdf(agency.quotation_config,postModel,campaignId)
      .then(async (response)=>{                
        const file =new File([response.data], `quote-${campaignId}.pdf`, { type: 'pdf' })
        setSaving(2);
        saveFileHeadless(
          {
            data : {
              'fileType': 'pdf',
              'category': 'campaign',
              'subcategory': 'quotation',              
              campaignId: campaignId,              
            },
            file: file,
            filename: `quote-${campaignId}.pdf`,
            // onSuccess: (response,file)=>{
            //   console.log('hhh2222');
            //   console.log(response)
            //   setSaving(0);
            // },
            // onError: (x)=>{
            //   setSaving(0);
            //   console.log(x);
            // }
          }
        )
          .then(data=>data.data.result).then(data=>{
            onSave(data);
          }).catch(()=>{
            message.error('Error in saving pdf')
            setSaving(0);
          })
      })
      .catch(err=>{
        setSaving(0);
        console.log(err);
      });
    // setSaving(1);
    // let images =[];    
    // for (var i = 0; i < pages.length; i++) { 
    //   const pageName = `page${i}`;
    //   var node = document.getElementById(pageName);
    //   const pngResp = await toPng(node);      
    //   const saveResp = await saveFileHeadless(
    //     {
    //       data : {
    //         'fileType': 'image',
    //         'category': 'campaign',
    //         'subcategory': 'quotation',
    //         'hiResimg': true,
    //         campaignId: campaignId
    //       },
    //       file: pngResp,
    //       filename: `quote${campaignId}-p${i}.png`,
    //       onSuccess: (x)=>{
    //         images.push(x.result.urls.original);
    //         setSaving(0);
    //       },
    //       onError: (x)=>{console.log(x)}
    //     }
    //   )
    //   if(saveResp.status===200){
    //     // console.log(saveResp.data.result);
    //     console.log(saveResp.data.result.urls.original);
    //     // images.push(saveResp.data.result.urls.original);
    //     images.push(saveResp.data.result._id);
    //   }     else{
    //     //what to do
    //   } 
    
    // }
      
  }

  const getCampaignJob = ()=>{
    if(savingRef.current!==4) return;
    if(pdfUrl) return;    
    getCampaign(campaignId).then(data=>data.data).then(data=>{
      if(data.residential_real_estate_info.quotation_pdf_id !== pdfId){
        // console.log(`${pdfId}`)
        // console.log(`${data.residential_real_estate_info.quotation_pdf_id}`)
        setSaving(5);
        setPdfUrl(data.residential_real_estate_info.quotation_pdf);
      }
    })
  }

  
  const onSave = (pdfAsset)=>{
    setSaving(2);    
    const postModel = {...header};
    let detailsTemp = {};

    details.forEach(item=>{
      detailsTemp[item.name] = item.lines;
    })
    
    postModel.items = details;
    

    setSaving(3);  
    updateCampaign(campaignId,{residential_real_estate:{
      quotation: postModel,
      quotation_pdf_id:pdfAsset._id
    }}).then(()=>
    {    
      setPdfUrl(pdfAsset.pdf.file);    
    }
    ).catch(()=>{
      message.error('Error in submitting.')      
    }).finally(()=>{
      setSaving(0);
    })
  }
  
  const downloadPdf = ()=>{
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if(pdfUrl){
    return (
      <div className="container pt-5 dashboard-agents-component text-center">
        <div className='row '>
          <div className='col text-center'>
            <Button className='mr-2' size='large' type='dashed' onClick={props.onCancel}>Back to editing</Button>            
            <Button  type='primary' onClick={downloadPdf}>Download Generated PDF</Button>
          </div>
        </div>        
      </div>
    )
  }
  
  if(saving>0){
    let msg = '';
    switch(saving){    
    case 1: msg = 'Generating PDF. Please wait ...';break;
    case 2: msg = 'Saving PDF. Please wait ...';break;
    case 3: msg = 'Update quotation. Please wait ...';break;
    }
    return(
      <ProgressCard text={msg} />
      // <div className="container pt-5 dashboard-agents-component text-center">
      //   <Loader className='mb-2'/>
      //   {saving===1 && <span>Generating Image. Please wait ...</span>}
      //   {saving===2 && <span>Updating campaign. Please wait ...</span>}
      //   {saving===3 && <span>Saving PDF. Please wait ...</span>}
      //   {saving===4 && <span>Generating PDF. Please wait ...</span>}        
      // </div>
    )
  }

  return (
    <>
      {pages.map((page,index)=>(
        <div key={`p${index}`} id={`page${index}`} className='p-2 bg-white' style={{maxWidth:'14cm'}}>

          {page.map((r,ri)=>(
            <div key={`p${index}-${ri}`} className='mb-4'>
              {r.type==='h' && 
                 <>
                   <h2>Quotation</h2>
                   <hr></hr>
                   <div className='row mb-2'>
                     <div className='col-2'>
                            Campaign
                     </div>
                     <div className='col-8'>
                       {header.campaign_name}
                     </div>
                   </div>
                   <div className='row mb-2'>
                     <div className='col-2'>
                            Agent
                     </div>
                     <div className='col-8'>
                       {header.agent}
                     </div>
                   </div>
                   <div className='row mb-2'>
                     <div className='col-2'>
                            Vendor
                     </div>
                     <div className='col-8'>
                       {header.vendor}
                     </div>
                   </div>
                   <div className='row mb-2'>
                     <div className='col-2'>
                            Sale Type
                     </div>
                     <div className='col-8'>
                       {header.sale_type}
                     </div>
                   </div>
                   <div className='row mb-2'>
                     <div className='col-2'>
                            Quote
                     </div>
                     <div className='col-8'>
                       {header.quote}
                     </div>
                   </div>
                   <hr></hr>
                   <h3>Items</h3>
                 </>
              }
              {r.type==='cat' && 
              <>
                <h6 className='ml-4 mt-2'>{r.title}</h6>
              </>
              }
              {r.type==='item' && 
            <div className={`row ml-1 ${r.hasComment?'mb-2':'mb-4'}`}>            
              <div className='col-10'>
                {r.title}
              </div>
              <div className='col-2 text-right'>
                ${r.extra}
              </div>
            </div>
              }
              {r.type==='comment' && 
              <div className='row ml-2 mb-3 font-weight-normal'>
                <div className='col'>
                  {r.title}
                </div>              
              </div>
              }
              {r.type==='footer' && 
              <div className='row mt-4'>
                <div className='col text-right'>
                  <h5>Sub Total: ${header.total_cost.toLocaleString(undefined, {maximumFractionDigits:2})}</h5>
                </div>
              </div>
              }
            </div>
          ))}

        </div>
      ))}

      <div className='row '>
        <div className='col text-center'>
          <Button className='mr-2' size='large' type='dashed' onClick={props.onCancel}>Back to editing</Button>            
          <Button disabled={saving!=0} size='large' type='primary' onClick={onOk}>Save & Generate PDF</Button>
        </div>
      </div>
    </>
  )
}

export default QuotationPreview;