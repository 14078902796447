import {countriesStates} from '../../../consts/countriesStates';

export const findStateByValue = (state) => {
  return countriesStates['Australia'][Object.keys(countriesStates['Australia']).filter(item => countriesStates['Australia'][item] === state)[0]];
};

export const addressMaker = (brokenAddress) => {
  if(!brokenAddress) return '';
  const item = `${brokenAddress.address_line_2 ? brokenAddress.address_line_2 + '/' : ''}${brokenAddress.address_line_1}, ${brokenAddress.suburb}, ${brokenAddress.state}, ${brokenAddress.postcode}`;
  return item;
};
