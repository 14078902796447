import React, { FC } from 'react';
import { TimePicker, DatePicker } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ReducerActionPayloadForUpdateInsert } from './types';
interface IProps {
  index: number;
  onChange: (input:any) => void;
  onRemove: () => void;
  defaultValue: ReducerActionPayloadForUpdateInsert;
}

const InspectionItem: FC<IProps> = props => {
  const { index, onChange, defaultValue, onRemove } = props;
  const { date, start, end } = defaultValue;
  const momentDateObj = moment(date, 'YYYY-MM-DD');
  const momentStartObj = moment(start, 'HH:mma');
  const momentEndObj = moment(end, 'HH:mma');
  const remove = () => {
    onRemove();
  };
  return (
    <div className="date-item">
      <DatePicker
        defaultValue={momentDateObj}
        onChange={(date, dateString) =>
          onChange({
            date,
            dateString,
            type: 'Inspection',
            kind: 'date',
            index
          })
        }
      />
      <TimePicker
        use12Hours
        format="HH:mma"
        defaultValue={momentStartObj}
        onChange={(date, dateString) =>
          onChange({
            date,
            dateString,
            type: 'Inspection',
            kind: 'start',
            index
          })
        }
      />
      <TimePicker
        use12Hours
        format="HH:mma"
        defaultValue={momentEndObj}
        onChange={(date, dateString) =>
          onChange({ date, dateString, type: 'Inspection', kind: 'end', index })
        }
      />
      <DeleteOutlined onClick={remove} />
    </div>
  );
};

export default InspectionItem;
