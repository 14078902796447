import Auth from 'services/auth/auth';
import { loginUrlMaker, logoutUrlMaker } from 'utils';
const auth = new Auth();


export async function setConfigInfo(dispatch,payload) {
  localStorage.setItem('profile', JSON.stringify(payload));
  dispatch({ type: 'CONFIG_INFO',payload });	
}

export async function loginUser(dispatch) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    let authResult = await checkSession();
    if (authResult && authResult.accessToken && authResult.idToken) {
      // check verification status api to ensure email is verified
      // verificationStatusApi(authResult.idToken).then(verificationStatusApiThen).catch(verificationStatusApiCatch);

      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: { auth_token: authResult.idToken },
      });
      localStorage.setItem('token', JSON.stringify(authResult.idToken));
      return authResult;
    }
    // console.log(authResult);
    dispatch({ type: 'LOGIN_ERROR', error: 'Error in login sp' });
    return null;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    console.log(error);
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('token');
  localStorage.removeItem('profile');
  setTimeout(() => {
    window.location = loginUrlMaker();    
  }, 500);
  
  // console.log('going to log out',logoutUrlMaker())
}

const checkSession = () => {
  return new Promise((resolve, reject) => {
    auth.auth0.checkSession({}, (err, authResult) => {
      if (err) reject(err);
      resolve(authResult);
    });
  });
};

// const verificationStatusApiThen = (response) => {
//   const { data } = response;
//   if(!data.is_verified){
//     window.location = loginUrlMaker();    
//   } 
// };

// const verificationStatusApiCatch = (e) => {
//   console.warn(e);
//   // message.error('Verification error');
//   window.location = loginUrlMaker();    
// };


