import React, { useState, useEffect } from 'react';
import { Upload,  Popconfirm, Divider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { saveFile, assetAPIBaseURL } from 'services/api/assets';
import './style.scss';
import { imageUrlMaker } from 'utils';
import {RightCircleTwoTone} from '@ant-design/icons'

const Dragger = Upload.Dragger;

export const Image = (props) => (
  <div className="image-wrapper">
    
    <Popconfirm
      placement="bottomRight"
      title={'Are you sure?'}
      onConfirm={props.onRemovePlanImage}
      okText="Yes"
      cancelText="No"
    >
      <div className="remove">
        <div className="fa fa-times-circle" />
      </div>
    </Popconfirm>

    <div
      className={'image'}
      onClick={props.editModalOpenner}
      style={{ backgroundImage: `url(${props.src})` }}
    >
      {props.title && <div className="title">{props.title}</div>}
    </div>
  </div>
);

const DomainImages= (props) => {
  
  const [propertyImages, propertyImagesSetter] = useState([]);
  const [floorImages, floorImagesSetter] = useState([]);  
  const imagesLoading=false;

  const {externalFloorImagesSetter,externalPropertyImagesSetter} = props;

  useEffect(() => {
    
    if(props.defalutPropertyImages){
      const pi = props.defalutPropertyImages.map(
        (imagesInfo) => imagesInfo.image
      );
      propertyImagesSetter(pi);
      externalPropertyImagesSetter(pi);
    }
    if(props.defaultFloorImages){
      floorImagesSetter(props.defaultFloorImages);
      externalFloorImagesSetter(props.defaultFloorImages);
    }
  }, []);


  const onPropertyImageChange = ({ file }) => {    
    if (file.status === 'done') {
      const uploadedFile = {
        urls: {
          250: file.response.result.urls['250'],
        },
        _id: file.response.result['_id'],
      };
      propertyImagesSetter(propertyImages.concat([uploadedFile]));
      externalPropertyImagesSetter(propertyImages.concat([uploadedFile]));
      
    }
    if (file.status === 'error') {
      console.log(file);
    }
  };

  const propertyImagesProps = {
    action: assetAPIBaseURL,
    customRequest: saveFile,
    data: {
      category: 'campaign',
      subcategory: 'photo',
      fileType: 'image',
      fileName: props.campaignId,
      campaignId: props.campaignId,
    },
    onChange: onPropertyImageChange,
    defaultFileList: [],
    listType: 'picture',
    className: 'upload-list-inline',
    accept: '.jpg,.png,.gif',
    multiple: true,
  };

  const onfloorImagesPropsChange = ({ file }) => {
    if (file.status === 'done') {
      const uploadedFile = {
        urls: {
          250: file.response.result.urls['250'],
        },
        _id: file.response.result['_id'],
      };
      floorImagesSetter((prevData) => [...prevData, uploadedFile]); // view image list
      externalFloorImagesSetter((prevData) => [...prevData, uploadedFile]); // view image list
    }
  };

  const floorImagesProps = {
    action: assetAPIBaseURL,
    customRequest: saveFile,
    data: {
      category: 'campaign',
      subcategory: 'floorplan',
      fileType: 'floorplan',
      fileName: '',
      campaignId: props.campaignId,
    },
    onChange: onfloorImagesPropsChange,
    defaultFileList: [],
    listType: 'picture',
    className: 'upload-list-inline',
    accept: '.jpg,.png,.gif',
  };

  const onRemovePlanImage = (id) => {
    const images = propertyImages.filter((image) => image['_id'] !== id);
    propertyImagesSetter(images);    
    externalPropertyImagesSetter(images);
  };
  

  const onRemoveFloorImage = (id) => {
    const newFloorPlanList = floorImages
      .filter((item) => item['_id'] !== id)
      .map((item) => item['_id']);
    floorImagesSetter(newFloorPlanList);
    externalFloorImagesSetter(newFloorPlanList);    
  };

  return (
    <div className=" dashboard-photo-plans">
      <h3 className="font-weight-medium d-inline-block">
        <RightCircleTwoTone className='mr-2'/>Photos
      </h3>
      {imagesLoading ? (
        <LoadingOutlined className="text-center w-100" size={24} spin />
      ) : (
        <React.Fragment>
          <Dragger
            {...propertyImagesProps}
            className="upload-images upload-photos"
          >
            <div className="upload-icon pt-3">
              <span className="fa fa-cloud-upload-alt" />
            </div>
            <strong>Browse your device and upload images</strong>
            <small className="d-block text-muted pb-5 pt-2">
                  Minimum Recommended File Size 300ppi (Approx. 3MB)
              <br></br>
                  Maximum file Size 10MB 
            </small>
          </Dragger>
          <div className="row images p-2 p-md-1 p-lg-0 mt-sm-3 mt-1">
            {propertyImages.map((item, key) => (
              <Image
                dragable={true}
                key={key}
                itemIndex={++key}
                src={imageUrlMaker(item, '250')}
                title={item?.fileName}
                onRemovePlanImage={() => onRemovePlanImage(item['_id'])}                
              />
            ))}
          </div>
        </React.Fragment>
      )}
      

      <Divider/>
      <h3 className="font-weight-medium d-inline-block">
        <RightCircleTwoTone className='mr-2'/>Floor Plans
      </h3>
      {imagesLoading ? (
        <LoadingOutlined className="text-center w-100" size={24} spin />
      ) : (
        <React.Fragment>
          <Dragger
            {...floorImagesProps}
            className="upload-images upload-plans"
          >
            <div className="upload-icon pt-sm-3 pt-0">
              <span className="fa fa-cloud-upload-alt" />
            </div>
            <strong>Browse your device and upload images</strong>
            <small className="d-block text-muted pb-md-5 pb-2 pt-2">
                  Minimum Recommended File Size 300ppi (Approx. 3MB)
              <br></br>
                  Maximum file Size 10MB 
            </small>
          </Dragger>
          <div className="row images p-2 p-md-1 p-lg-0 mt-sm-3 mt-1">
            {floorImages &&
                    floorImages.map((item, key) => (
                      <div
                        key={key}
                        className="col-6 col-md-4 col-lg-3 p-lg-3 p-md-2 p-1"
                      >
                        <Image
                          dragable={false}
                          src={imageUrlMaker(item, '250')}
                          title={item?.fileName}
                          onRemovePlanImage={() =>
                            onRemoveFloorImage(item['_id'])
                          }                          
                        />
                      </div>
                    ))}
          </div>
        </React.Fragment>
      )}
    </div>      
  );
};


export default DomainImages;