import React, { useState } from 'react';
import { Popconfirm, message } from 'antd';
import { LoadingOutlined,DragOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { browserRoutes } from '../../consts/browserRoutes';
import { imageUrlMaker } from '../../utils';

export default (props) => {
  const isReadonly = props.isReadonly ? props.isReadonly : false;
  const [removing, removingSetter] = useState(false);
  const onRemove = async () => {
    removingSetter(true);
    props
      .onRemove(props.id)
      .then(() => {
        props.elementRemover(props.itemIndex);
      })
      .catch((e) => {
        message.error(e.response.data.text);
      })
      .finally(() => {
        removingSetter(false);
      });
  };

  let image = imageUrlMaker(props.profile_picture_id);
  if (!image) {
    image = (
      <div className="image mr-3 m-1 m-sm-3">
        <i className="fa fa-user" />
      </div>
    );
  } else {
    image = (
      <div
        className="image mr-3 m-1 m-sm-3"
        style={{ backgroundImage: `url(${image})` }}
      />
    );
  }

  return (
    <div className={props.enableSort?'agent-item sortable-item':'agent-item'}>      
      {image}
      <div className="m-1 m-sm-3">
        <div className="name">
          {props['first_name']} {props['last_name']}
        </div>
        <Link
          className={'view-detail'}
          to={browserRoutes.agents.edit(props.userRole, props.id)}
        >
          View Details
        </Link>
      </div>
      {!isReadonly && (
        <div className="remove text-danger m-1 m-sm-3">
          {removing ? (
            <LoadingOutlined className="pt-2" style={{ fontSize: 20 }} spin />
          ) : (
            <Popconfirm
              placement="topLeft"
              title="Are you sure"
              onConfirm={onRemove}
              okText="Yes"
              cancelText="No"
            >
              Remove
            </Popconfirm>
          )}
        </div>
      )}
      {props.enableSort && (
        <span className="bellnumbers"><DragOutlined /></span>
      )}
    </div>
  );
};
