import styled from 'styled-components';


const Wrapper = styled.div`
.generating-text {
  margin-right: 15px;
}

.base-btn{
    background: #fff;
    color:#377dff;
    border:1px solid #377dff;
    text-align:center;
    font-weight: 500;
    border-radius:0 0 5px 5px;
    padding:5px 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.selected{
      background: #377dff;
      color:#fff;
      border:1px solid #377dff;
    }
    &.disabled{
      border:0;
      background: #f1f1f1;
      color: #d0d0d0;
    }
    .hover{
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      background: #8dd0ff;
      color: #fff;
      left: 0;
      padding-top: 5px;
      bottom: 0;
    }
    &:hover .hover{
      display:block;
    }
  }

  .base-btn-warn{
    background: #fff;
    color:#ff377d;
    border:1px solid #ff377d;
    text-align:center;
    font-weight: 500;
    border-radius:0 0 5px 5px;
    padding:5px 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.selected{
      background: #ff377d;
      color:#fff;
      border:1px solid #ff377d;
    }
    &.disabled{
      border:0;
      background: #f1f1f1;
      color: #d0d0d0;
    }
    .hover{
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      background: #8dd0ff;
      color: #fff;
      left: 0;
      padding-top: 5px;
      bottom: 0;
    }
    &:hover .hover{
      display:block;
    }
  }
`;

export default Wrapper;