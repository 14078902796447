import React, { useEffect, useState } from 'react';
import Menu from 'component/Template/Menu';
import Drawer from 'component/Template/Drawer';
import StyledHeader from './style';
import { imageUrlMaker, oppositeColor } from 'utils';
import auth0Client from 'Auth/AuthClient';
import LogoImage from 'theme/assets/img/ezi-Logo.png'

const Header = () => {  
  // const agencyColor = agency_color?agency_color:'whitesmoke';
  // const {headerImage} = store;
  // var complement = agencyColor ? oppositeColor(agencyColor) : undefined;
  
  const [profile, setProfile] = useState(null);
  const profileCallback = (error, profileData) => {
    if (error) {
      // console.log('user check on ERRR path')      
    }
    const agency = profileData.agency;
    const { agency_color, agency_text_color,agency_logo } = agency;
    
    const agencyColor= agency_color?agency_color:'whitesmoke'
    setProfile({
      agencyColor,
      agency_text_color,
      headerImage: imageUrlMaker(agency_logo, 250),
      complement:agencyColor ? oppositeColor(agencyColor) : undefined
    });    
  };

  useEffect(() => {
    auth0Client.getProfile(profileCallback);
  }, []);

  if(!profile){
    return null;
  }
  return (
    <StyledHeader
      className='sys-header'
      colors={{
        bg: profile.agencyColor,
        text: profile.agency_text_color,
        bgOppositeColor: profile.complement
          ? `rgb(${profile.complement},${profile.complement},${profile.complement})`
          : undefined,
      }}
    >
      <div className={profile.agency_color?'container u-header u-header--white-nav-links':'container u-header u-header-nav-links'}>
        <div className='row'>
          <div className='col-3 logo-container'>
            <img
              className=' logo-img'
              src={profile.headerImage ? profile.headerImage : LogoImage}
              alt='Ezi logo'
            />
          </div>
          <div className='col-9 text-right'>
            <nav className='js-breadcrumb-menu navbar navbar-expand-lg u-header__navbar u-header__navbar--no-space hs-menu-initialized hs-menu-horizontal'>
              <div className='collapse navbar-collapse u-header__navbar-collapse'>
                <Menu />
              </div>
            </nav>
            <Drawer />
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
