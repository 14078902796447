import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, Dropdown } from 'antd';
import { LoadingOutlined,DownOutlined,DownloadOutlined } from '@ant-design/icons';
import { checkFile, getPreview } from 'services/api/printUI';
import { useFetch } from 'services/api/useFetch';
import { RenderApi } from 'component/RenderApi';
import ImageSlider from '../ImageSlider';

import '../../style.scss';
import StyledDiv, { StyledImageDownloadLinkDiv } from './style';
import { useGlobalState } from 'Context';
import { RouteHelperOrderTab } from 'component/propertyDashboard/Common/CampaignRouteHelper';
import ProgressCard from 'component/common/ProgressCard';

const CheckFileComponentHook = props => {
  const { setCheckFileData, orderId } = props;
  const [checkFileData, checkFileApiCall] = useFetch({
    api: checkFile,
    params: orderId,
    withApiRefrence: true
  });

  useEffect(() => {
    setCheckFileData([checkFileData, checkFileApiCall]);
  }, [setCheckFileData, checkFileData, checkFileApiCall]);

  return null;
};


const ProccesDoneArtworkDetails = props => {
  const {advanceResidential} = useGlobalState();  
  let campaignType =props.campaignType?props.campaignType: 'residential-real-estate';  
  const {
    orderId,
    propertyId,
    visibleCongrats = true,
    visibleContinueButton = true
  } = props;
  const navigate = useNavigate();
  const getPreviewApiData = useFetch({
    api: getPreview,
    params: orderId
  });

  const [checkFileDataHook, setCheckFileData] = useState([
    {
      error: false,
      loading: true
    },
    () => {
      // intentionally empty
    }
  ]);

  const [checkFileData, checkFileApiCall] = checkFileDataHook || [];

  const checkFileApiCallInterval = useRef();
  useEffect(() => {
    if (
      !checkFileData.data &&
      !getPreviewApiData.error &&
      !checkFileApiCallInterval.current
    ) {
      checkFileApiCallInterval.current = setInterval(() => {
        checkFileApiCall();
      }, 5000);
    }
    return () => {
      clearInterval(checkFileApiCallInterval.current);
      checkFileApiCallInterval.current = undefined;
    };
  });

  const {
    result: { images: checkFileDataImages }
  } = checkFileData.data || { result: {} };
  const { result: previewApiDataImages } = getPreviewApiData.data || {};
  const ContinueButton = visibleContinueButton ? (
    <Button
      type="primary"
      size="large"
      className="px-5"
      onClick={() =>navigate(RouteHelperOrderTab(advanceResidential,campaignType,propertyId))}
      // navigate(
      //   browserRoutes.campaign.dashboard.residential.order(propertyId)
      // )
    >
      Continue
    </Button>
  ) : null;

  const PdfDownloadButton = (
    <RenderApi
      {...checkFileData}
      loadingComponent={
        <Button
          loading
          type="primary"
          ghost
          size="large"
          icon={<DownloadOutlined/>}
          className="mr-3"
        >
          Download PDF
        </Button>
      }
      renderError={() => {
        return (
          <Button
            loading
            type="primary"
            ghost
            size="large"
            icon={<DownloadOutlined/>}
            className="mr-3"
          >
            PDF Loading...
          </Button>
        );
      }}
      render={data => {
        return (
          <Button
            type="primary"
            href={data.result.pdf}
            target="_blank"
            ghost
            size="large"
            icon={<DownloadOutlined/>}
            className="mr-3"
          >
            Download PDF
          </Button>
        );
      }}
    />
  );

  // const imagesToUse=checkFileDataImages || previewApiDataImages;
  const images = checkFileDataImages
    ? checkFileDataImages
    : previewApiDataImages;

  const PreviewImageList = images ? (
    <ImageSlider images={images} />
  ) : (
    <RenderApi
      {...getPreviewApiData}
      loadingComponent={
        <div className="text-center mt-5 pt-5">
          preview Images are loading please wait ...
          <LoadingOutlined spin />
        </div>
      }
      renderError={() => {
        return 'An error has occurred';
      }}
      render={data => {
        return <ImageSlider images={data.result} />;
      }}
    />
  );


  const ImagesDownloadDropdown = (() => {
    const menu = (
      <Menu>
        {images ? (
          images.map((item, index) => {
            return (
              <Menu.Item key={index}>
                <StyledImageDownloadLinkDiv>
                  <Button
                    type="link"
                    href={item}
                    target="_blank"
                    ghost
                    size="large"
                    icon={<DownloadOutlined/>}
                    className="mr-3"
                  >
                    Image {index + 1}
                  </Button>{' '}
                </StyledImageDownloadLinkDiv>
              </Menu.Item>
            );
          })
        ) : (
          <Menu.Item>loading</Menu.Item>
        )}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger="click">
        <Button
          type="primary"
          ghost
          size="large"
          icon={<DownloadOutlined/>}
          className="mr-3"
        >
          Download Images <DownOutlined />
        </Button>
      </Dropdown>
    );
  })();

  return (
    <StyledDiv>
      <div className="container artwork-proccess-done text-center">
        {getPreviewApiData.loading ? (
          // <p className="pt-5 pb-2">Loading...</p>
          <ProgressCard />
        ) : null}
        {getPreviewApiData.error ? (
          <p className="pt-5 pb-2">
            <br />
            An error has occurred while processing your request, please try
            again later.
            <br /> <br />
            {ContinueButton}
          </p>
        ) : null}
        {!getPreviewApiData.loading && !getPreviewApiData.error ? (
          <>
            <CheckFileComponentHook
              setCheckFileData={setCheckFileData}
              orderId={orderId}
            />
            {visibleCongrats ? <h2 className="pt-5 pb-2">Congrats</h2> : null}
            <div className="info pb-3">
            Please see your artwork preview below and available for download.
              <br></br>
            Be sure to check the PDF proof for Size, Spelling and LowRes Images
            </div>
            <div className="image d-inline-block">{PreviewImageList}</div>
            <div className="pt-3">
              {PdfDownloadButton}
              {ImagesDownloadDropdown}
              {ContinueButton}
            </div>
          </>
        ) : null}
      </div>
    </StyledDiv>
  );
};

export default ProccesDoneArtworkDetails;
