import React, {useState} from 'react';
import {Button} from 'antd';
import { LoadingOutlined , PlusOutlined} from '@ant-design/icons';
import TaskList from './List';
import AddEdit from './AddEdit';
import { useParams } from 'react-router-dom';

export default (props) => {
  const [showModal, showModalSetter] = useState(false);
  const [loadingList, loadingListSetter] = useState(false);
  const {campaignId} = useParams();
  const updateList = () => {
    loadingListSetter(true);
    setTimeout(() => {
      loadingListSetter(false);
    }, 1);
  };

  const openModal = () => showModalSetter(true);
  const closeModal = () => {
    showModalSetter(false);
  };

  const onUpdateModal = () => {
    closeModal();
    updateList();
  }

  return(
    <div className="container pt-5 ">
      <h3 className='font-weight-medium d-inline-block mr-3'>Tasks</h3>
      <Button className="align-text-bottom"
        type="primary"
        shape="round"
        icon={<PlusOutlined/>}
        onClick={openModal}
      >
        New Task
      </Button>
      {showModal && <AddEdit editing={false} onUpdate={onUpdateModal} close={closeModal} campaignId={campaignId} />} //todo sp property
      {loadingList ? <LoadingOutlined type='loading' size={24} spin /> : <TaskList campaignId={campaignId} />}
    </div>
  );
}
