import { useCampaignContext } from '../CampaignContext';

const AuctionDetail = ({campaignId})=>{
  
  const campaignContext = useCampaignContext();
  
  if(!campaignContext.campaignDetail.published_to_auctions_live){
    return  null;
  }
  const data = campaignContext.campaignDetail.auctions_live;
  
  if(!data){
    return (
      <div className="w-md-80 w-lg-60 mx-md-auto mb-9">
        <div className="mb-5">
          <h1 className="font-weight-medium">Auction Live Detail</h1>
          <p>Your campaign has been successfully published to the auction live ...</p>
        </div>     
      </div>     
    )
  }
  return (
    <>
      <div className="w-md-80 w-lg-60 mx-md-auto mb-9">
        <div className="mb-5">
          <h1 className="font-weight-medium">Auction Live Detail</h1>
          <p>Your campaign has been successfully published to the auction live.</p>
        </div>        
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-5">
            <label className="input-label" htmlFor="auctionType">Auction Type</label>
            <div className="input-group">
              <input type="text" disabled={true} className="form-control" name="auctionType" id="auctionType" placeholder="Auction Type" value={data?.auction_type} />
            </div>
          </div>

          <div className="col-md-6 mb-3 mb-md-5">
            <label className="input-label" htmlFor="auctionDateTime">Auction Date time</label>
            <div className="input-group">
              <input type="text" disabled={true} className="form-control" name="auctionDateTime" id="auctionDateTime" value={data?.auction_date_time}/>
            </div>
          </div>                 
        </div>

        <div className='row mb-4'>
          <div className="col-12">
            <label className="input-label" htmlFor="shareUrl">Share URL</label>
            <div className="input-group">
              <input type="text" disabled={true} className="form-control" name="shareUrl" id="shareUrl" placeholder="Share URL"  value={data?.share_url}/>
            </div>
          </div>     
        </div>

        <div className='row'>
          {data.bidder_reg_url !=='False' && 
            <div className="col-12">
              <label className="input-label" htmlFor="bidder_reg_url">Bidder Registration URL</label>
              <div className="input-group">
                <input type="text" disabled={true} className="form-control" name="bidder_reg_url" id="bidder_reg_url" placeholder="Bidder Registration URL"  value={data?.bidder_reg_url}/>
              </div>
            </div>
          }
        </div>
        
      </div>

    </>
  )
}

export default AuctionDetail;