export const browserRoutes = {
  home: '/',
  login: '/login',
  callback: '/callback',
  callbackPublishDomain: '/callback-domain',
  auth0Callback: '/user/auth0/login-callback',
  dashboard: '/dashboard',
  paymentMethodLanding: '/paymentMethodLanding',
  paymentMethodLandingAuthorized: '/paymentMethodPrompt',
  'userSignupLevel1': '/user/signup',
  'userSignupLevel2': '/user/signup-complete',
  'userEmailVerification': '/user/email-verification',
  'userLogin': '/user/login',
  'forgotPassword': '/user/forgot-password',
  'authCallback': '/user/login-callback',
  'authLogoutCallback': '/user/logout-callback',  
  'profile': '/user/profile',
  'order':{
    'dispatch'    : '/dispatch', 
  },
  'campaign': {
    list: '/campaign/',    
    residentialOrders: '/property-orders/',    
    officeOrders: '/office-orders/',    
    agentOrders: '/agent-orders/',    
    create: {
      root                : '/campaign/create/',
      residential         : '/campaign/create/residential/',
      orderBased         : '/campaign/create/order-based',
      projectBased       : '/campaign/create/project-based',
    },
    dashboard: {
      root                : '/campaign/dashboard/',      
      residential: {
        'root'              : campaignId=> `/campaign/dashboard/residential/${campaignId}`,
        'create'            : '/campaign/dashboard/residential/create',
        'dashboard'         : campaignId => `/campaign/dashboard/residential/${campaignId}/dashboard`,        // campaignId => :campaignId
        'order'             : campaignId => `/campaign/dashboard/residential/${campaignId}/order`,            // campaignId => :campaignId
        'artworkEditor'     : (campaignId, orderId,campaignType, jobId,url) => `/campaign/dashboard/residential/${campaignId}/order/${orderId}/${campaignType}/artwork-editor/${jobId}/printUi/${url}`,       // campaignId => :campaignId
        'artworkEditorDone' : (campaignId, orderId,campaignType, jobId) => `/campaign/dashboard/residential/${campaignId}/order/${orderId}/${campaignType}/artwork-editor/${jobId}/done`,  // campaignId => :campaignId
        'collection'        : (campaignId, orderId) => `/campaign/dashboard/residential/${campaignId}/order/${orderId}/collection`, // campaignId => :campaignId
        'maintenance'       : (campaignId, orderId) => `/campaign/dashboard/residential/${campaignId}/order/${orderId}/maintenance`,// campaignId => :campaignId
        'orderNew'          : (campaignId) => `/campaign/dashboard/residential/${campaignId}/order/new/`,        // campaignId => :campaignId
        'orderDispatch2'    : campaignId => `/campaign/dashboard/residential/${campaignId}/dispatch`,         // campaignId => :campaignId  orderId => :orderId
        'photosAndPlans'    : campaignId => `/campaign/dashboard/residential/${campaignId}/photos-plans`,     // campaignId => :campaignId
        'agents'            : campaignId => `/campaign/dashboard/residential/${campaignId}/agents`,           // campaignId => :campaignId
        'adCopy'            : campaignId => `/campaign/dashboard/residential/${campaignId}/adCopy`,           // campaignId => :campaignId
        'rates'            : campaignId => `/campaign/dashboard/residential/${campaignId}/rates`,           // campaignId => :campaignId
        'InspectionAuction'            : campaignId => `/campaign/dashboard/residential/${campaignId}/inspection-auction`,           // campaignId => :campaignId
        'QR'            : campaignId => `/campaign/dashboard/residential/${campaignId}/qr`,
        'tasks'             : campaignId => `/campaign/dashboard/residential/${campaignId}/tasks`,            // campaignId => :campaignId
        'quotation'             : campaignId => `/campaign/dashboard/residential/${campaignId}/quotation`,            // campaignId => :campaignId
        'auction'             : campaignId => `/campaign/dashboard/residential/${campaignId}/auction`,            // campaignId => :campaignId
        'editProperty'             : (campaignId,propertyId) => `/campaign/dashboard/residential/${campaignId}/editProperty/${propertyId}`,            // campaignId => :campaignId        
        'socialPost'       : (campaignId, orderId) => `/campaign/dashboard/residential/${campaignId}/order/${orderId}/socialPost`,// campaignId => :campaignId
        'publishDomain'       : (campaignId, propertyId,orderId) => `/campaign/dashboard/residential/${campaignId}/order/${propertyId}/publishDomain/${orderId}`,// campaignId => :campaignId
        'DomainEnquiry'       : (campaignId, propertyId,userId,adId) => `/campaign/dashboard/residential/${campaignId}/order/${propertyId}/DomainEnquiry/${userId}/${adId}`,
      },
      'projectBased': {
        'root'              : campaignId=> `/campaign/dashboard/project-based/${campaignId}`,
        'orderNew'          : campaignId => `/campaign/dashboard/project-based/${campaignId}/order/new`,        // campaignId => :campaignId
        // 'create'            : '/campaign/project-based/create',
        'order'             : campaignId => `/campaign/dashboard/project-based/${campaignId}/order`,
        'agents'            : campaignId => `/campaign/dashboard/project-based/${campaignId}/agents`,         // campaignId => :campaignId,
        'socialPost'       : (campaignId, orderId) => `/campaign/dashboard/project-based/${campaignId}/order/${orderId}/socialPost`,// campaignId => :campaignId
        'collection'        : (campaignId, orderId) => `/campaign/dashboard/project-based/${campaignId}/order/${orderId}/collection`, // campaignId => :campaignId
        'maintenance'       : (campaignId, orderId) => `/campaign/dashboard/project-based/${campaignId}/order/${orderId}/maintenance`,// campaignId => :campaignId
      },      
    }
  },
  'proposal': '/proposal',
  'property': {
    'root': '/property/:propertyId',
    'dashboard'         : campaignId => `/property/${campaignId}/dashboard`,        // campaignId => :campaignId
    'order'             : campaignId => `/property/${campaignId}/order`,            // campaignId => :campaignId
    'artworkEditor'     : (campaignId, orderId, jobId) => `/property/${campaignId}/order/${orderId}/artwork-editor/${jobId}`,       // campaignId => :campaignId
    'artworkEditorDone' : (campaignId, orderId, jobId) => `/property/${campaignId}/order/${orderId}/artwork-editor/${jobId}/done`,  // campaignId => :campaignId
    'collection'        : (campaignId, orderId) => `/property/${campaignId}/order/${orderId}/collection`, // campaignId => :campaignId
    'maintenance'       : (campaignId, orderId) => `/property/${campaignId}/order/${orderId}/maintenance`,// campaignId => :campaignId
    'orderNew'          : campaignId => `/property/${campaignId}/order/new`,        // campaignId => :campaignId
    'orderDispatch2'    : campaignId => `/property/${campaignId}/dispatch`,         // campaignId => :campaignId  orderId => :orderId
    'photosAndPlans'    : campaignId => `/property/${campaignId}/photos-plans`,     // campaignId => :campaignId
    'agents'            : campaignId => `/property/${campaignId}/agents`,           // campaignId => :campaignId
    'adCopy'            : campaignId => `/property/${campaignId}/adCopy`,           // campaignId => :campaignId
    'rates'            : campaignId => `/property/${campaignId}/rates`,           // campaignId => :campaignId
    'InspectionAuction'            : campaignId => `/property/${campaignId}/inspection-auction`,           // campaignId => :campaignId
    'tasks'             : campaignId => `/property/${campaignId}/tasks`,            // campaignId => :campaignId    
    'socialPost'       : (campaignId, orderId) => `/property/${campaignId}/order/${orderId}/socialPost`,// campaignId => :campaignId    
  },
  'agents': {
    'root': '/staffs',
    'add': role => `/staffs/add/${role}`,
    'edit': (role, agentId) => `/staffs/edit/${role}/${agentId}`
  },
  'artwork': {
    'artworkCompleteDetail': orderId => `/artwork/${orderId}/success`,   // orderId => :order
    'artworkProccesDone'   : (orderId,campaignType) => `/artwork/${orderId}/done/${campaignType}`,      // orderId => :order
  },
  agency: {
    dashboard : '/agency/',
    general : '/agency/general',
    paymentSubscription: '/agency/paymentSubscription',
    paymentMethods: '/agency/paymentMethods',
    socialAccounts: '/agency/socialAccounts',    
    // 'integrations': code => `/agency/integrations/${code}`,
    integrations: '/agency/integrations',
  },
  social:{
    account : '/social/accounts',
    posts : '/social/posts',
    createPost : orderId => `/social/posts/create/${orderId}`,
    insight : orderId => `/social/posts/insight/${orderId}`,
  },
  'public' :{
    'vendorApproval': '/vendor-approval/:token',    
  }
};
