import auth0 from 'auth0-js';
// import history from '../../utils/history';


export default class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      responseType: 'token id_token',
      scope: 'openid'
    });
  }

  logout() {
    this.auth0.logout({
      returnTo: window.location.origin
    })
  }
  
  /*logout() {
    this.auth0.logout({
      returnTo: window.location.origin
    });

    // navigate to the home route
    history.replace('/home');
  }*/
}
