import React from 'react';
import { Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const { Option } = Select;

export default props => {
  const { packages = [], loading, onChange} = props;
  const isNotEmpty = !!packages.length;

  if (loading)
    return (
      <LoadingOutlined
        size={24}        
        spin
        className='loading text-center w-100'
      />
    );

  return isNotEmpty ? (
    <React.Fragment>
      <label className='form-label'>Packages</label>
      <Select
        size='large'
        defaultValue='Select'
        style={{ width: '100%' }}
        onChange={onChange}
      >
        {packages.map((packageItem, key) => {
          return (
            <Option key={key} value={packageItem.id}>
              {packageItem.name}
            </Option>
          );
        })}
      </Select>
    </React.Fragment>
  ) : null;
};
