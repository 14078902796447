import React from 'react';
import Template from '../Template';
import {loginUrlMaker} from '../../utils';

const Logout = (props) => {
  props.setUserToken('');
  window.location = loginUrlMaker();
  return (
    <Template>
      <div className='mt-5 container text-center alert alert-success'>
        Logout Successfully
      </div>
    </Template>
  );
};
export default Logout;

// export const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserToken: (token) => dispatch(setUserToken(token))
//   }
// };

// export default connect(null, mapDispatchToProps)(Logout);
